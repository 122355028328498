import React, { useEffect, useState } from "react";
import styles from "../../components/CustomTheme/customTheme.module.scss";
import ColorSectionItem from "../ColorSectionItem/ColorSectionItem";
import UploadIcon from "../../assets/images/al-upload-white.svg"; //
import BackgroundImage from "../../assets/images/al_background.svg"; //
import ModalCrop from "../ModalCrop/ModalCrop";
import arrowLeft from "../../assets/images/versionV2/DesignIcons/arrowLeft.svg";
import arrowRight from "../../assets/images/versionV2/DesignIcons/arrowRight.svg"; //
import {
  arabicFonts,
  cardShape,
  englishFonts,
  themes,
} from "../../constants/newThemes";
import { useSelector } from "react-redux";
import { store } from "../../app/store";
import { selectMe } from "../../features/stores/userSlicer";
import useRequest from "../../features/API/request";
import {
  setDataChange,
  setShouldSave,
} from "../../features/stores/changeDataSlicer";
import RouteLeavingGuard from "../UnsavedChanges/RouteLeavingGuard";
import mobileInfoCard from "../../assets/images/info2.svg";
import desktopInfoCard from "../../assets/images/desktopInfoCard2.svg";
import Loader from "react-loader-spinner";
import { refreshMe } from "../../features/Login/login";
import {
  selectUserTheme,
  setUserTheme,
} from "../../features/stores/userThemeSlicer";
import { raiseToast } from "../Toast/Toast";
import IconStylePicker from "../IconStylePicker/IconStylePicker";
import Compressor from "compressorjs";
import { blobToBase64, convertBase64ToBlob } from "../../Helper/imageCropper";
import { useTranslation } from "react-i18next";
import Switch from "@material-ui/core/Switch/Switch";
import useWindowWidth from "../../hooks/useWindowWidth";
import trashGrey from "../../assets/images/al_delete_grey.svg";

const selectChangeData = (state: any) => state.changeData.changeData;
const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;

interface ICustomThemeSelect {
  handleBackgroundLeft: () => void;
  handleBackgroundRight: () => void;
  backgroundIndex: number;
  setBackgroundIndex: (arg1: number) => void;
  handleFontLeft: () => void;
  handleFontRight: () => void;
  fontIndex: number;
  setFontIndex: (arg1: number) => void;
}

const CustomTheme = ({
  handleBackgroundLeft,
  handleBackgroundRight,
  setBackgroundIndex,
  backgroundIndex,
  handleFontLeft,
  handleFontRight,
  setFontIndex,
  fontIndex,
}: ICustomThemeSelect) => {
  const { t, i18n } = useTranslation();
  const theme = useSelector(selectUserTheme);
  const user = useSelector(selectMe);
  const themeRequest = useRequest({ url: "/linksUser/theme", method: "POST" });
  const width = useWindowWidth();
  const cardShapeDetails = [];
  const fontArray = Object.values(englishFonts);
  for (const [key, value] of Object.entries(cardShape)) {
    cardShapeDetails.push(value);
  }

  const customBackgroundReq = useRequest({
    url: "/linksUser/custom-background-image",
    method: "POST",
  });

  const [selectedCardShape, setSelectedCardShape] = useState(theme.cardShape);
  const [selectedBackground, setSelectedBackground] = useState("");
  const [selectedFont, setSelectedFont] = useState(theme.englishFont);
  const [selectedDisplayLocale, setSelectedDisplayLocale] = useState(
    theme.displayLocale
  );
  const [selectedIconStyle, setSelectedIconStyle] = useState(theme.iconSet);
  const [isFetching, setIsFetching] = useState(false);
  const [originalMobileImage, setOriginalMobileImage] = useState(
    theme.mobileImage
  );
  const [originalDesktopImage, setOriginalDesktopImage] = useState(
    theme.desktopImage
  );

  const dataChange = useSelector(selectChangeData);
  const shouldSave = useSelector(selectShouldSave);

  const [imageName, setImageName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [imageBlob, setImageBlob] = useState<any>();

  const isElementInViewport = (el: any) => {
    const rect = el ? el.getBoundingClientRect() : "";

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight ||
          document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <=
        (window.innerWidth ||
          document.documentElement.clientWidth) /* or $(window).width() */
    );
  };

  if (backgroundIndex === themes.length) {
    setBackgroundIndex(-1);
  }
  if (fontIndex === fontArray.length) {
    setFontIndex(-1);
  }
  useEffect(() => {
    const object = document.getElementById(
      `background-img-${backgroundIndex}`
    )!;
    const isInView = isElementInViewport(object);
    if (isInView) {
      object?.scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "auto",
      });
      if (!object) {
        setBackgroundIndex(-1);
      }
    }
  }, [backgroundIndex, setBackgroundIndex]);

  useEffect(() => {
    if (theme.themeName === "custom") {
      setBackgroundIndex(-1);
    } else {
      const index = themes.findIndex(
        (e) => e.mobileImage === theme.mobileImage
      );
      setBackgroundIndex(index);
    }
  }, [theme.mobileImage]);

  useEffect(() => {
    const object = document.getElementById(`font-style-${fontIndex}`)!;
    const isInView = isElementInViewport(object);
    if (isInView) {
      object?.scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "smooth",
      });
      if (!object) {
        setFontIndex(-1);
      }
    }
  }, [fontIndex, setFontIndex]);

  useEffect(() => {
    if (theme.themeName === "custom") {
      setFontIndex(-1);
    } else {
      const index = fontArray.findIndex(
        (englishFont) => englishFont === theme.englishFont
      );
      setFontIndex(index);
    }
  }, [theme.englishFont]);

  useEffect(() => {
    if (shouldSave) {
      handleSave();
    }
  }, [shouldSave]);

  useEffect(() => {
    setSelectedIconStyle(theme.iconSet);
    // setSelectedFont(theme.arabicFont);
    setSelectedFont(theme.englishFont);
    setSelectedCardShape(theme.cardShape);
    setSelectedDisplayLocale(theme.displayLocale);
  }, [theme]);

  const handleRemoveImage = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setImageBlob("");
    setImageName("");
    store.dispatch(
      setUserTheme({
        ...theme,
        mobileImage: originalMobileImage,
        desktopImage: originalDesktopImage,
      })
    );
    store.dispatch(setDataChange(true));
  };

  const colorPickerSections = [
    {
      key: "cardColor",
      title: t("card_color"),
      fieldName: "cardColor",
      defaultColor: theme.cardColor || "",
    },
    {
      key: "borderColor",
      title: t("card_border_color"),
      fieldName: "borderColor",
      defaultColor: theme.borderColor || "",
    },
    {
      key: "cardText",
      title: t("card_text_color"),
      fieldName: "cardTextColor",
      defaultColor: theme.cardTextColor || "",
    },
    {
      key: "nonCardText",
      title: t("non_card_text_color"),
      fieldName: "nonCardTextColor",
      defaultColor: theme.nonCardTextColor || "",
    },
    {
      key: "boxShadow",
      title: t("card_shadow"),
      fieldName: "boxShadow",
      defaultColor: theme.boxShadow || "",
    },
  ];

  const iconColorSection = [
    {
      key: "",
      title: "To be removed",
      fieldName: "",
      defaultColor: "",
    },
    {
      key: "mobileSocialMediaIconColor",
      title: t("mobile_social_media_color"),
      fieldName: "mobileSocialMediaColor",
      showInfo: true,
      infoContent: mobileInfoCard,
      defaultColor: theme.mobileSocialMediaColor || "",
    },
    {
      key: "desktopSocialMediaColor",
      title: t("desktop_social_media_color"),
      fieldName: "desktopSocialMediaColor",
      showInfo: true,
      infoContent: desktopInfoCard,
      defaultColor: theme.desktopSocialMediaColor || "",
    },
  ];

  const backgroundColorSection = [
    {
      key: "backgroundColor",
      title: t("bg_color"),
      fieldName: "backgroundColor",
      defaultColor: theme.backgroundColor || "",
    },
  ];

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await readFile(e.target.files[0]);
      setImageName(e.target.files[0].name);
      setImageBlob(imageDataUrl);
      e.target.value = null;
    }
    setIsOpen(true);
  };

  const handleOpenChoseImage = () => {
    document.getElementById("myInput")?.click();
    store.dispatch(setDataChange(true));
  };

  const scrollLeft = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollLeft -= 100;
    }
  };

  const scrollRight = (id: string) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollLeft += 100;
    }
  };

  const handleSave = async () => {
    const result = await themeRequest.doRequest({
      ...theme,
      // id: user.me.linksUser.id,
    });
    await refreshMe();
    raiseToast({ message: t("changes_saved") });
    store.dispatch(setDataChange(false));
    store.dispatch(setShouldSave(false));
    setImageName("");
  };

  const setSelectedLang = (lang: string) => {
    setSelectedDisplayLocale(lang);
    store.dispatch(setUserTheme({ ...theme, displayLocale: lang }));
    store.dispatch(setDataChange(true));
  };

  useEffect(() => {
    if (themeRequest.isFetching) {
      setIsFetching(true);
    } else if (themeRequest.isFetched) {
      setIsFetching(false);
    }
  }, [themeRequest]);

  return (
    <div className={styles.container}>
      <div className={styles.loadingOnSave}>
        {isFetching && (
          <Loader type="TailSpin" color="#FF002B" height={80} width={80} />
        )}
      </div>
      <RouteLeavingGuard
        when={dataChange}
        navigate={() => "/profile/design"}
        shouldReFetch={true}
        shouldBlockNavigation={() => true}
        saveFunction={handleSave}
      />
      <div className={styles.lang_follower_wrapper}>
        <div className={styles.locale_wrapper}>
          <span className={styles.section_name}>{t("display_language")}</span>
          <div className={styles.tabs}>
            <button
              className={
                selectedDisplayLocale === "en" ? styles.tabs__selected : ""
              }
              onClick={() => setSelectedLang("en")}
            >
              {t("english")}
            </button>
            <button
              className={
                selectedDisplayLocale === "ar" ? styles.tabs__selected : ""
              }
              onClick={() => setSelectedLang("ar")}
            >
              {t("arabic")}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.color_section_wrapper}>
        <span className={`${styles.section_name} ${styles.color_title}`}>
          {t("color")}
        </span>
        <div className={styles.color_subtitle}>{t("set_your_own_style")}</div>
        <div className={`${styles.color_sections} ${styles.padding_28_top}`}>
          {colorPickerSections.map((item: any, index: number) => {
            return item.key ? (
              <div key={item.key}>
                <ColorSectionItem
                  theme={theme}
                  fieldName={item.fieldName}
                  showInfo={item.showInfo}
                  infoContent={item.infoContent}
                  title={item.title}
                  defaultColor={item.defaultColor}
                />
              </div>
            ) : (
              <IconStylePicker
                theme={theme}
                selectedIconStyle={selectedIconStyle!}
                setSelectedIconStyle={setSelectedIconStyle}
              />
            );
          })}
        </div>
        <div className={styles.icon_container}>
          <div
            className={`${styles.color_sections} ${styles.color_sections_full}`}
          >
            <IconStylePicker
              theme={theme}
              selectedIconStyle={selectedIconStyle!}
              setSelectedIconStyle={setSelectedIconStyle}
            />
          </div>
          <div
            className={`${styles.color_sections} ${styles.color_sections_full}`}
          >
            {iconColorSection.map((item: any, index: number) => {
              return item.key ? (
                <div key={item.key}>
                  <ColorSectionItem
                    theme={theme}
                    fieldName={item.fieldName}
                    showInfo={item.showInfo}
                    infoContent={item.infoContent}
                    title={item.title}
                    defaultColor={item.defaultColor}
                  />
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>

      <div className={styles.card_shape_wrapper}>
        <span className={styles.section_name}>{t("card_shape")}</span>
        <div className={styles.shape_sections}>
          {cardShapeDetails.map((item: any, index: number) => {
            return (
              <div
                className={`${styles.shape_sections_container} ${
                  selectedCardShape === item.name ? styles.active : ""
                }`}
                key={index}
              >
                <div
                  className={
                    selectedCardShape === item.name
                      ? styles.shape_item_selected
                      : styles.shape_item
                  }
                  onClick={() => {
                    setSelectedCardShape(item.name);
                    store.dispatch(
                      setUserTheme({ ...theme, cardShape: item.name })
                    );
                    store.dispatch(setDataChange(true));
                  }}
                >
                  <div
                    className={
                      selectedCardShape === item.name
                        ? styles.shape_selected
                        : styles.shape
                    }
                    style={{
                      borderRadius: item.border.borderRadius,
                    }}
                  ></div>
                </div>
                <span
                  className={
                    selectedCardShape === item.name
                      ? styles.shape_title_selected
                      : styles.shape_title
                  }
                >
                  {t(item.name)}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.backgroundImage_wrapper}>
        <span className={styles.section_name}>{t("background")}</span>
        {isOpen && (
          <ModalCrop
            originalImageurl={theme.mobileImage!}
            handleCancelUpload={() => {
              setImageBlob("");
              setImageName("");
            }}
            setIsOpen={setIsOpen}
            imgSrc={imageBlob}
            setImageUrl={(image) => {
              if (image.includes("base64")) {
                new Compressor(convertBase64ToBlob(image), {
                  quality: 0.9,
                  convertTypes: [
                    "image/png",
                    "image/webp",
                    "image/jpg",
                    "image/jpeg",
                  ],
                  convertSize: 1000000,
                  async success(result) {
                    const baseImage = (await blobToBase64(result)) as string;
                    setImageBlob(baseImage);
                    store.dispatch(
                      setUserTheme({
                        ...theme,
                        mobileImage: baseImage,
                        desktopImage: baseImage,
                        themeName: "custom",
                      })
                    );

                    store.dispatch(setDataChange(true));
                  },
                  error(err) {
                    console.log(err.message);
                  },
                });
              } else {
                setImageBlob(image);
                store.dispatch(
                  setUserTheme({
                    ...theme,
                    mobileImage: image,
                    desktopImage: image,
                    themeName: "custom",
                  })
                );
              }
            }}
            type={"theme"}
          />
        )}

        <div className={styles.input_buttons_wrapper}>
          {backgroundColorSection.map((item: any, index: number) => {
            return (
              <div
                key={`bg-${index}`}
                className={styles.input_background_container}
              >
                <ColorSectionItem
                  title={item.title}
                  fieldName={item.fieldName}
                  theme={theme}
                  defaultColor={item.defaultColor}
                />
              </div>
            );
          })}
          <div className={styles.input_file_container}>
            <span></span>
            <div className={styles.input_file_container_body}>
              <div className={styles.input_file}>
                <img src={BackgroundImage} />
                <div className={styles.input_file_content}>{imageName}</div>
                {imageName.length > 0 && (
                  <div
                    className={styles.remove_button}
                    onClick={handleRemoveImage}
                  >
                    <img src={trashGrey} />
                  </div>
                )}
              </div>
              <div
                className={styles.upload_button}
                onClick={handleOpenChoseImage}
              >
                {/* <img src={UploadIcon} /> */}
                <input
                  id="myInput"
                  style={{ display: "none" }}
                  type={"file"}
                  accept="image/*"
                  onChange={fileSelectedHandler}
                />
                {t("upload")}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.choose_gallery}>
          {t("choose_from_gallery")}
          <div>
            {width > 768 && backgroundIndex > 0 && (
              <img
                className={styles.arrowLeft}
                src={arrowLeft}
                onClick={() => handleBackgroundLeft()}
              />
            )}
            {width > 768 && backgroundIndex < themes.length - 1 && (
              <img
                className={styles.arrowRight}
                src={arrowRight}
                onClick={() => handleBackgroundRight()}
              />
            )}
          </div>
        </div>
        <div className={styles.backgrounds} id="background">
          {themes.map((item: any, index: number) => {
            return (
              <>
                <div
                  key={index}
                  className={styles.background_item}
                  onClick={() => {
                    setBackgroundIndex(index);
                    setSelectedBackground(item.themeName);
                    store.dispatch(
                      setUserTheme({
                        ...theme,
                        mobileImage: item.mobileImage,
                        desktopImage: item.desktopImage,
                      })
                    );
                    store.dispatch(setDataChange(true));
                    setImageName("");
                    setImageBlob(null);
                    setBackgroundIndex(index);
                  }}
                  // style={selectedBackground === item.themeName ? {border: '1px solid #208CED'} : {}}
                  style={
                    backgroundIndex === index
                      ? { border: "1px solid #208CED" }
                      : {}
                  }
                  id={`background-img-${index}`}
                >
                  <img src={item.mobileImage} />
                </div>
                <div
                  id={`${
                    backgroundIndex != -1 && backgroundIndex == index
                      ? "selectedBackground"
                      : ""
                  }`}
                ></div>
              </>
            );
          })}
        </div>
      </div>

      <div className={styles.font_wrapper}>
        <span className={styles.section_name}>
          {t("font")}
          {width > 768 && (
            <div>
              {fontIndex > 0 && (
                <img
                  className={styles.arrowLeft}
                  src={arrowLeft}
                  onClick={() => handleFontLeft()}
                />
              )}
              {fontIndex < fontArray.length - 1 && (
                <img
                  className={styles.arrowRight}
                  src={arrowRight}
                  onClick={() => handleFontRight()}
                />
              )}
            </div>
          )}
        </span>
        <div
          className={styles.backgrounds}
          id="fonts"
          style={{ marginTop: 0, gap: "8px" }}
        >
          {Object.values(englishFonts).map((value, index) => {
            return (
              <>
                <div
                  key={index}
                  id={`font-style-${index}`}
                  className={`${styles.font_item} ${styles.background_item} ${
                    fontIndex === index ? styles.selected_font : ""
                  }`}
                  onClick={() => {
                    setFontIndex(index);
                    setSelectedFont(value);
                    store.dispatch(
                      setUserTheme({ ...theme, englishFont: value })
                    );
                    // setChange(true);
                    store.dispatch(setDataChange(true));
                  }}
                >
                  <span style={{ fontFamily: value }} className={styles.text}>
                    Aa
                  </span>
                  <span style={{ fontFamily: value }} className={styles.value}>
                    {t(value)}
                  </span>
                </div>
                <div
                  id={`${
                    fontIndex != -1 && fontIndex == index ? "selectedFont" : ""
                  }`}
                ></div>
              </>
            );
          })}
        </div>
      </div>

      {/* <div className={styles.font_wrapper}>
        <span className={styles.section_name}>{t('font')}</span>
           <div className={styles.fonts}>
              {Object.values(englishFonts).map((value, index) => {
              return (
                <div key={index} className={styles.font_item} onClick={() => {
                  setSelectedFont(value);
                  store.dispatch(setUserTheme({...theme, 'englishFont': value}));
                  // setChange(true);
                  store.dispatch(setDataChange(true));
                }}
                style={selectedFont === value ? {border: '1px solid #208CED'}: {}}>
                  <span style={selectedFont === value ? {color: '#208CED', fontFamily: value}: {fontFamily: value}} className={styles.text}>Aa</span>
                  <span style={selectedFont === value ? {color: '#208CED', fontFamily: value}: {fontFamily: value}} className={styles.value}>{t(value)}</span>
                </div>
              );
            })}
          </div>
      </div> */}

      {/* <div className={styles.buttons_wrapper}>
        <div className={styles.save_button} onClick={() => handleSave()}>
            Save
        </div>
      </div> */}
    </div>
  );
};

export default CustomTheme;
