import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Compressor from 'compressorjs';
import {useSelector} from "react-redux";
import {store} from "../../app/store";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {blobToBase64, convertBase64ToBlob} from "../../Helper/imageCropper";
import ModalCrop from "../ModalCrop/ModalCrop";
import styles from "./AvatarDisplay.module.scss";
import defaultAvatar from '../../assets/images/al_default_avatar.png';
import EditImg from '../../assets/images/Version3/icons/editImage.svg';
import EditImgOnboard from '../../assets/images/versionV2/Profile/onboard_edit.svg';
import CreateIcon from '@material-ui/icons/Create';

const selectUser = (state: any) => state.user.me.linksUser;

type AvatarProps = {
  onBoard?: boolean;
};

const ImageUploader = ({fileSelectedHandler}: any): JSX.Element => {
    return <input
    id="avatarInput"
    style={{display: 'none'}}
    type='file'
    onChange={(e) => {
        fileSelectedHandler(e);
    }}
/>;
};

const AvatarDisplay = ({onBoard = false}: AvatarProps)=> {
  const {t} = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const linksUser = useSelector(selectLinksUser);
  const user = useSelector(selectUser);

  const handleOpenChoseImage = () => {
    document.getElementById('avatarInput')?.click();
  };

  const readFile = (file: any) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const fileSelectedHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await readFile(e.target.files[0]) as string;
      store.dispatch(setLinksUser({...linksUser, avatar: imageDataUrl}));
    }
    setIsOpen(true);
  };

  const getInitials = () => {
    const nameParts = linksUser?.fullname?.trim()?.split(' ');
    const initials = nameParts?.map((part) => part[0]?.toUpperCase())?.join('');
    return initials;
  };

  return (
      <>
      {isOpen && (
        <ModalCrop setIsOpen={setIsOpen} imgSrc={linksUser.avatar} setImageUrl={(image) => {
          if (image.includes('base64')) {
            new Compressor(convertBase64ToBlob(image), {
              quality: 0.8,
              async success(result) {
                const baseImage = await blobToBase64(result) as string;
                store.dispatch(setLinksUser({...linksUser, avatar: baseImage}));
                store.dispatch(setDataChange(true));
              },
              error(err) {
                console.log(err.message);
              },
            });
          } else {
            store.dispatch(setLinksUser({...linksUser, avatar: image}));
          }
        }}
        type={'profile'}
        handleCancelUpload={() => {
          store.dispatch(setLinksUser({...linksUser, avatar: user?.avatar?.url}));
        }}
        />
      )}
      {onBoard ? <div className={`${styles.avatar_wrapper_onboard} ${linksUser?.avatar ? styles.celebrate : ''}`} onClick={handleOpenChoseImage}>
        {linksUser?.avatar && <>
          <div className={styles.image_container}>
            <img src={linksUser.avatar || defaultAvatar} className={styles.avatar} />
            <img src={EditImgOnboard} className={`${styles.edit_img} ${styles.onboard_img}`} />
            <ImageUploader fileSelectedHandler={fileSelectedHandler} />
          </div>
           <div className={styles.looks}>
            {t('look_amazing')}
          </div></>}
        {!!!linksUser?.avatar && <>
          <div className={styles.image_container_no_avatar}>
            {getInitials()}
            <div className={styles.optional}>{t('optional')}</div>
          </div>
           <div className={styles.upload_photo}>
            {t('upload_photo')}
          </div></>}
          <ImageUploader fileSelectedHandler={fileSelectedHandler} />
      </div> : <div
          className={styles.avatar_wrapper}
          onClick={handleOpenChoseImage}
          style={{
              backgroundImage: `url(${linksUser.avatar || defaultAvatar})`,
          }}
      >
          <img src={EditImg} className={styles.edit_img} />
          <ImageUploader fileSelectedHandler={fileSelectedHandler} />
      </div>}
      </>
  );
};

export default AvatarDisplay;
