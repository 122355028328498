import React, { useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../app/store";
import {
  selectLinksUser,
  setLinksUser,
} from "../../features/stores/linksUserSlicer";
import { setDataChange } from "../../features/stores/changeDataSlicer";
import { useTranslation } from "react-i18next";
import styles from "./UserSettings.module.scss";
import TextFieldInput from "../TextFieldInput/TextFieldInput";
import AvatarDisplay from "../AvatarDisplay/AvatarDisplay";
import CountrySelect from "../CountrySelect/CountrySelect";
import RegionSelect from "../RegionSelect/RegionSelect"; // Assuming RegionSelect is properly set up
import Loader from "react-loader-spinner";
import CreatorProfile from "../../components/CreatorProfile/CreatorProfile";
import { logOut, refreshMe } from "../../features/Login/login";
import {ReactComponent as LogoutIcon} from "../../assets/images/Version3/icons/logout.svg";
import useRequest from "../../features/API/request";
import { raiseToast } from "../../components/Toast/Toast";
import { MAX_BIO_CHARACTERS } from "../../constants/helper";
import TextInput from "../LinkModal/components/TextInput";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import ReferralBanner from "../ReferralBanner/ReferralBanner";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import useWindowWidth from "../../hooks/useWindowWidth";

const UserSettings: React.FC = () => {
  const { t } = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const width = useWindowWidth();
  const [showFollower, setShowFollower] = useState(linksUser.displayFollower);
  const [loading, setLoading] = useState(false);
  const updateProfileRequest = useRequest({
    method: "POST",
    url: "/linksUser/profile-settings",
  });

  const savePersonalInfo = async () => {
    try {
      setLoading(true);
      const payload = {
        bio: linksUser.bio,
        fullname: linksUser.fullname,
        location: linksUser.location,
        displayFollower: linksUser.displayFollower,
        city: linksUser.city,
        contentCategoryIds: linksUser.contentCategoryIds || [],
        categoryIds: linksUser.categoryIds || [],
        phone: linksUser.phone,
        gender: linksUser.gender,
      };
      await updateProfileRequest.doRequest(payload);
      raiseToast({ message: t("changes_saved") });
      store.dispatch(setDataChange(false));
      await refreshMe();
      setLoading(false);
      return true;
    } catch (error) {
      setLoading(false);
      return false;
    }
  };

  const handleFollowerToggle = (value: boolean) => {
    setShowFollower(value);
    store.dispatch(setLinksUser({ ...linksUser, displayFollower: value }));
    store.dispatch(setDataChange(true));
  };

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newBio = e.target.value.slice(0, MAX_BIO_CHARACTERS);
    store.dispatch(setLinksUser({ ...linksUser, bio: newBio }));
    store.dispatch(setDataChange(true));
  };

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <h1>{t("settings")}</h1>
      </div>
      <div className={styles.right}>
        <div className={styles.inputs_wrapper}>
          <TextFieldInput
            label={t("name")}
            value={linksUser.fullname as string}
            customStyle={{ padding: "27px 22px 10px" }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              store.dispatch(
                  setLinksUser({ ...linksUser, fullname: e.target.value })
              );
              store.dispatch(setDataChange(true));
            }}
          />
        </div>
        <div className={styles.inputs_wrapper}>
          <div className={styles.component_container}>
            <CountrySelect />
          </div>
        </div>

        <div className={styles.inputs_wrapper}>
          <div className={styles.component_container}>
            <RegionSelect />
          </div>
        </div>

        <div className={styles.container}>
          {/* Other fields */}

          {/* Bio Field */}
          <div className={styles.inputs_wrapper}>
            <TextInput
              label={t("bio")}
              value={linksUser.bio as string}
              maxLength={MAX_BIO_CHARACTERS}
              onChange={handleBioChange}
              containerClassName={styles.textInputContainer}
              multiline
              minRows={5}
            />
            <p className={styles.characterCounter}>
              {`${(linksUser.bio || "").length}/${MAX_BIO_CHARACTERS}`}
            </p>
          </div>
        </div>

        {(
          <div className={styles.creator_profile}>
            <div className={styles.follower_language}>
              <LanguageToggle/>
              <div className={styles.followers_wrapper}>
                <label className={styles.label}>{t("display_follower")}</label>
                <div className={styles.tabs}>
                  <button
                    className={showFollower ? styles.tabs__selected : ""}
                    onClick={() => handleFollowerToggle(true)}
                  >
                    {t("yes")}
                  </button>
                  <button
                    className={!showFollower ? styles.tabs__selected : ""}
                    onClick={() => handleFollowerToggle(false)}
                  >
                    {t("no")}
                  </button>
                </div>
              </div>
            </div>
            <CreatorProfile />
            <ReferralBanner />
            <div className={styles.buttons_wrapper}>
              <PrimaryButton
                avatar={<LogoutIcon />}
                label={t("logout")}
                onClick={() => logOut()}
                color="black"
                style={{ width: '100%', padding: '10px 0' }}
              />
              <PrimaryButton
                label={t('save')}
                onClick={savePersonalInfo}
                disabled={updateProfileRequest.isFetching}
                color="red"
                style={{ width: '100%', padding: '10px 0' }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSettings;
