import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {dealItem, userDeal} from '../../features/stores/dealsSlicer';
import defaultStyle from '../PublicProfileItemCard/PublicProfileItemCard.module.scss';
import desktopPreviewStyle from '../PublicProfileItemCard/PublicProfileItemCard_desktop-preview.module.scss';
import mobilePreviewStyle from '../PublicProfileItemCard/PublicProfileItemCard_mobile-preview.module.scss';
import {UserThemeState} from '../../features/stores/userThemeSlicer';
import {previewStyles} from '../../constants/newThemes';
import ViewDealDetailsModal from './ViewDealDetailsModal';
import useWindowWidth from '../../hooks/useWindowWidth';
import {toast} from 'react-toastify';

interface IProps{
  dealItem: dealItem;
  theme: UserThemeState;
  selectedStyle: string;
  handleDealClick: (id: string) => {};
}

interface LanguageTexts {
    months: string;
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
};

const dealsMaxCharLength = 180;

const PublicProfileDeal = ({dealItem, theme, selectedStyle, handleDealClick}: IProps) => {
  const {t, i18n} = useTranslation();
  const mobile = useWindowWidth();
  const [openDealDetailsModal, setOpenDealDetailsModal] = useState(false);
  const translate = (key : string) => {
    return t(key, {lng: theme.displayLocale || 'en'});
  };
  const fontFamily = theme.englishFont!;
  const parentStyles = selectedStyle === previewStyles.DESKTOP_PREVIEW ? desktopPreviewStyle : (selectedStyle === previewStyles.MOBILE_PREVIEW ? mobilePreviewStyle : defaultStyle);
  const titleLength = ((selectedStyle === previewStyles.MOBILE_PREVIEW || selectedStyle === previewStyles.DESKTOP_PREVIEW) || mobile < 786) ? 30 : 80;
  const descriptionLength = 999999999999999;
  const sitePreview = [previewStyles.DESKTOP_PREVIEW, previewStyles.MOBILE_PREVIEW].includes(selectedStyle);
  const [showSeeMore, setShowSeeMore] = useState((dealItem?.description || dealItem?.deal?.description).length > dealsMaxCharLength);
  const dealDescription = dealItem?.description || dealItem?.deal?.description;

  const handleShowMoreLess = (idMore: any, idLess: any, param: any) => {
    const more: any = document.getElementById(idMore);
    const less: any = document.getElementById(idLess);
    if (more && less) {
      if (param == 'more') {
        more.style.display = 'none';
        less.style.display = 'block';
      } else {
        more.style.display = 'block';
        less.style.display = 'none';
      }
    }
  };

  const getCollapseText = (title: string, id: string, param: string, trimLength: number = 80) => {
    return (
        title?.length > trimLength ? (<>
          <div className={`${parentStyles.show_less} ${parentStyles[param]}`} id={`show-more-${param}-${id}`} onClick={(e) => {
            e.stopPropagation();
            handleShowMoreLess(`show-more-${param}-${id}`, `show-less-${param}-${id}`, 'more');
          }}>{title?.substring(0, trimLength)}...</div>
          <div className={`${parentStyles.show_less} ${parentStyles[param]}`} style={{display: 'none'}} id={`show-less-${param}-${id}`} onClick={(e) => {
            e.stopPropagation();
            handleShowMoreLess(`show-more-${param}-${id}`, `show-less-${param}-${id}`, 'less');
          }}>{title}</div>
        </>
            ) : (title || '--')
    );
  };

  return (
    <div className={`${parentStyles.container} ${parentStyles.deals_main}`}>
      <div onClick={() => {
        if (selectedStyle === previewStyles.DEFAULT) {
          handleDealClick(dealItem?.id);
          setOpenDealDetailsModal(true);
        } else {
          toast(
              <span style={{color: 'black'}}>
                {t('not_active_from_section')}
              </span>,
              {autoClose: 3000},
          );
        }
      }} className={`${parentStyles.deal_container} ${parentStyles.dealsContainer}`}
      style={{
        backgroundColor: theme.cardColor!,
        boxShadow: theme.boxShadow!,
        fontFamily: fontFamily,
        color: theme.cardTextColor!,
        border: '1px solid ' + theme.borderColor,
      }}>
        <div className={`${parentStyles.dealDiv} ${parentStyles.dealDiv}`} style={{cursor: 'pointer'}}>
          <div className={parentStyles.top}>
            <div className={parentStyles.logo_text_container}>
              <div>
                <img src={dealItem?.logoUrl || dealItem?.deal?.logoUrl} />
              </div>
              <div>
                <div className={parentStyles.break_word} title={dealItem?.deal?.name}>
                  {getCollapseText(dealItem?.deal?.name || '', dealItem?.id, 'title-box', titleLength)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewDealDetailsModal 
        theme={theme} 
        setIsOpen={setOpenDealDetailsModal} 
        open={openDealDetailsModal} 
        userDeal={dealItem as any} 
        deal={dealItem?.deal} 
        translates={translate}
        onClose={() => {
          setOpenDealDetailsModal(false);
        }}
      />
    </div>
  );
};

export default PublicProfileDeal;
