import React, {useMemo} from 'react';
import TextInput from './TextInput';
import {useSelector} from 'react-redux';
import {getConfig} from '../../../features/stores/configSlicer';
import {haveSegmentPermission} from '../../../features/util';
import {MonetizeLink, MonetizeType} from '../modals/types';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem} from '../../../features/stores/linksUserSlicer';

type Props = {
  editable: boolean;
  onChange: (price?: number) => void;
  link: LinksSectionItem;
  pickerData?: {index: number};
};

export const useMonetizePrice = (currency?: string, type?: MonetizeType) => {
  const {t} = useTranslation();
  const config = useSelector(getConfig).data || [];
  const monetizationConfig: MonetizeLink[] = haveSegmentPermission(config, 'monetize2');
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];
  const getConversionFactor = (currency?: string) => {
    const currencyInfo = currencyMap.find((e: any) => e.currency === currency);
    return currencyInfo ? Number(currencyInfo.conversionFactor) : 100;
  };
  const minAmount = useMemo(() => {
    const defaultMonetize = monetizationConfig.find((item) => item.id === type)!;
    return defaultMonetize.minPrice / getConversionFactor(currency) || 1;
  }, [type, currency, monetizationConfig]);

  const maxAmount = useMemo(() => {
    const defaultMonetize = monetizationConfig.find((item) => item.id === type)!;
    return defaultMonetize.maxPrice / getConversionFactor(currency) || 5000;
  }, [type, currency, monetizationConfig]);

  const validate = (priceInCents: {type: string, value: string, amount: number}[]) => {
    for (let i = 0; i < priceInCents?.length; i++) {
      const price = display(priceInCents[i]?.amount);
      if (price < minAmount) {
        return t('amount_cant_be_less_than') + minAmount;
      } else if (price > maxAmount) {
        return t('amount_cant_be_greater_than') + maxAmount;
      }
      return '';
    }
  };

  const display = (priceInCents: number) => {
    return priceInCents / 100;
  };

  return {minAmount, maxAmount, validate, display};
};
const PriceInput = ({link, onChange, editable, pickerData={index: 0}}: Props) => {
  const {t} = useTranslation();

  const {validate, display} = useMonetizePrice(link.currency, link.type as MonetizeType);

  const savePrice = (price: string) => {
    const numericPrice = parseInt(price);
  
    if (numericPrice < 0 || isNaN(numericPrice)) {
      onChange(undefined); // Reset the value if negative or invalid
      return;
    }
  
    const cents = numericPrice * 100;
    onChange(cents);
  };
  return (
    <TextInput
      value={display(link['price'][pickerData.index].amount)}
      onChange={(e) => savePrice(e.target.value)}
      disabled={!editable}
      label={t('price')}
      type='number'
      error={validate(link['price'][pickerData.index].amount)}
    />
  );
};

export default PriceInput;
