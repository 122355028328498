import React, {useState, useEffect} from 'react';
import styles from './ViewDealDetailsModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {ReactComponent as ExternalLinkIcon} from '../../assets/images/external_link.svg';
import {deal, userDeal} from '../../features/stores/dealsSlicer';
import {PrimaryButton} from '../Buttons/PrimaryButton';
import {useTranslation} from "react-i18next";
import nonHookRequest from '../../features/API/nonHookRequest';
import {ReactComponent as CheckIcon} from '../../assets/images/shop/check.svg';
import {truncateUrl} from '../../Helper/generalHelper';
import cardStyles from '../AddCollectionModal/AddCollectionModal.module.scss';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Loader from 'react-loader-spinner';

interface AddCollectionModalProps {
  open: boolean;
  setIsOpen: (arg: boolean) => void;
  userDeal: userDeal;
  deal: deal;
  handleAddingCollection: (collection: any, resetForm: () => void) => void;
}

export const AddDealCollectionModal = ({
  open, 
  setIsOpen, 
  deal, 
  userDeal,
  handleAddingCollection,
}: AddCollectionModalProps) => {
  const [products, setProducts] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");

  const {t} = useTranslation();

  const getDealProducts = async () => {
    setLoading(true);
    const products = await nonHookRequest({
      method: 'GET',
      url: `/brandshub/api/product?start=0&limit=100&userDealId=${userDeal?.id}`,
      isShortUrl: true,
    });
    setProducts(products?.products);
    setLoading(false);
  };

  useEffect(() => {
    getDealProducts();
  }, []);

  const resetForm = () => {
    setName("");
    setSelectedProducts([]);
  };

  return (
    open ? 
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.modal_content} style={{minWidth: '500px', padding: '20px'}}>
          <div className={styles.cross} onClick={() => setIsOpen(false)}><CloseIcon /></div>
          <div className={styles.content_body}>
            
            <div className={styles.row_top}>
              <a
                href={userDeal?.dealTrackingLink || "#"} 
                rel="noreferrer" 
                target={userDeal?.dealTrackingLink ? "_blank" : "_self"}
                className={styles.content_image}>
                <img src={deal?.logoUrl || deal?.logoUrl} />
              </a>
              <div className={styles.content}>
                <a
                  href={userDeal?.dealTrackingLink || "#"} 
                  rel="noreferrer" 
                  target={userDeal?.dealTrackingLink ? "_blank" : "_self"}
                  className={styles.content_headline}
                >
                  {deal?.name}
                  <ExternalLinkIcon className={styles.external_icon} />
                </a>
              </div>
            </div>

            <div className={styles.row_middle}>
              <div className={styles.sectionContainer}>
                <OutlinedInput
                  className={cardStyles.search}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={t('collection_title')}
                />
              </div>

              {loading ? 
                <div className={styles.loader}>
                  <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                </div> :
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {products?.length ? products?.map((product, i) => {
                    const currentProductExist = selectedProducts?.length && selectedProducts.find((item) => item?.id === product?.id);
                    return (
                      <div 
                        key={i} 
                        className={cardStyles.dealContainer}
                        style={{width: '30%'}}
                      >
                        <div className={cardStyles.imageContainer}>
                          <img
                            src={product?.logoUrl}
                            className={currentProductExist ? 
                              `${cardStyles.dealImage} ${cardStyles.selected}` : 
                              `${cardStyles.dealImage}`}
                            alt=""
                            onClick={() => setSelectedProducts((prev) => currentProductExist ? 
                              prev.filter((item) => item?.id !== product?.id) : 
                              [...prev, 
                              {id: product?.id, title: product?.title, logoUrl: product?.logoUrl, trackingLink: product?.trackingLink}])
                            }
                          />
                          {currentProductExist ? <CheckIcon className={cardStyles.checkMark} /> : null}
                        </div>
                        <div className={cardStyles.textWrapper}>
                          <p className={cardStyles.dealName}>{truncateUrl(product?.title, 18)}</p>
                        </div>
                      </div>
                    );
                  }): null}
                </div>
              }
            </div>

            <PrimaryButton 
              label={t('add_collection')}
              onClick={() => handleAddingCollection({
                name: name,
                products: selectedProducts.map((product) => {
                  return {product};
                }),
              }, resetForm)} 
              disabled={!name || !selectedProducts?.length} 
              style={{width: '180px', height: '35px', alignSelf: 'center', marginBottom: '10px'}}
            />

          </div>
        </div>
      </div>
    </div> : <></>
  );
};
