/* eslint-disable */
import React, {useState} from "react";
import styles from './AddProductDetails.module.scss';
import {Typography} from "@material-ui/core";
import {useUploadRequest} from "../../../features/API/request";
import {useTranslation} from 'react-i18next';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {toast} from 'react-toastify';
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from 'react-loader-spinner';
import {PrimaryButton} from "../../Buttons/PrimaryButton";
import {ReactComponent as EditIcon} from '../../../assets/images/shop/edit_rounded.svg';
import placeholder from '../../../assets/images/placeholder.jpg';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {makeStyles} from '@material-ui/core/styles';
import ConfirmationModal from "../../LinkModal/modals/ConfirmationModal";
import {raiseToast} from "../../Toast/Toast";
import nonHookRequest from "../../../features/API/nonHookRequest";
import {DeletingProps} from "../AddTrackingLinkModal";

interface AddProductDetailsProps {
  handleAddingProduct: () => void;
  handleDeletingProduct: ({productId, setOpenDeleteConfirmationModal}: DeletingProps) => Promise<void>;
  productTitle: string | null;
  productLink: string | null;
  productLogo: string | null;
  setProductTitle: React.Dispatch<React.SetStateAction<string | null>>;
  setProductLink: React.Dispatch<React.SetStateAction<string | null>>;
  setProductLogo: React.Dispatch<React.SetStateAction<string | null>>;
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  collectionIds: string[];
  setCollectionIds: React.Dispatch<React.SetStateAction<string[]>>;
  collections: any[];
  isEditing: boolean;
  productId?: string;
  onClose: any;
}

export const AddProductDetails = ({
  handleAddingProduct,
  handleDeletingProduct,
  productTitle,
  productLink,
  productLogo,
  setProductTitle,
  setProductLink,
  setProductLogo,
  isVisible,
  setIsVisible,
  collectionIds,
  setCollectionIds,
  collections,
  isEditing,
  productId,
  onClose,
}: AddProductDetailsProps) => {
  const [uploading, setUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  const uploadFileRequest = useUploadRequest({method: 'POST', url: '/linksFile/upload'});

  const {t} = useTranslation();

  const useStyles = makeStyles((theme) => ({
    root: {
      color: "#FF5733",
      "&$checked": {
        color: "#E887F9",
      },
    },
    checked: {},
  }));

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const params = {
        file: {
          key: 'file',
          value: file,
          fileName: file.name,
        },
      };
      setUploading(true);
      const response = await uploadFileRequest.doRequest(params);
      if (response.length) {
        setUploading(false);
        setProductLogo(response[0].url);
      }
    }
  };

  const isValidURL = (url: string) => {
    const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    return regex.test(url);
  };

  const classes = useStyles();

  return (
    <div className={styles.container}>
      <h5>{t('add_product_details')}</h5>

      <div className={styles.productInfoContainer}>
        <div className={`${styles.flexItem} ${styles.imgContainer}`}>
          <img
            src={productLogo ? productLogo as string : placeholder}
            alt="Preview"
            className={styles.productImage}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{display: 'none'}}
            id="file-upload"
            disabled={isLoading}
          />
          <label htmlFor="file-upload">
            <EditIcon className={styles.editIcon} />
          </label>
        </div>

        <span style={{margin: '20px'}}></span>

        <div className={`${styles.flexItem} ${styles.inputsWrapper}`}>
          <OutlinedInput
            className={styles.customInput}
            value={productLink}
            onChange={(e) => setProductLink(e.target.value)}
            endAdornment={isLoading ? 
              <InputAdornment position="start">
                <Loader type="TailSpin" color="#EB3B5A" height={20} width={20} />
              </InputAdornment> : 
            null}
            placeholder={t('add_product_link')}
            disabled
          />

          <OutlinedInput
            className={styles.customInput}
            value={productTitle}
            onChange={(e) => setProductTitle(e.target.value)}
            placeholder={t('add_product_title')}
            disabled={isLoading}
          />

          <p className={styles.note}>{t('show_product_in')}</p>

          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isVisible}
                  onChange={() => setIsVisible(!isVisible)}
                  name="checkedB"
                  classes={{
                    root: classes.root,
                    checked: classes.checked,
                  }}
                />
              }
              label={t('my_shop')}
              style={{width: '45%'}}
            />
            {collections?.length ? collections?.map((collection, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={collectionIds.includes(collection.id)}
                      onChange={(e) => {
                        setCollectionIds((prev) => e.target.checked ? 
                          [...prev, collection.id] : 
                          prev.filter((elem) => elem !== collection.id),
                        );
                      }}
                      name="checkedB"
                      style={{color: '#E887F9'}}
                    />
                  }
                  label={collection?.name}
                  style={{width: '45%'}}
                />
              );
            }) : null}

            {uploading && <Typography>{t('uploading')}</Typography>}
          </div>

          <span style={{margin: 'auto 0'}}></span>

          <div style={{display: 'flex'}}>
            <PrimaryButton 
              label={isEditing ? t('save_changes') : t('add_product_link')}
              onClick={async () => {
                if (isValidURL(productLink!)) {
                  setIsLoading(true);
                  await handleAddingProduct();
                  setIsLoading(false);
                } else {
                  toast.error(t('invalid_product_link'));
                }
              }}
              disabled={!productTitle || !productLink || !productLogo || isLoading}
              style={{width: '200px', height: '40px'}}
            />

            {isEditing ? <PrimaryButton 
              label={t('delete_product')}
              onClick={() => setOpenDeleteConfirmationModal(true)}
              disabled={!productTitle || !productLink || !productLogo}
              style={{width: '150px', height: '40px', margin: '0 20px', fontSize: '14px'}}
              color="black"
            /> : null}
          </div>

        </div>
      </div>

      <ConfirmationModal
        title={t('sure_delete_product')}
        primaryButtonText={t('yes_delete_product')}
        handlePrimaryButtonClick={() => 
          handleDeletingProduct({productId: productId!, setOpenDeleteConfirmationModal})
        }
        secondaryButtonText={t('no_keep_product')}
        open={openDeleteConfirmationModal}
        onClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
      />

    </div>
  );
};
