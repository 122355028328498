import React, { useEffect, useState } from "react";
import styles from "../../components/CustomTheme/customTheme.module.scss";
import ColorSectionItem from "../ColorSectionItem/ColorSectionItem";
import UploadIcon from "../../assets/images/al-upload-white.svg";
import BackgroundImage from "../../assets/images/al_background.svg";
import ModalCrop from "../ModalCrop/ModalCrop";
import arrowLeft from "../../assets/images/versionV2/DesignIcons/arrowLeft.svg";
import arrowRight from "../../assets/images/versionV2/DesignIcons/arrowRight.svg";
import {
  arabicFonts,
  cardShape,
  englishFonts,
  themes,
} from "../../constants/newThemes";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../app/store";
import { selectMe } from "../../features/stores/userSlicer";
import useRequest from "../../features/API/request";
import {
  setDataChange,
  setShouldSave,
} from "../../features/stores/changeDataSlicer";
import RouteLeavingGuard from "../UnsavedChanges/RouteLeavingGuard";
import mobileInfoCard from "../../assets/images/info2.svg";
import desktopInfoCard from "../../assets/images/desktopInfoCard2.svg";
import Loader from "react-loader-spinner";
import { refreshMe } from "../../features/Login/login";
import {
  selectUserTheme,
  setUserTheme,
} from "../../features/stores/userThemeSlicer";
import { raiseToast } from "../Toast/Toast";
import IconStylePicker from "../IconStylePicker/IconStylePicker";
import Compressor from "compressorjs";
import { blobToBase64, convertBase64ToBlob } from "../../Helper/imageCropper";
import { useTranslation } from "react-i18next";
import Switch from "@material-ui/core/Switch/Switch";
import useWindowWidth from "../../hooks/useWindowWidth";
import trashGrey from "../../assets/images/al_delete_grey.svg";
import { PrimaryButton } from "../Buttons/PrimaryButton";
import { selectPreviewMode, setPreviewMode } from "../../features/stores/previewModeSlicer";
import { useHistory } from "react-router-dom";

const selectChangeData = (state: any) => state.changeData.changeData;
const selectShouldSave = (state: any) => state.changeData.shouldSaveEverything;

interface ICustomThemeSelect {
  handleBackgroundLeft: () => void;
  handleBackgroundRight: () => void;
  backgroundIndex: number;
  setBackgroundIndex: (arg1: number) => void;
  handleFontLeft: () => void;
  handleFontRight: () => void;
  fontIndex: number;
  setFontIndex: (arg1: number) => void;
  handleSave: () => void;
}

const CustomTheme = ({
  handleBackgroundLeft,
  handleBackgroundRight,
  setBackgroundIndex,
  backgroundIndex,
  handleFontLeft,
  handleFontRight,
  setFontIndex,
  fontIndex,
  handleSave,
}: ICustomThemeSelect) => {
  const { t, i18n } = useTranslation();
  const theme = useSelector(selectUserTheme);
  const history = useHistory();
  const dispatch = useDispatch();
  const previewMode = useSelector(selectPreviewMode);
  const themeRequest = useRequest({ url: "/linksUser/theme", method: "POST" });
  const width = useWindowWidth();
  const cardShapeDetails = [];
  const fontArray = Object.values(englishFonts);
  for (const [key, value] of Object.entries(cardShape)) {
    cardShapeDetails.push(value);
  }

  const [selectedCardShape, setSelectedCardShape] = useState(theme.cardShape);
  const [selectedFont, setSelectedFont] = useState(theme.englishFont);
  const [selectedDisplayLocale, setSelectedDisplayLocale] = useState(
    theme.displayLocale
  );
  const [selectedIconStyle, setSelectedIconStyle] = useState(theme.iconSet);
  const [isFetching, setIsFetching] = useState(false);

  const dataChange = useSelector(selectChangeData);
  const shouldSave = useSelector(selectShouldSave);

  const [imageName, setImageName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [imageBlob, setImageBlob] = useState<any>();

  const getSelectedBgOption = (color: any) => {
    if (!color || color.length == 0) {
      return "customBg";
    } else if (color === "white") {
      return "lightBg";
    } else if (color === "black") {
      return "darkBg";
    }
    return "customBg";
  };

  const [selectedBgOption, setSelectedBgOption] = useState(
    getSelectedBgOption(theme.backgroundColor)
  );

  const isElementInViewport = (el: any) => {
    const rect = el ? el.getBoundingClientRect() : "";

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight ||
          document.documentElement.clientHeight) /* or $(window).height() */ &&
      rect.right <=
        (window.innerWidth ||
          document.documentElement.clientWidth) /* or $(window).width() */
    );
  };

  if (backgroundIndex === themes.length) {
    setBackgroundIndex(-1);
  }
  if (fontIndex === fontArray.length) {
    setFontIndex(-1);
  }
  useEffect(() => {
    const object = document.getElementById(
      `background-img-${backgroundIndex}`
    )!;
    const isInView = isElementInViewport(object);
    if (isInView) {
      object?.scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "auto",
      });
      if (!object) {
        setBackgroundIndex(-1);
      }
    }
  }, [backgroundIndex, setBackgroundIndex]);

  useEffect(() => {
    if (theme.themeName === "custom") {
      setBackgroundIndex(-1);
    } else {
      const index = themes.findIndex(
        (e) => e.mobileImage === theme.mobileImage
      );
      setBackgroundIndex(index);
    }
  }, [theme.mobileImage]);

  useEffect(() => {
    const object = document.getElementById(`font-style-${fontIndex}`)!;
    const isInView = isElementInViewport(object);
    if (isInView) {
      object?.scrollIntoView({
        inline: "center",
        block: "nearest",
        behavior: "smooth",
      });
      if (!object) {
        setFontIndex(-1);
      }
    }
  }, [fontIndex, setFontIndex]);

  useEffect(() => {
    if (theme.themeName === "custom") {
      setFontIndex(-1);
    } else {
      const index = fontArray.findIndex(
        (englishFont) => englishFont === theme.englishFont
      );
      setFontIndex(index);
    }
  }, [theme.englishFont]);

  useEffect(() => {
    if (shouldSave) {
      handleSave();
    }
  }, [shouldSave]);

  useEffect(() => {
    setSelectedIconStyle(theme.iconSet);
    setSelectedFont(theme.englishFont);
    setSelectedCardShape(theme.cardShape);
    setSelectedDisplayLocale(theme.displayLocale);
  }, [theme]);

  const colorPickerSections = [
    {
      key: "cardColor",
      title: t("card_color"),
      fieldName: "cardColor",
      defaultColor: theme.cardColor || "",
    },
    {
      key: "borderColor",
      title: t("card_border_color"),
      fieldName: "borderColor",
      defaultColor: theme.borderColor || "",
    },
    {
      key: "cardText",
      title: t("card_text_color"),
      fieldName: "cardTextColor",
      defaultColor: theme.cardTextColor || "",
    },
    {
      key: "nonCardText",
      title: t("non_card_text_color"),
      fieldName: "nonCardTextColor",
      defaultColor: theme.nonCardTextColor || "",
    },
    {
      key: 'nonCardTextDesktop',
      title: t('non_card_text_color_desktop'),
      fieldName: 'nonCardTextColorDesktop',
      defaultColor: theme.nonCardTextColorDesktop || '',
    },
    {
      key: 'boxShadow',
      title: t('card_shadow'),
      fieldName: 'boxShadow',
      defaultColor: theme.boxShadow || '',
    },
  ];

  const iconColorSection = [
    {
      key: "",
      title: "To be removed",
      fieldName: "",
      defaultColor: "",
    },
    {
      key: "mobileSocialMediaIconColor",
      title: t("social_media_color"),
      fieldName: "mobileSocialMediaColor",
      showInfo: true,
      infoContent: mobileInfoCard,
      defaultColor: theme.mobileSocialMediaColor || "",
    },
    {
      key: 'desktopSocialMediaColor',
      title: t('desktop_social_media_color'),
      fieldName: 'desktopSocialMediaColor',
      showInfo: true,
      infoContent: desktopInfoCard,
      defaultColor: theme.desktopSocialMediaColor || '',
    },
  ];

  const backgroundColorSection = [
    {
      key: "customBg",
      title: t("custom"),
      color: theme.backgroundColor || "",
      iconBg:
        "linear-gradient(rgb(255, 0, 0) 0%, rgb(255, 0, 255) 17%, rgb(0, 0, 255) 33%, rgb(0, 255, 255) 50%, rgb(0, 255, 0) 67%, rgb(255, 255, 0) 83%, rgb(255, 0, 0) 100%)",
    },
    {
      key: "lightBg",
      title: t("light"),
      color: "white",
      iconBg: "white",
    },
    {
      key: "darkBg",
      title: t("dark"),
      color: "black",
      iconBg: "black",
    },
  ];

  const BackgroundColorOption = (item: any) => {
    return (
      <div
        className={styles.bgColorOption}
        style={
          selectedBgOption == item.item.key
            ? {
                border: "1px solid #F2DBEF",
                backgroundColor: '#F2DBEF'
              }
            : {}
        }
        onClick={() => {
          setSelectedBgOption(item.item.key);
          store.dispatch(setDataChange(true));
          let themePayload: {[key: string]: string} = {'backgroundColor': item.item.color};
          if (item.item.key === 'darkBg') {
            themePayload = {...themePayload, cardTextColor: 'white', cardColor: 'black', iconSet: 'white', nonCardTextColor: 'white', nonCardTextColorDesktop: 'white', mobileSocialMediaColor: 'white', desktopSocialMediaColor: 'white', boxShadow: '2px 6px 16px rgba(255, 255, 255, 0.25)'};
          }
          if (item.item.key === 'lightBg') {
            themePayload = {...themePayload, cardTextColor: 'black', cardColor: 'white', iconSet: 'black', nonCardTextColor: 'black', nonCardTextColorDesktop: 'black', mobileSocialMediaColor: 'black', desktopSocialMediaColor: 'black', boxShadow: '2px 6px 16px rgba(0, 0, 0, 0.25)'};
          }
          store.dispatch(setUserTheme({...theme, ...themePayload}));
        }}>
        <div className={styles.colorSampler} style={{background: item.item.iconBg}}/>
        {item.item.title}
      </div>
    );
  };

  const handlePreviewClick = async () => {
    await store.dispatch(setPreviewMode(true));
    history.push('/profile/preview');
  };

  const setSelectedLang = (lang: string) => {
    setSelectedDisplayLocale(lang);
    store.dispatch(setUserTheme({ ...theme, displayLocale: lang }));
    store.dispatch(setDataChange(true));
  };

  useEffect(() => {
    if (themeRequest.isFetching) {
      setIsFetching(true);
    } else if (themeRequest.isFetched) {
      setIsFetching(false);
    }
  }, [themeRequest]);

  useEffect(() => {
    dispatch(setPreviewMode(false));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.loadingOnSave}>
        {isFetching && (
          <Loader type="TailSpin" color="#FF002B" height={80} width={80} />
        )}
      </div>
      {!previewMode && (
        <RouteLeavingGuard
          when={dataChange}
          navigate={() => "/profile/design"}
          shouldReFetch={true}
          shouldBlockNavigation={() => true}
          saveFunction={handleSave}
        />
      )}
      <div className={styles.lang_follower_wrapper}>
        <div className={styles.locale_wrapper}>
          <span className={styles.section_name}>{t("display_language")}</span>
          <div className={styles.tabs}>
            <button
              className={
                selectedDisplayLocale === "en" ? styles.tabs__selected : ""
              }
              onClick={() => setSelectedLang("en")}
            >
              {t("english")}
            </button>
            <button
              className={
                selectedDisplayLocale === "ar" ? styles.tabs__selected : ""
              }
              onClick={() => setSelectedLang("ar")}
            >
              {t("arabic")}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.backgroundImage_wrapper}>
        <span className={styles.section_name}>{t("color_theme")}</span>
        {isOpen && (
          <ModalCrop
            originalImageurl={theme.mobileImage!}
            handleCancelUpload={() => {
              setImageBlob("");
              setImageName("");
            }}
            setIsOpen={setIsOpen}
            imgSrc={imageBlob}
            setImageUrl={(image) => {
              if (image.includes("base64")) {
                new Compressor(convertBase64ToBlob(image), {
                  quality: 0.9,
                  convertTypes: [
                    "image/png",
                    "image/webp",
                    "image/jpg",
                    "image/jpeg",
                  ],
                  convertSize: 1000000,
                  async success(result) {
                    const baseImage = (await blobToBase64(result)) as string;
                    setImageBlob(baseImage);
                    store.dispatch(
                      setUserTheme({
                        ...theme,
                        mobileImage: baseImage,
                        desktopImage: baseImage,
                        themeName: "custom",
                      })
                    );

                    store.dispatch(setDataChange(true));
                  },
                  error(err) {
                    console.log(err.message);
                  },
                });
              } else {
                setImageBlob(image);
                store.dispatch(
                  setUserTheme({
                    ...theme,
                    mobileImage: image,
                    desktopImage: image,
                    themeName: "custom",
                  })
                );
              }
            }}
            type={"theme"}
          />
        )}

        <div className={styles.input_buttons_wrapper}>
          <div className={styles.input_bg_wrapper}>
            {backgroundColorSection.map((item: any, index: number) => {
              return (
                <div key={`bg-${index}`} className={styles.input_bg_container}>
                  <BackgroundColorOption key={item.key} item={item} />
                </div>
              );
            })}
          </div>
          {selectedBgOption == "customBg" && (
            <div>
              <ColorSectionItem
                title={t("bg_color")}
                fieldName={"backgroundColor"}
                theme={theme}
                defaultColor={theme.backgroundColor || ""}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.color_section_wrapper}>
        <span className={`${styles.section_name} ${styles.color_title}`}>
          {t("color")}
        </span>
        <div className={styles.color_subtitle}>{t("set_your_own_style")}</div>
        <div className={`${styles.color_sections} ${styles.padding_28_top}`}>
          {colorPickerSections.map((item: any, index: number) => {
            return item.key ? (
              <div key={item.key}>
                <ColorSectionItem
                  theme={theme}
                  fieldName={item.fieldName}
                  showInfo={item.showInfo}
                  infoContent={item.infoContent}
                  title={item.title}
                  defaultColor={item.defaultColor}
                  index={index}
                />
              </div>
            ) : (
              <IconStylePicker
                theme={theme}
                selectedIconStyle={selectedIconStyle!}
                setSelectedIconStyle={setSelectedIconStyle}
              />
            );
          })}
        </div>
        <div className={styles.icon_container}>
          <div
            className={`${styles.color_sections} ${styles.color_sections_full}`}
          >
            <IconStylePicker
              theme={theme}
              selectedIconStyle={selectedIconStyle!}
              setSelectedIconStyle={setSelectedIconStyle}
            />
          </div>
          <div
            className={`${styles.color_sections} ${styles.color_sections_full}`}
          >
            {iconColorSection.map((item: any, index: number) => {
              return item.key ? (
                <div key={item.key}>
                  <ColorSectionItem
                    theme={theme}
                    fieldName={item.fieldName}
                    showInfo={item.showInfo}
                    infoContent={item.infoContent}
                    title={item.title}
                    defaultColor={item.defaultColor}
                  />
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>

      <div className={styles.card_shape_wrapper}>
        <span className={styles.section_name}>{t("card_shape")}</span>
        <div className={styles.shape_sections}>
          {cardShapeDetails.map((item: any, index: number) => {
            return (
              <div
                className={`${styles.shape_sections_container} ${
                  selectedCardShape === item.name ? styles.active : ""
                }`}
                key={index}
              >
                <div
                  className={
                    selectedCardShape === item.name
                      ? styles.shape_item_selected
                      : styles.shape_item
                  }
                  onClick={() => {
                    setSelectedCardShape(item.name);
                    store.dispatch(
                      setUserTheme({ ...theme, cardShape: item.name })
                    );
                    store.dispatch(setDataChange(true));
                  }}
                >
                  <div
                    className={
                      selectedCardShape === item.name
                        ? styles.shape_selected
                        : styles.shape
                    }
                    style={{
                      borderRadius: item.border.borderRadius,
                    }}
                  ></div>
                </div>
                <span
                  className={
                    selectedCardShape === item.name
                      ? styles.shape_title_selected
                      : styles.shape_title
                  }
                >
                  {t(item.name)}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.font_wrapper}>
        <span className={styles.section_name}>
          {t("font")}
          {width > 768 && (
            <div>
              {fontIndex > 0 && (
                <img
                  className={styles.arrowLeft}
                  src={arrowLeft}
                  onClick={() => handleFontLeft()}
                />
              )}
              {fontIndex < fontArray.length - 1 && (
                <img
                  className={styles.arrowRight}
                  src={arrowRight}
                  onClick={() => handleFontRight()}
                />
              )}
            </div>
          )}
        </span>
        <div
          className={styles.backgrounds}
          id="fonts"
          style={{ marginTop: 0, gap: "8px" }}
        >
          {Object.values(englishFonts).map((value, index) => {
            return (
              <>
                <div
                  key={index}
                  id={`font-style-${index}`}
                  className={`${styles.font_item} ${styles.background_item} ${
                    fontIndex === index ? styles.selected_font : ""
                  }`}
                  onClick={() => {
                    setFontIndex(index);
                    setSelectedFont(value);
                    store.dispatch(
                      setUserTheme({ ...theme, englishFont: value })
                    );
                    store.dispatch(setDataChange(true));
                  }}
                >
                  <span style={{ fontFamily: value }} className={styles.text}>
                    Aa
                  </span>
                  <span style={{ fontFamily: value }} className={styles.value}>
                    {t(value)}
                  </span>
                </div>
                <div
                  id={`${
                    fontIndex != -1 && fontIndex == index ? "selectedFont" : ""
                  }`}
                ></div>
              </>
            );
          })}
        </div>
        {width < 768 && (
          <div className={styles.action_button}>
            <PrimaryButton
              label={dataChange ? t("save") : t("saved")}
              onClick={handleSave}
              disabled={!dataChange}
              color="red"
              style={{ width: "100%" }}
            />
            <PrimaryButton
              label={t("preview")}
              onClick={handlePreviewClick}
              color="black"
              style={{ width: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomTheme;
