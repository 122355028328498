/* eslint-disable camelcase */
import mixpanel from 'mixpanel-browser';
import {EventTracking} from './event-tracking';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {isProdSite} from '../../Helper/generalHelper';

const initialize = () => {
  mixpanel.init(envConfig().mixPanelKey, {
    debug: isProdSite() ? false : true,
    track_pageview: true,
    persistence: 'localStorage',
  });
};

const track = (eventName: string, data?: Record<string, any>) => {
  mixpanel.track(eventName, {...data});
};

const identify = (id: string, data?: Record<string, any>) => {
  mixpanel.identify(id);
  if (data) {
    const formattedData = formattingData(data);
    mixpanel.people.set(formattedData);
  }
};

const updateProfile = (data: Record<string, any>) => {
  const formattedData = formattingData(data);
  mixpanel.people.set(formattedData);
};

const reset = () => {
  mixpanel.reset();
};

const renamedKeys: Record<string, string> = {
  'Email': '$email',
  'Name': '$name',
  'Photo': '$avatar',
  'Phone': '$phone',
} as const;

const rename = (data: Record<string, any>) => Object.keys(data).reduce((acc, key) => {
  if (renamedKeys[key]) {
    acc[renamedKeys[key]] = data[key];
  } else {
    acc[key] = data[key];
  }
  return acc;
}, {} as Record<string, any>);


const addFirstAndLastNames = (data: Record<string, any>) => {
  const name = data.$name;
  if (!name) return data;
  const split = name.split(' ');
  const $first_name = split[0];
  const $last_name = split.length > 1 ? split[split.length - 1] : null;
  return {...data, $first_name, $last_name};
};

const formattingData = (data: Record<string, any>) => {
  const renamedData = rename(data);
  const withFirstAndLastNames = addFirstAndLastNames(renamedData);
  return withFirstAndLastNames;
};


const mixpanelEventTracking: EventTracking = {
  initialize,
  track,
  identify,
  updateProfile,
  reset,
};

export default mixpanelEventTracking;
