import React, { useState, useEffect, useMemo } from 'react';
import styles from './AvailabilityModal.module.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getSchedule } from '../../features/stores/scheduleSlicer';
import { weekDay } from '../../constants/helper';
import SelectWithSearch from '../SelectWithSearch/SelectWithSearch';
import { timeZones } from '../../constants/timezone';
import CustomDropdown from './CustomDropdown';
import DaySection from './DaySection';
import { Slot } from '../LinkModal/modals/types';

const initialValue = {
  Mon: [],
  Tue: [],
  Wed: [],
  Thu: [],
  Fri: [],
  Sat: [],
  Sun: [],
};

const AvailabilityModal = ({
  handleChange,
}: {
  handleChange?: (data: any) => void;
  settingView?: boolean;
}) => {
  const { t } = useTranslation();
  const schedule = useSelector(getSchedule).data || {};
  const [timezone, setTimezone] = useState<any>('');
  const [activeDay, setActiveDay] = useState<any>([]);
  const [scheduleData, setScheduleData] = useState<any>(initialValue);

  const getCurrentTimeInterval = () => {
    const start = moment().format('hh:00 A');
    const end = moment(start, 'hh:00 A').add(15, 'm').format('hh:mm A');
    return { startTime: start, endTime: end };
  };

  const handleSelectChange = (
      day: string,
      event: any,
      index: number,
      type: string,
  ) => {
    if (event?.target?.value) {
      const scheduleCopy = { ...scheduleData };
      scheduleCopy[day][index][type] = event?.target?.value;
      setScheduleData(scheduleCopy);
      if (handleChange) {
        handleChange({ change: true, slots: scheduleCopy, activeDay });
      }
    }
  };

  const addDeleteMoreSlots = (day: string, index: number) => {
    const scheduleCopy = { ...scheduleData };
    if (index === -1) {
      // Add a new slot at the end
      scheduleCopy[day] = [...scheduleCopy[day], getCurrentTimeInterval()];
    } else {
      // Remove the slot at the specified index
      scheduleCopy[day] = scheduleCopy[day].filter(
          (_: any, i: number) => i !== index,
      );
    }
    setScheduleData(scheduleCopy);
    if (handleChange) {
      handleChange({ change: true, slots: scheduleCopy, activeDay });
    }
  };

  const handleTimezone = (value: any) => {
    const timezoneName = value.name || value;
    setTimezone(timezoneName);

    if (handleChange) {
      handleChange({
        change: true,
        slots: scheduleData,
        activeDay: activeDay,
        timezone: timezoneName, // Only passing the timezone name
      });
    }
  };

  useEffect(() => {
    if (schedule?.id) {
      const scheduleCopy = JSON.parse(JSON.stringify(scheduleData));
      const dayCopy: string[] = [];
      schedule?.slots?.map((el: any) => {
        scheduleCopy[el?.dayOfWeek].push({
          startTime: el?.startTime,
          endTime: el?.endTime,
        });
        if (!dayCopy.includes(el?.dayOfWeek)) {
          dayCopy.push(el?.dayOfWeek);
        }
      });

      setScheduleData(scheduleCopy);
      setActiveDay(dayCopy);
      setTimezone(schedule?.timezone);
      if (handleChange) {
        handleChange({
          change: false,
          slots: scheduleCopy,
          activeDay: dayCopy,
          timezone: schedule?.timezone,
        });
      }
    }
  }, [schedule]);

  const clearDay = (day: string) => {
    const updatedSchedule = { ...scheduleData, [day]: [] };
    const updatedActiveDays = activeDay.filter((d: string) => d !== day);

    setScheduleData(updatedSchedule);
    setActiveDay(updatedActiveDays);

    if (handleChange) {
      handleChange({
        change: true,
        slots: updatedSchedule,
        activeDay: updatedActiveDays,
      });
    }
  };

  const handleDaySelect = (day: string) => {
    if (!activeDay.includes(day)) {
      const scheduleCopy = { ...scheduleData };
      scheduleCopy[day] = [getCurrentTimeInterval()]; // Add a default slot for the selected day

      setScheduleData(scheduleCopy);
      setActiveDay([...activeDay, day]);

      if (handleChange) {
        handleChange({
          change: true,
          slots: scheduleCopy,
          activeDay: [...activeDay, day],
        });
      }
    }
  };

  const validateSlots = (day: string, index: number): string | null => {
    const currentSlot = scheduleData[day][index];

    const isOverlapping = scheduleData[day]
        .filter((slot: Slot, i: number) => i !== index)
        .some(
            (slot:any) =>
              moment(slot.startTime, 'h:m A').isBetween(
                  moment(currentSlot.startTime, 'h:m A'),
                  moment(currentSlot.endTime, 'h:m A'),
              )
          || moment(slot.endTime, 'h:m A').isBetween(
              moment(currentSlot.startTime, 'h:m A'),
              moment(currentSlot.endTime, 'h:m A'),
          )
          || (moment(slot.startTime, 'h:m A').isSame(
              moment(currentSlot.startTime, 'h:m A'),
          )
            && moment(slot.endTime, 'h:m A').isSame(
                moment(currentSlot.endTime, 'h:m A'),
            )),
        );

    if (isOverlapping) return t('time_overlapped');

    const isEndBeforeStart = moment(currentSlot.endTime, 'h:m A').isBefore(
        moment(currentSlot.startTime, 'h:m A'),
    );
    if (isEndBeforeStart) return t('end_time_is_lesser');

    return null;
  };

  return (
    <div className={styles.availibility_main}>
      <CustomDropdown
        onDaySelect={handleDaySelect}
        options={weekDay}
        placeholder="Select day"
      />
      <div className={styles.schedule_container}>
        {activeDay.map((day: string) => (
          <DaySection
            key={day}
            day={day}
            slots={scheduleData[day]}
            activeDay={activeDay}
            onAddSlot={() => addDeleteMoreSlots(day, -1)}
            onRemoveSlot={(index) => addDeleteMoreSlots(day, index)}
            handleSelectChange={(event, index, field) =>
              handleSelectChange(day, event, index, field)
            }
            validateSlots={(index) => validateSlots(day, index)}
            clearDay={clearDay}
          />
        ))}
      </div>

      <div className={styles.timezone_container}>
        <SelectWithSearch
          value={timezone}
          onChange={(e) => handleTimezone(e.target.value)}
          label={t('timezone')}
          options={timeZones.map((zone) => ({
            display: `${zone.name} (GMT${zone.offset})`,
            value: zone.name,
          }))}
        />
      </div>
    </div>
  );
};

export default AvailabilityModal;
