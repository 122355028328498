import React from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import copy from 'copy-to-clipboard';
import styles from '../TrackingLinksTable.module.scss';
import {raiseToast} from "../../Toast/Toast";
import {selectLinksUser} from '../../../features/stores/linksUserSlicer';
import {ReactComponent as CopyIcon} from '../../../assets/images/shop/copy.svg';
import {ReactComponent as DeleteIcon} from '../../../assets/images/shop/delete.svg';
import {ReactComponent as DragIcon} from '../../../assets/images/shop/drag.svg';
import {ReactComponent as EditIcon} from '../../../assets/images/shop/edit.svg';
import {ReactComponent as VisibleTrueIcon} from '../../../assets/images/shop/visible_true.svg';
import {ReactComponent as VisibleFalseIcon} from '../../../assets/images/shop/visible_false.svg';
import {ReactComponent as AddIcon} from '../../../assets/images/shop/add.svg';
import {envConfig} from '../../../features/EnvironmentVariables/enviromentVariables';
import {truncateUrl} from "../../../Helper/generalHelper";
import {ProductEditingInfoProps, CollectionEditingInfoProps} from '../../../pages/TrackingLinks/TrackingLinksPage';

interface ShopCardProps {
  trackingLink: any;
  setType: React.Dispatch<React.SetStateAction<"product" | "collection" | null | undefined>>;
  setTrackingLinkId: React.Dispatch<React.SetStateAction<string | null>>;
  setIsCurrentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCurrentCollection: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenVisibilityConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTrackingLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCollectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProductEditingInfo: React.Dispatch<React.SetStateAction<ProductEditingInfoProps>>;
  setCollectionEditingInfo: React.Dispatch<React.SetStateAction<CollectionEditingInfoProps>>;
};

const url = envConfig().copyLinkUrl;

export const ShopCard = ({
  trackingLink,
  setType,
  setTrackingLinkId,
  setIsCurrentVisible,
  setIsCurrentCollection,
  setOpenVisibilityConfirmationModal,
  setOpenDeleteConfirmationModal,
  setOpenTrackingLinkModal,
  setOpenCollectionModal,
  setProductEditingInfo,
  setCollectionEditingInfo,
}: ShopCardProps) => {
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);

  const handleCopyingProductLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    raiseToast({message: t('copied_to_clipboard')});
  };

  const handleCopyingCollectionLink = async (urlSlug: string) => {
    copy(`${url}/${linksUser?.nickname?.toLowerCase()}?collection=${urlSlug}`);
    raiseToast({message: t('copied_to_clipboard')});
  };

  const handleEditingProduct = (product: any) => {
    setProductEditingInfo({
      id: product?.id,
      productUrl: product?.productUrl,
      title: product?.title,
      logoUrl: product?.logoUrl,
      isVisible: product?.visible,
    });
    setOpenTrackingLinkModal(true);
  };

  const handleEditingCollection = (collection: any) => {
    setCollectionEditingInfo({
      id: collection?.id,
      name: collection?.name,
      productIds: collection?.products?.length ? collection?.products.map((elem: any) => elem.product.id) : [],
    });
    setOpenCollectionModal(true);
  };

  const isCollection = !!trackingLink?.collection;
  const heading = isCollection ? trackingLink?.collection?.name : trackingLink?.product?.title;
  const subHeading = isCollection ? 
    `${trackingLink?.collection?.products?.length} item${trackingLink?.products?.length !== 1 ? 's' : ''}` : 
    `${trackingLink?.product?.userDeal?.deal?.name}`;

  return (
    <>
      <div style={{display: 'flex'}}>
        <div>
        {/* <div {...provided.dragHandleProps}> */}
          <DragIcon style={{cursor: 'grab'}} />
        </div>
        <span style={{margin: '0 auto'}}></span>
        <CopyIcon 
          onClick={() => isCollection ? 
            trackingLink?.collection?.products?.length ? handleCopyingCollectionLink(trackingLink?.collection?.urlSlug) : null : 
            handleCopyingProductLink(trackingLink?.product?.trackingLink)
          } 
          style={{cursor: (!isCollection || trackingLink?.collection?.products?.length) ? 'pointer' : 'default'}}
        />
      </div>
      <div className={styles.productInfoContainer}>
        {isCollection ? 
          <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {trackingLink?.collection?.products?.length ?
              trackingLink?.collection?.products?.length === 1 ? 
                <img src={trackingLink?.collection?.products[0]?.product?.logoUrl} className={styles.trackingLogo} alt=""/>:
              <>
                {trackingLink?.collection?.products?.slice(0, 4)?.map((product: any, i: number) => {
                  return (
                    <img 
                      src={product?.product?.logoUrl} 
                      className={
                        trackingLink?.collection?.products?.length === 2 ? styles.collectionLogoTwo :
                        (trackingLink?.collection?.products?.length === 3 && i===2) ? styles.collectionLogoThree :
                        styles.collectionLogo
                      }
                      alt=""
                      key={i}
                    />
                  );
                })}
              </> :
              <div 
                className={`${styles.trackingLogo} ${styles.AddIcon}`}
                onClick={() => handleEditingCollection(trackingLink?.collection)}
              >
                <AddIcon />
                <p>{t('add_product_to_collection')}</p>
              </div>
            }
          </div> :
          <img src={trackingLink?.product?.logoUrl} className={styles.trackingLogo} alt=""/>
        }
        <span className={styles.cardHeading}>{truncateUrl(heading, 15)}</span>
        <span className={styles.cardSubheading}>{truncateUrl(subHeading, 20)}</span>
      </div>
      <div style={{display: 'flex'}}>
        {(isCollection && trackingLink?.collection?.visible) || 
        (!isCollection && trackingLink?.product?.visible) ? (
          <VisibleTrueIcon 
            onClick={() => {
              setType(isCollection ? "collection" : "product");
              setTrackingLinkId(isCollection ? trackingLink?.collection?.id : trackingLink?.product?.id);
              setIsCurrentVisible(isCollection ? trackingLink?.collection?.visible : trackingLink?.product?.visible);
              setIsCurrentCollection(isCollection);
              setOpenVisibilityConfirmationModal(true);
            }}
            style={{cursor: 'pointer'}}
          />
        ) : (
          <VisibleFalseIcon 
            onClick={() => {
              if (!isCollection || trackingLink?.collection?.products?.length) {
                setType(isCollection ? "collection" : "product");
                setTrackingLinkId(isCollection ? trackingLink?.collection?.id : trackingLink?.product?.id);
                setIsCurrentVisible(isCollection ? trackingLink?.collection?.visible : trackingLink?.product?.visible);
                setIsCurrentCollection(isCollection);
                setOpenVisibilityConfirmationModal(true);
              }
            }}
            style={{cursor: (!isCollection || trackingLink?.collection?.products?.length) ? 'pointer' : 'default'}}
          />
        )}
        <span style={{margin: '0 auto'}}></span>
        <EditIcon 
          onClick={() => {
            isCollection ?
              handleEditingCollection(trackingLink?.collection) : 
              handleEditingProduct(trackingLink?.product);
          }} 
          style={{cursor: 'pointer'}} 
        />
        <span style={{margin: '0 5px'}}></span>
        <DeleteIcon 
          onClick={(e: any) => {
            e.stopPropagation();
            setType(isCollection ? "collection" : "product");
            setTrackingLinkId(isCollection ? trackingLink?.collection?.id : trackingLink?.product?.id);
            setIsCurrentCollection(isCollection);
            setOpenDeleteConfirmationModal(true);
          }}
          style={{cursor: 'pointer'}}
        />
      </div>
    </>
  );
};
