import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {PlusCircleFilled} from '@ant-design/icons';
import styles from '../ImportantLinks/ImportantLinks.module.scss';
import {store} from '../../app/store';
import {selectChangeData, setDataChange, setShouldSave} from '../../features/stores/changeDataSlicer';
import {useDispatch, useSelector} from 'react-redux';
import {
  deleteLinksSectionItem,
  LinksSectionItem,
  selectLinksUser,
  sortLinksSectionItems,
  updateSectionItem,
  updateSectionItems,
} from '../../features/stores/linksUserSlicer';
import {reorder, haveSegmentPermission} from '../../features/util';
import {useTranslation} from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import ExploreModal from '../ExploreModal/ExploreModal';
import noLink from '../../assets/images/versionV2/Profile/noLinks.svg';
import {ReactComponent as UserPreview} from '../../assets/images/Users.svg';
import {getConfig} from './../../features/stores/configSlicer';
import {themeColor} from '../../constants/newThemes';
import DraggableLinksSection from './DraggableLinksSection';
import {Divider} from 'antd';
import {Badge} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import LinkModalFactory from '../LinkModal/LinkModalFactory';
import AddLinkModal from '../AddLinkModal/AddLinkModal';
import {MonetizeType, MonetizeTypes} from '../LinkModal/modals/types';
import {
  selectPreviewMode,
  setPreviewMode,
} from '../../features/stores/previewModeSlicer';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { desktopVideoSrc, mobileVideoSrc } from '../../constants/helper';

export const CustomBadge = withStyles(() => ({
  badge: {
    transform: 'translate(11px, -8px)',
  },
}))(Badge);

export const ButtonCustom = withStyles({
  root: {
    background: (props: any) => props.background,
    color: (props: any) => props.colortext,
    border: (props: any) =>
      props.border ? `1px solid ${props.border}` : 'none',
    boxShadow: 'none',
    borderRadius: '25px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '8px 20px',
    textTransform: 'none',
  },
  contained: {
    '&:hover': {
      background: (props: any) => props.background,
    },
  },
})(Button);

const ImportantLinks = () => {
  const {t} = useTranslation();
  const mobile = useWindowWidth();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openExploreModal, setOpenExploreModal] = useState(false);
  const [openMonetizeModal, setOpenMonetizeModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState<LinksSectionItem>();
  const config = useSelector(getConfig).data || [];
  const dataChange = useSelector(selectChangeData);
  const handleSaveGlobal = () => {
    if (dataChange) {
      store.dispatch(setShouldSave(true));
    }
  };
  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const importantLinksSection = linksUserSections.find(
      (section) => section.type === 'important_links',
  );
  const importantLinksItems = importantLinksSection?.linksSectionItems ?? [];
  const seasonalLinksSection = linksUserSections.find(
      (section) => section.type === 'seasonal',
  );
  const seasonalLinksItems = seasonalLinksSection?.linksSectionItems ?? [];

  const linkLimit = haveSegmentPermission(config, 'maxLinkLimit') || 10;
  const seasonalLinksPermission = haveSegmentPermission(
      config,
      'seasonalLinks',
  );
  // each user can have only 1 seasonal link of each type
  const AvailableSeasonalLinks = Array.isArray(seasonalLinksPermission) ?
    seasonalLinksPermission.filter(
        (e) => !seasonalLinksItems.find((link) => link.type === e.type),
    ) :
    [];
  const showExploreButton =
    AvailableSeasonalLinks.length > 0 ||
    importantLinksItems?.length < linkLimit;

  const existSeasonalLinks =
    seasonalLinksItems && seasonalLinksItems.length > 0;
  const existImportantLinks =
    importantLinksItems && importantLinksItems.length > 0;

  const monetizationEnabled =
    haveSegmentPermission(config, 'monetize') &&
    !haveSegmentPermission(config, 'monetizeBlocklist');

  const [openLinkModal, setOpenLinkModal] = useState<{
    open: boolean;
    editable: boolean;
    mode: 'add'|'edit';
    back?: 'exploreModal' | 'addLinkModal';
  }>({
    open: false,
    editable: true,
    mode: 'add',
  });

  const onDragEnd = (result: any, links: any[]) => {
    if (!result.destination) {
      return;
    }
    const itemsTemp = reorder(
        links,
        result.source.index,
        result.destination.index,
    ) as LinksSectionItem[];

    itemsTemp.forEach((element: LinksSectionItem, index: number) => {
      store.dispatch(
          updateSectionItem({
            id: element?.id,
            data: {...element, orderNumber: index},
          }),
      );
    });
    store.dispatch(sortLinksSectionItems());
    store.dispatch(setDataChange(true));
  };

  const handleDelete = (id: string) => {
    store.dispatch(deleteLinksSectionItem({sectionItemId: id}));
    store.dispatch(setDataChange(true));
  };

  const handleAddNewLink = (payload?: any) => {
    const obj: LinksSectionItem = payload ?
      payload :
      {
        id: Date.now().toString(),
        type: 'other',
        url: '',
        customUploadedImage: '',
        title: 'Title',
        linksSectionId: importantLinksSection?.id as string,
        orderNumber: 0,
        hidden: true,
        isVisible: true,
        layout: 'new',
      };
    const sectionItems = linksUserSections.find(
        (section) => section.id === obj.linksSectionId,
    )?.linksSectionItems;
    let data = JSON.parse(JSON.stringify(sectionItems)) || [];
    data.map((e: any, index: number) => {
      e.orderNumber = index + 1;
    });
    data = [obj, ...data];
    store.dispatch(updateSectionItems({obj, data}));

    data.forEach((element: LinksSectionItem, index: number) => {
      store.dispatch(
          updateSectionItem({
            id: element?.id,
            data: {...element, orderNumber: index},
          }),
      );
    });
    store.dispatch(sortLinksSectionItems());
    if (payload) {
      store.dispatch(setDataChange(true));
    }
  };

  const handleSave = (link: LinksSectionItem) => {
    if (openLinkModal.mode === 'add') {
      handleAddNewLink(link);
    } else {
      store.dispatch(updateSectionItem({id: link.id, data: link}));
      store.dispatch(setDataChange(true));
    }
    setOpenLinkModal((state) => ({...state, open: false}));
  };

  const handleOpenLinkModal = (link: LinksSectionItem, editable: boolean = true, mode: 'add'| 'edit' = 'add', back?: 'exploreModal' | 'addLinkModal') => {
    if (!monetizationEnabled && MonetizeTypes.includes(link.type as MonetizeType)) {
      editable = false;
    }
    setSelectedLink({...link});
    setOpenLinkModal({open: true, editable, mode, back});
  };

  const handleBackButton = () => {
    const backModal = openLinkModal.back;
    if (backModal === 'exploreModal') {
      setOpenExploreModal(true);
    } else if (backModal === 'addLinkModal') {
      setOpenMonetizeModal(true);
    }
    setOpenLinkModal((state)=>({...state, open: false, back: undefined}));
  };

  const handlePreviewClick = async () => {
    await store.dispatch(setPreviewMode(true));
    history.push('/profile/preview');
  };

  return (
    <div
      className={`${styles.container} ${
        showExploreButton ? styles.container_margin : ""
      }`}
    >
      <AddLinkModal
        open={openMonetizeModal}
        onClose={() => {
          setOpenMonetizeModal(false);
        }}
        onAddLink={(...params) =>
          handleOpenLinkModal(...params, "add", "addLinkModal")
        }
      />
      <LinkModalFactory
        editable={openLinkModal.editable}
        defaultLink={selectedLink!}
        open={openLinkModal.open}
        mode={openLinkModal.mode}
        withBackButton={!!openLinkModal.back}
        onBack={handleBackButton}
        onClose={() => {
          setOpenLinkModal((state) => ({ ...state, open: false }));
        }}
        onSave={handleSave}
        onDelete={(id) => {
          handleDelete(id);
          setOpenLinkModal((state) => ({ ...state, open: false }));
        }}
      />

      <div>
        {existImportantLinks && existSeasonalLinks && (
          <h5>{t("links_of_the_season")}</h5>
        )}

        {existSeasonalLinks ? (
          <DraggableLinksSection
            list={seasonalLinksItems}
            onDragEnd={onDragEnd}
            onEditLink={(link) => handleOpenLinkModal(link, false, "edit")}
          />
        ) : null}

        {existImportantLinks && existSeasonalLinks && (
          <>
            <Divider />
            <h5>{t("links")}</h5>
          </>
        )}

        {existImportantLinks ? (
          <DraggableLinksSection
            list={importantLinksItems}
            onDragEnd={onDragEnd}
            onEditLink={(link) => handleOpenLinkModal(link, true, "edit")}
          />
        ) : null}

        {!existImportantLinks && !existSeasonalLinks && (
          <div className={styles.no_links}>
            <video
              className={styles.no_links_video}
              src={mobile <= 768 ? mobileVideoSrc : desktopVideoSrc}
              autoPlay
              muted
              loop
              playsInline
            />
          </div>
        )}

        {(
          <div className={styles.btn_container}>
            {importantLinksItems?.length < linkLimit && (
              <PrimaryButton
                label={t("add_link")}
                onClick={() => setOpenMonetizeModal(true)}
                color="red"
                style={{width: mobile > 1024 ? "40%" : "100%", padding: '10px 0'}}
              />
            )}
            {mobile < 1026 && (
              <PrimaryButton
                avatar={<UserPreview className={styles.user_avatar}/>}
                label={t("preview")}
                onClick={handlePreviewClick}
                color="black"
                style={{ width: "100%", padding: '10px 0' }}
              />
            )}
          </div>
        )}

        {(mobile <= 1026) && (
          <div className={styles.btn_containers}>
            <PrimaryButton
              label={dataChange ? t("save") : t("saved")}
              onClick={handleSaveGlobal}
              disabled={!dataChange}
              color="red"
              style={{ width: "100%", padding: '10px 0' }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ImportantLinks;
