import React, {useState, useEffect, useRef} from "react";
import styles from './TrackingLinksTable.module.scss';
import nonHookRequest from "../../features/API/nonHookRequest";
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import ConfirmationModal from "../LinkModal/modals/ConfirmationModal";
import {TrackingLink} from "../../pages/TrackingLinks/TrackingLinksPage";
import {raiseToast} from "../Toast/Toast";
import {DragDropContext, Droppable, Draggable, DropResult} from 'react-beautiful-dnd';
import {EmptyState} from "./components/EmptyState";
import {ProductEditingInfoProps, CollectionEditingInfoProps} from '../../pages/TrackingLinks/TrackingLinksPage';
import {ShopCard} from "./components/ShopCard";
import useWindowWidth from "../../hooks/useWindowWidth";
import InfiniteScroll from 'react-infinite-scroll-component';

interface TrackingLinksTableProps {
  trackingLinks: any[];
  trackingLinksTotalCount: number;
  setOffsetAndFetchLinks: () => void;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
  perPage: number;
  isSearching: boolean;
  setOpenTrackingLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCollectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProductEditingInfo: React.Dispatch<React.SetStateAction<ProductEditingInfoProps>>;
  setCollectionEditingInfo: React.Dispatch<React.SetStateAction<CollectionEditingInfoProps>>;
  getProducts: () => Promise<void>;
  getCollections: () => Promise<void>
};

const TrackingLinksTable = ({
  trackingLinks, 
  trackingLinksTotalCount, 
  setOffsetAndFetchLinks, 
  offset,
  setOffset,
  perPage,
  isSearching,
  setOpenTrackingLinkModal,
  setOpenCollectionModal,
  setProductEditingInfo,
  setCollectionEditingInfo,
  getProducts,
  getCollections,
}: TrackingLinksTableProps) => {
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openVisibilityConfirmationModal, setOpenVisibilityConfirmationModal] = useState(false);
  const [trackingLinkId, setTrackingLinkId] = useState<string | null>(null);
  const [isCurrentVisible, setIsCurrentVisible] = useState(false);
  const [isCurrentCollection, setIsCurrentCollection] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [type, setType] = useState<"product" | "collection" | null>();
  const [isLoading, setIsLoading] = useState(false);
  const isSingleCard = useRef(false);
  
  const {t} = useTranslation();
  const width = useWindowWidth();
  isSingleCard.current = (width > 1024 && width < 1200) || width < 625;

  useEffect(() => {
    if (trackingLinks.length) {
      setRows(isSingleCard.current ? trackingLinks : transformTrackingLinks(trackingLinks));
    }
  }, [trackingLinks, isSingleCard.current]);

  const transformTrackingLinks = (trackingLinks: any[]) => {
    const transformedArray = [];
    let rowNumber = 0;
    for (let i = 0; i < trackingLinks.length; i += 2) {
      // Slice the array to create subarrays of 2 elements each
      transformedArray.push({id: `row-${++rowNumber}`, items: trackingLinks.slice(i, i + 2)});
    }
    return transformedArray;
  };

  const deleteTrackingLink = async () => {
    setIsLoading(true);
    const response = await nonHookRequest({
      method: 'DELETE',
      url: type === 'product' ? 
        `/brandshub/api/product/${trackingLinkId}` :
        `/brandshub/api/collection/${trackingLinkId}`,
      isShortUrl: true,
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: type === 'product' ? t('product_deleted') : t('collection_deleted')});
      setOffsetAndFetchLinks();
      type === 'product' ? getProducts() : getCollections();
      setOpenDeleteConfirmationModal(false);
    };
    setIsLoading(false);
  };

  const updateVisibility = async () => {
    setIsLoading(true);
    const response = await nonHookRequest({
      method: 'PUT',
      url: type === 'product' ? 
        `/brandshub/api/product/${trackingLinkId}` :
        `/brandshub/api/collection/${trackingLinkId}`,
      isShortUrl: true,
      body: {visible: !isCurrentVisible},
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      setOffsetAndFetchLinks();
      setOpenVisibilityConfirmationModal(false);
    };
    setIsLoading(false);
  };

  const onDragEnd = async (result: DropResult) => {
    const {destination, source, type} = result;

    if (!destination) return; // If dropped outside the droppable area

    let newIndex;
    if (isSingleCard.current) {
      newIndex = destination.index;
    } else {
      const rowIndex = parseInt(destination.droppableId.split("-")[2]) - 1;
      newIndex = 2 * rowIndex + destination.index - 1;
      if (newIndex < 0) newIndex = 0;
    }

    const newOrder = trackingLinks[newIndex].displayOrder;

    const response = await nonHookRequest({
      method: 'PUT',
      url: `/brandshub/api/products-collections/displayOrder`,
      isShortUrl: true,
      body: {entityId: result?.draggableId, newOrder},
      filterError: true,
    });
    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: t('order_updated_successfully')});
      setOffsetAndFetchLinks();
    };
  };

  return !trackingLinks?.length ? (
    isSearching ? 
    <div className={styles.emptyContainer}>
      <h3 className={styles.emptyHeading}>{t('no_search_result')}</h3>
      <p className={styles.emptysubHeading}>{t('click_to_add')}</p>
    </div> :
    <EmptyState setOpenTrackingLinkModal={setOpenTrackingLinkModal} />
  ): 
  (
    <div className={styles.container}>
      <InfiniteScroll
        dataLength={trackingLinks.length} 
        next={() => setOffset((prev) => prev + perPage)}
        hasMore={trackingLinks.length < trackingLinksTotalCount}
        loader={<h4>{t('loading')}</h4>}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable 
            droppableId="droppable-rows" 
            direction={isSingleCard.current ? "vertical" : undefined} 
            type={isSingleCard.current ? undefined : "rows"}
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={styles.rowsContainer}
              >
                {rows.map((row, rowIndex) => {
                  return (
                    <Draggable key={row.id} draggableId={row.id} index={rowIndex}>
                      {(provided) => {
                        return (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {isSingleCard.current ? 
                            <div className={styles.productContainer}>
                              <ShopCard 
                                trackingLink={row} 
                                setType={setType} 
                                setTrackingLinkId={setTrackingLinkId} 
                                setIsCurrentVisible={setIsCurrentVisible} 
                                setIsCurrentCollection={setIsCurrentCollection} 
                                setOpenVisibilityConfirmationModal={setOpenVisibilityConfirmationModal} 
                                setOpenDeleteConfirmationModal={setOpenDeleteConfirmationModal} 
                                setOpenTrackingLinkModal={setOpenTrackingLinkModal} 
                                setOpenCollectionModal={setOpenCollectionModal} 
                                setProductEditingInfo={setProductEditingInfo} 
                                setCollectionEditingInfo={setCollectionEditingInfo}
                              />
                            </div> : 
                            <Droppable droppableId={`droppable-row-${rowIndex + 1}`} direction="horizontal">
                              {(provided, snapshot) => (
                                <div
                                  className={styles.productsContainer}
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {row?.items?.length && row.items.map((trackingLink: any, itemIndex: number) => (
                                  <Draggable
                                    key={trackingLink.id}
                                    draggableId={trackingLink.id}
                                    index={itemIndex}
                                  >
                                    {(provided) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={styles.productContainer}
                                        >
                                          <ShopCard 
                                            trackingLink={trackingLink} 
                                            setType={setType} 
                                            setTrackingLinkId={setTrackingLinkId} 
                                            setIsCurrentVisible={setIsCurrentVisible} 
                                            setIsCurrentCollection={setIsCurrentCollection} 
                                            setOpenVisibilityConfirmationModal={setOpenVisibilityConfirmationModal} 
                                            setOpenDeleteConfirmationModal={setOpenDeleteConfirmationModal} 
                                            setOpenTrackingLinkModal={setOpenTrackingLinkModal} 
                                            setOpenCollectionModal={setOpenCollectionModal} 
                                            setProductEditingInfo={setProductEditingInfo} 
                                            setCollectionEditingInfo={setCollectionEditingInfo}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                  ))}
                                  {provided.placeholder} {/* Ensure the placeholder is rendered */}
                                </div>
                              )}
                            </Droppable>
                            }
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </InfiniteScroll>

      <ConfirmationModal
        title={isCurrentCollection ? t('sure_delete_collection') : t('sure_delete_product')}
        primaryButtonText={isCurrentCollection ? t('yes_delete_collection') : t('yes_delete_product')}
        handlePrimaryButtonClick={deleteTrackingLink}
        secondaryButtonText={isCurrentCollection ? t('no_keep_collection') : t('no_keep_product')}
        open={openDeleteConfirmationModal}
        onClose={() => {
          setOpenDeleteConfirmationModal(false);
        }}
        disabled={isLoading}
      />

      <ConfirmationModal
        title={isCurrentVisible ? 
          isCurrentCollection ? t('hide_collection_confirmation') : t('hide_product_confirmation') : 
          isCurrentCollection ? t('publish_collection_confirmation') : t('publish_product_confirmation')
        }
        primaryButtonText={isCurrentVisible ? 
          isCurrentCollection ? t('yes_hide_collection') : t('yes_hide_product') : 
          isCurrentCollection ? t('yes_publish_collection') : t('yes_publish_product')
        }
        handlePrimaryButtonClick={updateVisibility}
        secondaryButtonText={isCurrentVisible ? t('no_publish') : t('no_hide')}
        open={openVisibilityConfirmationModal}
        onClose={() => {
          setOpenVisibilityConfirmationModal(false);
        }}
        disabled={isLoading}
      />
    </div>
  );
};

export default TrackingLinksTable;
