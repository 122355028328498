/* eslint-disable new-cap */
import React, {
    useEffect,
    useMemo,
    useState,
  } from 'react';
  import moment, {Moment} from 'moment';
  import useRequest from '../../../../features/API/request';
  import styles from './DemographicVisits.module.scss';
  import Loader from 'react-loader-spinner';
  import {useTranslation} from "react-i18next";
  import "antd/dist/antd.css";
  import {Space} from "antd";
  import DateTabs from "../DateTabs/DateTabs";
  import DemographicChart from '../../../Charts/DemographicChart';
  import ReactCountryFlag from "react-country-flag";
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { countryAbbreviations } from '../../../../constants/countries';

  interface countryProps {country: string, countryId: string, visits: number};
  
  const DemographicVisits = () => {
    const {t} = useTranslation();
    const [data, setData] = useState(false);
    const [value, setValue] = useState([]);
    const [stackedData, setStackedData]= useState<[string, number|string][]>([]);
    const [countryData, setCountryData]= useState<countryProps[]>([]);
    const [showMore, setShowMore]= useState<boolean>(false);
    const stackRequest = useRequest({method: 'GET', url: '/linkAnalytics/demographics'});

    const totalValue = useMemo(() => countryData.reduce((accumulator, currentValue) => accumulator + currentValue?.visits, 0), [countryData]);
  
    const getDemographicAnalyticsData = async () => {
      try {
        setData(false);
        const params = {
          startDate: moment(`${moment(value[0]).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          endDate: moment(`${moment(value[1]).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm:ss').toISOString(),
          offset: (new Date()).getTimezoneOffset(),
        };
        const stackResult = await stackRequest.doRequest(params);
        if (stackResult) {
          const mapping: [string, string|number][] = [["Country", "Visits"]];
          const countryMapping: countryProps[] = [];
          stackResult.map((country: {dimensionValues: {oneValue: string, value: string}[], metricValues: {oneValue: string, value: string | number}[]}) => {
            mapping.push([country?.dimensionValues[0]?.value, parseInt(country?.metricValues[0]?.value as string)]);
            countryMapping.push({country: country?.dimensionValues[0]?.value, countryId: country?.dimensionValues[1]?.value, visits: parseInt(country?.metricValues[0]?.value as string)});
          });
          setStackedData(mapping);
          setCountryData(countryMapping);
          setData(true);
        } else {
          setData(true);
        }
      } catch (error) {
        setData(true);
        console.log('Error occured', error);
      }
    };

    const getCountryStructure = () => {
      if (countryData?.length) {
        const countryDataCopy = JSON.parse(JSON.stringify(countryData));
        const loopData = showMore ? countryDataCopy : countryDataCopy.splice(0, 4);
        return loopData.map((ele: countryProps) => (
            <div className={styles.country_row} key={ele.countryId}>
                <div><ReactCountryFlag svg style={{width: '2em', height: '2em'}} countryCode={ele?.countryId} />&nbsp;&nbsp;&nbsp;&nbsp;{countryAbbreviations[ele?.country] || ele?.country}</div>
                <div>{Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format((ele?.visits/totalValue)*100)}%</div>
            </div>
        ));
      } else {
        return (<div className={styles.country_row}>
          {t('no_data_found')}
        </div>);
      };
    };
  
    useEffect(() => {
        if (value.length) {
          getDemographicAnalyticsData();    
        }
    }, [value]);
  
    return (
      <div className={styles.total_clicks_social_media_wrapper}>
          <div className={styles.total_clicks_wrapper}>
            <div className={styles.description_select_wrapper}>
              <p>{t('geography')}</p>
              <Space direction="vertical" size={12}>
                <DateTabs handleChange={setValue} />
              </Space>
            </div>
            <br />
            <div className={styles.map_parent}>
                <div className={styles.chart_wrapper}>
                {data ? <DemographicChart values={stackedData} /> :
                <div style={{position: 'relative'}}>
                    <div style={{position: 'absolute', top: '20%', right: '45%'}}>
                    <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
                    </div>
                    {/* <DemographicChart values={[["Country", "Visits"]]} /> */}
                </div>
                }
                </div>
                <div className={styles.country_list_wrapper}>
                    <div className={styles.list}>
                      <div className={styles.list_div}>
                      {getCountryStructure()}
                      </div>
                     {countryData?.length > 4 && <div className={styles.sell_all} onClick={() => setShowMore(!showMore)}>
                        <div>{showMore ? t('See less') : t('See all')}</div>
                        <div>{showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
                      </div>}
                    </div>
                </div>
            </div>
          </div>
        </div>
    );
  };
export default DemographicVisits;
