/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';

interface SubmitButtonProps {
  loading: boolean;
  step: string;
  linkType: string;
  visitorData: any;
  termsOfService: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  calendarDataSelected?: boolean;
  locale: string; // Add locale as a prop
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  loading,
  step,
  visitorData,
  termsOfService,
  linkType,
  onClick,
  calendarDataSelected = false,
  locale,
}) => {
  const { t } = useTranslation();

  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.button}
      disabled={
        loading ||
        (step === 'googleCalendarView' && !calendarDataSelected) ||
        (step === 'form' &&
          (!visitorData.name.length ||
            !visitorData.email.length ||
            !visitorData.phone.length ||
            !visitorData.countryCode.length ||
            !termsOfService))
      }
    >
      <span className={styles.button_text}>
        {loading ? (
          <div className={styles.spinner}></div>
        ) : (
          t(linkType === 'shortCall' ? 'next' : 'Buy now', { lng: locale }) // Specify locale
        )}
      </span>
    </button>
  );
};

export default SubmitButton;
