import React from "react";
import Chart from "react-apexcharts";
import "../Charts/charts.modules.scss";

const LineChart = ({
  values,
  labels,
}: {
  values: number[];
  labels: string[];
}) => {
  const maxValue = Math.max(...values);
  const maxAxisValue = maxValue < 50 ? 50 : maxValue;

  const options: ApexCharts.ApexOptions | undefined = {
    chart: {
      id: "basic-bar",
      type: "line",
      toolbar: {
        show: false,
      },
    },

    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
      categories: labels,
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      min: 0,
      max: maxAxisValue,
      tickAmount: 5,
      labels: {
        formatter: function(value) {
          return value?.toLocaleString();
        },
      },
    },
    theme: {
      monochrome: {
        enabled: true,
        color: "#FF002B",
        shadeIntensity: 0.65,
      },
    },
    grid: {
      show: false,
    },
    markers: {
      colors: ["#000000"],
      strokeColors: "#fff",
      strokeWidth: 5,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 10,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      onClick: undefined,
      onDblClick: undefined,
      showNullDataPoints: true,
      hover: {
        size: 10,
        sizeOffset: 3,
      },
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }: any) {
        return (
          '<div className="test">' +
          '<p className="serie">' +
          series[seriesIndex][dataPointIndex] +
          " </p>" +
          '<span className="category">' +
          categories[dataPointIndex] +
          "</span>" +
          "</div>"
        );
      },
    },
  };
  const series = [
    {
      name: "",
      data: values,
    },
  ];
  const categories = labels;
  return (
    <div className="chart" id="lineChart" style={{ height: "500px" }}>
      <Chart
        options={options}
        series={series}
        type="line"
        width="100%"
        height="340px"
      />
    </div>
  );
};

export default LineChart;
