import React from 'react';
import {store} from '../../app/store';
import {socialMediaLinks} from '../../constants/socialMedia';
import {setDataChange} from '../../features/stores/changeDataSlicer';
import {setUserTheme, UserThemeState} from '../../features/stores/userThemeSlicer';
import styles from './IconStylePicker.module.scss';
import {useTranslation} from 'react-i18next';
import defaultFb from '../../assets/images/versionV2/DesignIcons/icons/defaultFb.svg';
import blackFb from '../../assets/images/versionV2/DesignIcons/icons/blackFb.svg';
import whiteFb from '../../assets/images/versionV2/DesignIcons/icons/whiteFb.svg';


interface IIconStyleProps {
    selectedIconStyle: string;
    setSelectedIconStyle: Function;
    theme: UserThemeState;
}

const IconStylePicker = ({selectedIconStyle, setSelectedIconStyle, theme}:IIconStyleProps)=>{
  const {t} = useTranslation();
  const fb = socialMediaLinks.find((el)=>el.key === 'facebook');
  const buttonData = [{
    key: 'default',
    title: t('default'),
    icon: defaultFb,
    backgroundColor: '#1877F2',
    color: 'white',
  }, {
    key: 'black',
    title: t('black'),
    icon: blackFb,
    backgroundColor: 'white',
    color: 'black',
  }, {
    key: 'white',
    title: t('white'),
    icon: whiteFb,
    backgroundColor: 'black',
    color: 'white',
  }];

  return (<div className={styles.container}>
    <div className={styles.section_name}>
        {t('icon_style')}
    </div>
    <div className={styles.button_container}>
      {buttonData.map((item)=>
        <div key={item.key}
          style={{...(selectedIconStyle === item.key ?
            {border: '1px solid #F2DBEF', background: '#F2DBEF'} : {})}}
          className={styles.wrapper}
          onClick={()=>{
            store.dispatch(setUserTheme({...theme, 'iconSet': item.key, 'themeName': 'custom'}));
            setSelectedIconStyle(item.key);
            store.dispatch(setDataChange(true));
          }}
        >
          <div className={styles.button}>
            <div><img src={item.icon} /></div>
          </div>
          {item.title}
        </div>,
      )}

    </div>
  </div>);
};

export default IconStylePicker;
