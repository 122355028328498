import React, {useEffect, useRef} from 'react';
import styles from './Deals.module.scss';
import Loader from 'react-loader-spinner';
import DealItem from './DealItem';
import {useTranslation} from 'react-i18next';
import {dealsConst} from '../../constants/helper';
import {dealsStatus} from '../../pages/DealsPage/DealsPage';
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';

interface AppliedDealsProps {
  appliedDeals: any[];
  handleGettingDeals: (status: dealsStatus, keyword?: string) => Promise<void>;
  loading: boolean;
}

const AppliedDeals = ({appliedDeals, handleGettingDeals, loading}: AppliedDealsProps) => {
  const {t} = useTranslation();
  const scrollableDivRef = useRef() as any;

  return (
    <div className={styles.container}>
      {loading ? 
        <div className={styles.loader}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div> : 
        <>
          {appliedDeals.length > 0 ? (
            <div className={styles.scrollable} ref={scrollableDivRef}>
              {appliedDeals.map((item, index) => (
                <div key={item.id} className={styles.scrollableItem}>
                  <DealItem
                    deal={item}
                    handleDealApply={() => {}}
                    activeId={''}
                    status={dealsConst.APPLIED}
                  />
                </div>
              ))}
            </div>
          ) : (
              <div className={styles.no_links}>
                <img src={noVideo} alt="No deals" />
                <div className={styles.no_links_text}>
                  <div className={styles.no_links_title}>{t('no_deals_to_display')}</div>
                </div>
              </div>
            )}
        </>
      }
    </div>
  );
};

export default AppliedDeals;
