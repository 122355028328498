import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles from './WalletHome.module.scss';
import Wallet from './../../assets/images/versionV2/HelperIcons/Wallet.svg';
import { themeColor } from '../../constants/newThemes';
import { useSelector } from 'react-redux';
import { getWalletStore } from '../../features/stores/walletSlicer';
import { getConfig } from '../../features/stores/configSlicer';
import { haveSegmentPermission } from '../../features/util';
import { getCurrencyLabel } from '../../constants/currencies';
import { Tooltip } from 'antd';
import { ReactComponent as Info } from '../../assets/images/info.svg';
import { PrimaryButton } from '../Buttons/PrimaryButton';

const ButonCustom = withStyles({
  root: {
    background: (props: any) => props.background,
    color: (props: any) => props.color,
    borderRadius: '40px',
    padding: '4px 24px',
    textTransform: 'capitalize',
  },
})(Button);

const WalletHome = ()=> {
  const { t } = useTranslation();
  const walletList = useSelector(getWalletStore).data || [];
  const config = useSelector(getConfig).data || [];
  const currencyMap = haveSegmentPermission(config, 'currencyFactor') || [];
  const currencyConversion = haveSegmentPermission(config, 'currencyConversion') || { AEDToUSD: 0.27, SARToUSD: 0.28, USDToUSD: 1 };

  const walletDisplay = useMemo(() => {
    let display = 0;
    if (walletList?.length) {
      walletList.map((wallet: any) => {
        display += (currencyConversion[`${wallet?.currency?.toUpperCase()}ToUSD`] * (Number(wallet.balance)/(currencyMap.filter((e: any) => e.currency==wallet.currency)[0]?.conversionFactor||100)));
      });
    }
    return `$${display.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  }, [walletList, currencyMap]);

  const handleOpen = () => {
    window.location.href = `${window?.location?.origin}/earnings/`;
  };

  const getInfo = () => {
    const currencyBreakDown = Object.keys(currencyConversion);
    return (<div>
      <div>{t('conversion')}</div>
      {currencyBreakDown?.map((e: any, index: number) => {
        return <div key={index}>
          {`${e?.replace('To', ' To ')}: ${currencyConversion[e]}`}
        </div>;
      })}
    </div>);
  };

  return (
    <div className={styles.wallet_conatiner_box}>
      <div className={styles.box_left}>
        <div className={styles.wallet}>
          <img src={Wallet} />
        </div>
        <div className={styles.wallet_brief}>
          <div className={styles.wallet_brief_box}>
            {t('my_balance')}
            <Tooltip title={getInfo()} >
              <div>
                <Info />
              </div>
            </Tooltip>
          </div>
          <div className={styles.wallet_value}>{walletDisplay}</div>
        </div>
      </div>
      <div className={styles.box_right}>
        <PrimaryButton
          label={t('open')}
          onClick={handleOpen}
          color="red"
          style={{
            fontSize: '13px',
            width: '80px',
            minWidth: 'unset',
          }}
        />
      </div>
    </div>
  );
};

export default WalletHome;
