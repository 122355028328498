import React, {useEffect, useState} from 'react';
import Loader from 'react-loader-spinner';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import styles from './ChargeCardModal.module.scss';
import CloseIcon from '@material-ui/icons/Close';
import {CardForm} from './CardForm';
import nonHookRequest from '../../features/API/nonHookRequest';
import {envConfig} from '../../features/EnvironmentVariables/enviromentVariables';
import {useTranslation, Trans} from 'react-i18next';
import {toast} from 'react-toastify';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import defaultAvatar from './../../assets/images/al_default_avatar.png';
import CalendarBooking from '../CalendarBooking/CalendarBooking';
import {getCurrencyLabel} from '../../constants/currencies';
import Preview from './Preview';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import {isArabic} from '../../features/util';
import ar from 'react-phone-input-2/lang/ar.json';
import {Checkbox} from '@material-ui/core';
import LoaderComponent from '../ChargeCardTapModal/LoaderComponent';
import TipJar from '../ChargeCardTapModal/TipJar';
import InputField from '../ChargeCardTapModal/InputField';
import CheckboxField from '../ChargeCardTapModal/CheckboxField';
import SubmitButton from '../ChargeCardTapModal/SubmitButton';
import SafeHtmlDisplay from '../LinkModal/components/SafeHtmlDisplay';
import TextInput from '../LinkModal/components/TextInput';
import BookCallSlotPicker from '../BookCallSlotPicker/BookCallSlotPicker';
import moment from 'moment';
import {SectionItemContentType} from '../../constants/helper';


interface IProps {
  handleClose: () => void;
  clientSecret: any;
  setCientSecret: React.Dispatch<React.SetStateAction<any>>;
  monetizeID: string;
  theme?: any;
  monetizeObj: any;
  linksUser: any;
}
const stripePromise = loadStripe(envConfig().stripeKey as string);
const ChargeCardModal = ({
  handleClose,
  clientSecret,
  setCientSecret,
  monetizeID,
  theme,
  monetizeObj,
  linksUser,
}: IProps) => {
  const currencyLabel = getCurrencyLabel(monetizeObj.currency);
  const {t, i18n} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('form');
  const [visitorData, setVisitorData] = useState<any>({
    name: '',
    email: '',
    notes: '',
    tipJarAmount: null,
    isCustom: false,
    phone: '',
    countryCode: '',
  });
  const [visitorDataError, setVisitorDataError] = useState({
    name: '',
    email: '',
    tipJarAmount: '',
    phone: '',
    countryCode: '',
  });
  const [title, setTitle] = useState('');
  const [termsOfService, setTermsOfService] = useState(false);
  const [selectionObj, setSelectedObj] = useState<any>({activeDuration: '', activeSlot: '', timezone: '', activeDate: '', amount: ''});
  const calendarDataSelected = Object.keys(selectionObj).every((e) => selectionObj[e]);

  const urlParams = new URLSearchParams(window.location.search);

  const profileAvatar = linksUser ?
    linksUser.avatar?.url || linksUser.avatar || defaultAvatar :
    theme.avatar || defaultAvatar;
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const locale = theme.displayLocale || 'en';

  const createPaymentIntent = async () => {
    try {
      setLoading(true);

      const calendarPayload = step == 'googleCalendarView' ?
        {startDate: moment(`${selectionObj?.activeDate} ${selectionObj?.activeSlot?.startTime}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm:SS'), 
        endDate: moment(`${selectionObj?.activeDate} ${selectionObj?.activeSlot?.startTime}`, 'YYYY-MM-DD hh:mm A').add(selectionObj?.activeDuration, 'm').format('YYYY-MM-DDTHH:mm:SS'), 
        timezone: selectionObj?.timezone} : undefined;

      const intentResponse = await nonHookRequest({method: 'POST', url: '/finance/api/stripe/paymentIntent',
          body: {details: calendarPayload, linkId: monetizeID, currency: monetizeObj?.currency,
            amount: monetizeObj?.type == 'tipJar' ? (visitorData.tipJarAmount * 100) : undefined, productType: 'Link',
            sectionItemPriceValue: step == 'googleCalendarView' ? selectionObj?.activeDuration : 'default'}, isShortUrl: true,
          });

      if (intentResponse?.clientSecret) {
        setCientSecret(intentResponse?.clientSecret);
      } else if (intentResponse?.name == 'Error') {
        toast(
            <span style={{color: 'black'}}>{intentResponse.message}</span>,
            {autoClose: 3000},
        );
        handleClose();
      }
      setLoading(false);
    } catch (error) {
      console.log('something went wrong!');
      setLoading(false);
    }
  };

  const handleChangeInput = (value: any, param: string) => {
    if (!value && param != 'notes') {
      if (param == 'tipJarAmount' && monetizeObj.type != 'tipJar') {
        return;
      }
      setVisitorDataError({
        ...visitorDataError,
        [param]: t('this_field_is_required', {
          lng: theme.displayLocale || 'en',
        }),
      });
    } else {
      setVisitorDataError({...visitorDataError, [param]: ''});
    }
    setVisitorData({
      ...visitorData,
      [param]: param == 'tipJarAmount' ? parseInt(value) : value,
    });
  };

  const validateData = () => {
    let valid = true;
    let obj = JSON.parse(JSON.stringify(visitorDataError));
    if (!visitorData.name) {
      obj = {
        ...obj,
        name: t('this_field_is_required', {lng: theme.displayLocale || 'en'}),
      };
      valid = false;
    }
    if (
      !visitorData.email ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(visitorData.email)
    ) {
      obj = {
        ...obj,
        email: t('not_a_valid_email', {lng: theme.displayLocale || 'en'}),
      };
      valid = false;
    }
    if (
      monetizeObj.type == 'tipJar' &&
      (!visitorData?.tipJarAmount ||
        visitorData?.tipJarAmount < monetizeObj.minPrice ||
        visitorData?.tipJarAmount > monetizeObj.maxPrice)
    ) {
      obj = {
        ...obj,
        tipJarAmount:
          visitorData?.tipJarAmount > monetizeObj.maxPrice ?
            `${t('tip_amount_cant_be_more_than')} ${currencyLabel} ${
              monetizeObj.maxPrice
            }` :
            `${t('tip_amount_cant_be_less_than')} ${currencyLabel} ${
              monetizeObj.minPrice
            }`,
      };
      valid = false;
    }
    if (!visitorData.phone) {
      obj = {
        ...obj,
        phone: t('this_field_is_required', {
          lng: theme.displayLocale || 'en',
        }),
      };
      valid = false;
    }
    if (!visitorData.countryCode) {
      obj = {
        ...obj,
        countryCode: t('this_field_is_required', {
          lng: theme.displayLocale || 'en',
        }),
      };
      valid = false;
    }
    setVisitorDataError(obj);
    return valid;
  };

  const getHeading = () => {
    switch (monetizeObj.type) {
      case 'videoMsg':
        return {text: monetizeObj.title, notes: 'whats_your_question'};
      case 'tipJar':
        return {
          text: monetizeObj.title,
          notes: 'something_you_would_like_to_tell_us',
        };
      case 'socialMediaPromote':
        return {
          text: monetizeObj.title,
          notes: 'what_would_you_like_to_promote',
        };
      case 'sellProduct':
        return {
          text: monetizeObj.title,
          notes: 'something_you_would_like_to_tell_us',
        };
      case 'sellProductGuide':
        return {
          text: monetizeObj.title,
          notes: 'something_you_would_like_to_tell_us',
        };
      case 'shortCall':
        return {
          text: monetizeObj.title || 'book_one_one_call',
          notes: 'please_share_anything_that_will_help_prepare_for_our_meeting',
        };
      default:
        return {text: monetizeObj.title || 'card_payment', notes: 'notes'};
    }
  };

  const isRefundable = (monetizationType: string) => {
    return monetizationType == 'socialMediaPromote';
  };

  const handlePhoneChange = (phone: any, country: any) => {
    const countryDial = country.dialCode;
    setVisitorData({...visitorData, phone: phone, countryCode: countryDial});
  };

  const handleNextStep = async () => {
    if (step == 'preview') {
      setStep('form');
    } else if (step == 'form' && monetizeObj?.contentType !== SectionItemContentType.GOOGLE_CALENDAR) {
      const isValid = validateData();
      isValid ? createPaymentIntent() : '';
    } else if (step == 'form' && monetizeObj?.contentType === SectionItemContentType.GOOGLE_CALENDAR) {
      setStep('googleCalendarView');
    } else if (step == 'googleCalendarView' && calendarDataSelected) {
      const isValid = validateData();
      isValid ? createPaymentIntent() : '';
    }
  };

  useEffect(() => {
    // api hit for intent
    if (clientSecret) {
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get('linkType') || '';
      if (type == 'shortCall' && !monetizeID) {
        const name = urlParams.get('name') || '';
        const email = urlParams.get('email') || '';
        const notes = urlParams.get('notes') || '';
        setVisitorData({
          ...visitorData,
          name: name,
          email: email,
          notes: notes,
        });
      }
      setStep('card');
    }
  }, [clientSecret]);

  useEffect(() => {
    if (step && step == 'bookingSlot') {
      toast(
          <span style={{color: 'black'}}>
            {t('your_payment_is_successfully_done', {
              lng: theme.displayLocale || 'en',
            })}
          </span>,
          {autoClose: 3000},
      );
    }
  }, [step]);

  const newSuccessModalLinkTypes = ['sellProductGuide', 'sellProduct'];

  return (
    <>
      <div className={styles.container}>
        <div className={styles.modal}>
          <div className={styles.modal_content}>
            <div className={styles.cross} onClick={handleClose}>
              <CloseIcon />
            </div>
            {step == 'card' && monetizeID && (
              <div className={styles.back} onClick={() => setStep('form')}>
                <ArrowBackIcon />
              </div>
            )}

            {(monetizeObj.type === 'sellProductGuide' ||
              monetizeObj.type === 'sellProduct') &&
            step !== 'card' &&
            !loading ? (
              <Preview
                theme={theme}
                monetizeObj={monetizeObj}
                linksUser={linksUser}
                getHeading={getHeading}
              />
            ) : (
              ''
            )}

            {(newSuccessModalLinkTypes.includes(urlParams.get('linkType')!)) ? null : <h4 style={{marginBottom: '0px', textAlign: 'center'}}>
              { t(
                    step == 'card' && clientSecret ?
                      'card_payment' :
                      getHeading()?.text,
                    {lng: theme.displayLocale || 'en'},
              )}
            </h4>}
            {monetizeObj.note && step==='form' && (
              <SafeHtmlDisplay htmlContent={monetizeObj.note} />
            )}
            <div className={styles.content_body}>
              {['form', 'preview'].includes(step) && !loading && (
                <form>
                  <>
                    <div className={styles.row}>
                      {monetizeObj.type === 'tipJar' && (
                        <TipJar
                          monetizeObj={monetizeObj}
                          visitorData={visitorData}
                          setVisitorData={setVisitorData}
                          visitorDataError={visitorDataError}
                          currencyLabel={getCurrencyLabel(monetizeObj.currency)}
                          handleChangeInput={handleChangeInput}
                          locale={theme.displayLocale || 'en'}
                        />
                      )}
                      <InputField
                        label={t('full_name', {
                          lng: theme.displayLocale || 'en',
                        })}
                        value={visitorData.name}
                        error={visitorDataError.name}
                        onChange={(e) =>
                          handleChangeInput(e.target.value, 'name')
                        }
                      />
                      <InputField
                        label={t('email', {lng: theme.displayLocale || 'en'})}
                        type="email"
                        value={visitorData.email}
                        error={visitorDataError.email}
                        onChange={(e) =>
                          handleChangeInput(e.target.value, 'email')
                        }
                      />
                      <PhoneNumber
                        localization={isArabic(i18n.language) ? ar : undefined}
                        placeholder=" " // Leave this empty to trigger the floating label
                        value={visitorData.phone}
                        onChange={(v, c) => handlePhoneChange(v, c)}
                        country="ae"
                        inputClass={styles.phoneInput}
                        specialLabel={t('mobileNumber', {
                          lng: theme.displayLocale || 'en',
                        })}
                        inputProps={{
                          name: 'phone',
                          required: true,
                          id: 'phone',
                        }}
                      />
                      {monetizeObj.type === 'sellProductGuide' || monetizeObj.type === 'sellProduct' ? (
                        ''
                      ) : (
                        <TextInput
                          value={visitorData.notes}
                          onChange={(e) =>
                            handleChangeInput(e.target.value, 'notes')
                          }
                          label={t(getHeading()?.notes, {
                            lng: theme.displayLocale || 'en',
                          })}
                          multiline
                          minRows={5}
                          containerStyle={{marginTop: '1rem'}}
                          inputProps={{
                            style: {
                              padding: '1rem 0 0 0',
                            },
                          }}
                        />
                      )}
                      <div className={styles.row}>
                        {monetizeObj.type === 'tipJar' ? (
                          visitorData?.tipJarAmount ? (
                            <div className={styles.totalSection}>
                              <div className={styles.totalLabel}>
                                {t('total', {
                                  lng: theme.displayLocale || 'en',
                                })}
                              </div>
                              <div className={styles.dottedLine}></div>
                              <div className={styles.totalPrice}>
                                {currencyLabel} {visitorData.tipJarAmount}
                              </div>
                            </div>
                          ) : null
                        ) : (
                          <div className={styles.totalSection}>
                            <div className={styles.totalLabel}>
                              {t('total', {lng: theme.displayLocale || 'en'})}
                            </div>
                            <div className={styles.dottedLine}></div>
                            <div className={styles.totalPrice}>
                              {currencyLabel} {monetizeObj?.amount[0]?.amount / 100}
                            </div>
                          </div>
                        )}
                      </div>

                      {<CheckboxField
                        checked={termsOfService}
                        onChange={() => setTermsOfService(!termsOfService)}
                        label={
                          <Trans i18nKey='term_and_conditions'>
                          Accept <a href="https://app.al.fan/terms-and-condition-buyer" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href="https://app.al.fan/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                          </Trans>
                        }
                      />}
                    </div>

                    {isRefundable(monetizeObj.type) && (
                      <div className={styles.refund_policy}>
                        <br />
                        {t('refund_policy')}
                      </div>
                    )}
                    <SubmitButton
                      loading={loading}
                      step={step}
                      linkType={monetizeObj.type}
                      visitorData={visitorData}
                      termsOfService={termsOfService}
                      onClick={handleNextStep}
                      calendarDataSelected={calendarDataSelected}
                      locale={locale}
                    />
                  </>
                </form>
              )}
              {['googleCalendarView'].includes(step) && !loading && (
                <form>
                  <>
                    <BookCallSlotPicker selectionObj={selectionObj} setSelectedObj={setSelectedObj} monetizeObj={monetizeObj} language={theme.displayLocale} currencyLabel={currencyLabel} monetizeID={monetizeID} />

                    {isRefundable(monetizeObj.type) && (
                      <div className={styles.refund_policy}>
                        <br />
                        {t('refund_policy')}
                      </div>
                    )}
                    <SubmitButton
                      loading={loading}
                      step={step}
                      linkType={monetizeObj.type}
                      visitorData={visitorData}
                      termsOfService={termsOfService}
                      onClick={handleNextStep}
                      calendarDataSelected={calendarDataSelected}
                      locale={locale}
                    />
                  </>
                </form>
              )}
              {step == 'card' && clientSecret && (
                <Elements
                  options={options as StripeElementsOptions}
                  stripe={stripePromise}
                >
                  <CardForm
                    theme={theme}
                    visitorData={visitorData}
                    monetizeObj={monetizeObj}
                    monetizeID={monetizeID}
                    setTitle={setTitle}
                    // onPaymentIntentReady={() => setLoading(false)}
                    step={step}
                    setStep={setStep}
                    handleClose={handleClose}
                  />
                </Elements>
              )}
              {step == 'bookingSlot' && monetizeObj?.contentType == SectionItemContentType.CALENDLY && (
                <CalendarBooking
                  url={`${monetizeObj.url}?hide_gdpr_banner=1&name=${visitorData.name}&email=${visitorData.email}&notes=${visitorData.notes}`}
                />
              )}
              {loading && <LoaderComponent />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChargeCardModal;
