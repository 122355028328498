import React, {useState, useEffect} from 'react';
import {truncateUrl} from '../../Helper/generalHelper';
import emptyProductImage from '../../assets/images/shop/empty_product.png';
import defaultStyle from './PublicProfileCollection.module.scss';
import mobilePreviewStyle from './PublicProfileCollection_mobile-preview.module.scss';
import desktopPreviewStyle from './PublicProfileCollection_desktop-preview.module.scss';
import BaseModal from '../LinkModal/components/BaseModal';
import {BaseModalProps} from '../LinkModal/components/BaseModal';
import {useSelector} from 'react-redux';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import {useTranslation} from 'react-i18next';
import {previewStyles} from '../../constants/newThemes';

interface IProps{
  item: any;
  isClicked: boolean;
  selectedStyle: string;
}

const PublicProfileCollection = ({item, isClicked, selectedStyle}: IProps) => {
  let styles: any = '';
  if (selectedStyle === previewStyles.DESKTOP_PREVIEW) styles = desktopPreviewStyle;
  if (selectedStyle === previewStyles.MOBILE_PREVIEW) styles = mobilePreviewStyle;
  if (selectedStyle === previewStyles.DEFAULT) styles = defaultStyle;
  return (
    <div className={styles.dealContainer}>
      {item?.collection ? 
        <RenderCollection collection={item} isClicked={isClicked} styles={styles} /> : 
        <RenderProduct product={item?.product} styles={styles} />
      }
    </div>
  );
};

const RenderCollection = ({collection, isClicked, styles}: any) => {
  const [openCollectionDetailsModal, setOpenCollectionDetailsModal] = useState(false);

  const {t} = useTranslation();
  const theme = useSelector(selectUserTheme);

  const translate = (key : string) => {
    return t(key, {lng: theme.displayLocale || 'en'});
  };

  useEffect(() => {
    if (isClicked) {
      setOpenCollectionDetailsModal(true);
    }
  }, [isClicked]);

  return (
    <>
      <div className={styles.imageContainer} onClick={() => setOpenCollectionDetailsModal(true)}>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
          {collection?.collection?.products?.slice(0, 4)?.map((product: any, i: number) => {
            return (
              <img 
                src={product?.product?.logoUrl} 
                className={
                  collection?.collection?.products?.length === 1 ? styles.smallImageOne : 
                  collection?.collection?.products?.length === 2 ? styles.smallImageTwo : 
                  (collection?.collection?.products?.length === 3 && i===2) ? styles.smallImageThree : 
                  styles.smallImage
                }
                alt=""
                key={i}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.textWrapper}>
        <p className={styles.dealName}>{truncateUrl(collection?.collection?.name, 25)}</p>
        <p className={styles.productsCount}>{collection?.collection?.products?.length} {collection?.collection?.products?.length > 1 ? translate('items') : translate('item')}</p>
      </div>

      <ShowCollectionDetailsModal 
        open={openCollectionDetailsModal}
        onClose={() => setOpenCollectionDetailsModal(false)}
        collection={collection?.collection}
        styles={styles}
      />
    </>
  );
};

const RenderProduct = ({product, styles}: any) => {
  return (
    <>
      <div className={styles.imageContainer}>
        <a href={product?.trackingLink} target="_blank" rel="noreferrer">
          <img
            src={product?.logoUrl}
            style={{width: '100%', height: '100%', cursor: 'pointer', borderRadius: '10px', objectFit: 'cover'}}
            alt=""
          />
        </a>
      </div>

      <div className={styles.textWrapper}>
        <p className={styles.dealName}>{truncateUrl(product?.title, 25)}</p>
      </div>
    </>
  );
};

const PaperProps= {
  style: {
    width: '80%',
    maxWidth: '550px',
    height: '700px',
  },
};

type Props = BaseModalProps & {
  collection: any;
  styles: any;
};

export const ShowCollectionDetailsModal = ({collection, onClose, styles, ...props}: Props) => {
  const {t} = useTranslation();
  const theme = useSelector(selectUserTheme);

  const translate = (key : string) => {
    return t(key, {lng: theme.displayLocale || 'en'});
  };

  return (
    <BaseModal {...props} PaperProps={PaperProps} onClose={() => onClose({}, 'close')}>
      <div className={styles.container}>
        <h5 style={{fontWeight: 600}}>{collection?.name}</h5>
        <p className={styles.productsCount}>{collection?.products?.length} {collection?.products?.length > 1 ? translate('items') : translate('item')}</p>
        <div className={`${styles.dealsContainer} ${styles.customScrollbar}`}>
          {collection?.products?.length && collection?.products?.map((product: any, i: number) => {
            return (
              <div className={styles.dealContainer} style={{margin: '5px', flexBasis: '45%'}} key={i}>
                <RenderProduct product={product?.product || product} styles={styles} />
              </div>
            );
          })}
        </div>
      </div>
    </BaseModal>
  );
};

export default PublicProfileCollection;
