import React from "react";
import BaseModal from "../components/BaseModal";
import {BaseModalProps} from "../components/BaseModal";
import style from './ConfirmationModal.module.scss';
import {PrimaryButton} from "../../Buttons/PrimaryButton";

type Props = BaseModalProps & {
  title: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  handlePrimaryButtonClick: () => void;
  disabled?: boolean;
};

const ConfirmationModal = ({
  title,
  primaryButtonText,
  handlePrimaryButtonClick,
  secondaryButtonText,
  onClose, 
  disabled = false,
  ...props
}: Props) => {
  return (
    <BaseModal {...props} onClose={onClose}>
      <div className={style.container}>
        <h5 className={style.heading}>{title}</h5>
        <div className={style.buttonContainer}>
          <PrimaryButton 
            label={primaryButtonText}
            onClick={handlePrimaryButtonClick}
            style={{width: "220px", height: '40px'}}
            disabled={disabled}
          />
          <span className={style.spacing}></span>
          <PrimaryButton 
            label={secondaryButtonText}
            onClick={() => onClose({}, 'close')}
            color="black"
            style={{width: "220px", height: '40px'}}
          />
        </div>
      </div>
    </BaseModal>
  );
};

export default ConfirmationModal;
