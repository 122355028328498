import React, {
  useEffect,
  useState,
  useRef,
} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './TrackingLinksPage.module.scss';
import DealsContainer from '../../components/Deals/DealsContainer';
import TrackingLinksTable from '../../components/TrackingLinks/TrackingLinksTable';
import AddTrackingLinkModal from '../../components/AddTrackingLinkModal/AddTrackingLinkModal';
import nonHookRequest from "../../features/API/nonHookRequest";
import {PlusCircleFilled} from '@ant-design/icons';
import {ButtonCustom} from '../../components/ImportantLinks/ImportantLinks';
import useWindowWidth from '../../hooks/useWindowWidth';
import {themeColor} from '../../constants/newThemes';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import {deal} from '../../features/stores/dealsSlicer';
import {useDebouncedCallback} from "use-debounce";
import Loader from 'react-loader-spinner';
import {PrimaryButton} from '../../components/Buttons/PrimaryButton';
import AddCollectionModal from '../../components/AddCollectionModal/AddCollectionModal';

export interface TrackingLink {
  id: string;
  userDealId: string;
  productTrackingLink: string;
  description: string;
  visible: boolean;
  logoUrl: string;
  createdAt: string;
  updatedAt: string;
  userDeal: {
    id: string;
    deal: deal;
  }
}

export type ProductEditingInfoProps = {
  id?: string;
  productUrl?: string;
  title?: string;
  logoUrl?: string;
  isVisible?: boolean;
}

export type CollectionEditingInfoProps = {
  id?: string;
  name?: string;
  productIds?: string[];
}

const TrackingLinksPage: React.FC = () => {
  const [openTrackingLinkModal, setOpenTrackingLinkModal] = useState(false);
  const [openCollectionModal, setOpenCollectionModal] = useState(false);
  const [trackingLinks, setTrackingLinks] = useState<TrackingLink[]>([]);
  const [trackingLinksTotalCount, setTrackingLinksTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(true);
  const [productEditingInfo, setProductEditingInfo] = useState<ProductEditingInfoProps>({});
  const [collectionEditingInfo, setCollectionEditingInfo] = useState<CollectionEditingInfoProps>({});
  const [products, setProducts] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);
  const {t} = useTranslation();
  const keywordRef = useRef("");
  const mobile = useWindowWidth();

  const perPage = 100;
  const isInitialEmptyState = !trackingLinks?.length && !keywordRef.current;

  useEffect(() => {
    getProducts();
    getCollections();
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const response = await nonHookRequest({
      method: 'GET',
      url: `/brandshub/api/product?start=0&limit=100`,
      isShortUrl: true,
    });
    setProducts(response.products);
    setLoading(false);
  };

  const getCollections = async () => {
    const response = await nonHookRequest({
      method: 'GET',
      url: `/brandshub/api/collection?start=0&limit=20&excludeDealCollection=true`,
      isShortUrl: true,
    });
    setCollections(response?.collections);
  };

  useEffect(() => {
    getTrackingLinks();
  }, [offset]);

  const getTrackingLinks = async () => {
    const response = await nonHookRequest({
      method: 'GET',
      url: `/brandshub/api/products-collections/displayOrder?start=${offset}&limit=${perPage}&searchKey=${keyword}`,
      isShortUrl: true,
    });

    setTrackingLinks((prev) => !offset ? response?.data : [...prev, ...response?.data]);
    setTrackingLinksTotalCount(response?.totalCount);
    setLoading(false);
  };
  
  const setOffsetAndFetchLinks = () => {
    setOffset(0);
    if (!offset) getTrackingLinks();
  };

  const handleSearch = useDebouncedCallback((e: any) => {
    keywordRef.current = keyword;
    setOffsetAndFetchLinks();
  }, 1000);

  return (
    <DealsContainer screen="trackingLinks">
      <div className={styles.container}>
        {isInitialEmptyState ? null :
        <>
          <div style={{display: 'flex', justifyContent: "flex-end"}}>
            <PrimaryButton label={t('plus_collection')} onClick={() => setOpenCollectionModal(true)} />
            <span style={{margin: '0 7px'}}></span>
            <PrimaryButton label={t('plus_product')} color="black" onClick={() => setOpenTrackingLinkModal(true)} />
          </div>
          
          <div>
            <OutlinedInput
              className={styles.search}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                handleSearch(e);
              }}
              startAdornment={<InputAdornment position="start"><SearchIcon></SearchIcon></InputAdornment>}
              placeholder={t('search')}
            />
          </div> 
        </>}

        {loading ? 
          <div className={styles.loader}>
            <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
          </div> :
          <TrackingLinksTable 
            trackingLinks={trackingLinks} 
            trackingLinksTotalCount={trackingLinksTotalCount}
            setOffsetAndFetchLinks={setOffsetAndFetchLinks}
            offset={offset}
            setOffset={setOffset}
            perPage={perPage}
            isSearching={!!keywordRef.current}
            setOpenTrackingLinkModal={setOpenTrackingLinkModal}
            setOpenCollectionModal={setOpenCollectionModal}
            setProductEditingInfo={setProductEditingInfo}
            setCollectionEditingInfo={setCollectionEditingInfo}
            getProducts={getProducts}
            getCollections={getCollections}
          />
        }
      </div>
      <AddTrackingLinkModal
        setOffsetAndFetchLinks={setOffsetAndFetchLinks}
        setOffset={setOffset}
        onClose={() => {
          setOpenTrackingLinkModal(false);
        }}
        open={openTrackingLinkModal}
        productEditingInfo={productEditingInfo}
        setProductEditingInfo={setProductEditingInfo}
        collections={collections}
        getProducts={getProducts}
      />
      <AddCollectionModal
        setOffsetAndFetchLinks={setOffsetAndFetchLinks}
        setOffset={setOffset}
        onClose={() => {
          setOpenCollectionModal(false);
        }}
        open={openCollectionModal}
        collectionEditingInfo={collectionEditingInfo}
        setCollectionEditingInfo={setCollectionEditingInfo}
        products={products}
        getCollections={getCollections}
        loading={loading}
      />
    </DealsContainer>
  );
};

export default TrackingLinksPage;
