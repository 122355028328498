import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TotalRevenue.module.scss";
import { useSelector } from "react-redux";
import { getWalletStore } from "../../features/stores/walletSlicer";
import { getConfig } from "../../features/stores/configSlicer";
import { haveSegmentPermission } from "../../features/util";
import { MenuItem, Select } from "@material-ui/core";
import {
  getCurrencyFullLabel,
  getCurrencyLabel,
} from "../../constants/currencies";
import { Space } from "antd";
import DateTabs from "../../pages/Analytics/Components/DateTabs/DateTabs";
import moment from "moment";
import useRequest from "../../features/API/request";
import Loader from "react-loader-spinner";
import EarningDashboardChart from "./EarningDashboardChart";
import { fetchWalletStore } from "../../features/Login/login";

const TotalRevenue = () => {
  const { t } = useTranslation();
  const walletList = useSelector(getWalletStore).data || [];
  const config = useSelector(getConfig).data || [];
  const [currencySelected, setCurrencySelected] = useState("usd");
  const [initialDataLoading, setInitialDataLoading] = useState(true); // Track initial load
  const [value, setValue] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<{
    value: { name: string; data: number[] }[];
    label: string[];
  }>({
    value: [{ name: "Earning", data: [] }],
    label: [],
  });
  const [earningData, setEarningData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [earningDataStats, setEarningDataStats] = useState({
    earningsTotal: 0,
    fanEarningsTotal: 0,
    platformEarningsTotal: 0,
    dealEarningsTotal: 0,
  });

  const revenueRequest = useRequest({
    method: "GET",
    url: `/finance/api/orders/earnings`,
    isShortUrl: true,
  });

  useEffect(() => {
    fetchWalletStore();
  }, []);


  const handleTooltipValue = (value: number) => {
    return `${getCurrencyLabel(currencySelected)}${Number(
      value
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const handleCurrencyChange = (e: any) => {
    setCurrencySelected(e?.target?.value);
  };

  const getRevenueData = async () => {
    if (!currencySelected || value.length === 0) return; // Wait until currency and value are set

    setLoading(true);
    try {
      const params = {
        offset: new Date().getTimezoneOffset(),
        startDate: moment(
          `${moment(value[0]).format("YYYY-MM-DD")} 00:00:00`,
          "YYYY-MM-DD HH:mm:ss"
        ).toISOString(),
        endDate: moment(
          `${moment(value[1]).format("YYYY-MM-DD")} 23:59:59`,
          "YYYY-MM-DD HH:mm:ss"
        ).toISOString(),
        currency: currencySelected,
      };
      const result = await revenueRequest.doRequest(params);
      setEarningData(result?.earnings || {});
      setEarningDataStats({
        earningsTotal: result?.earningsTotal || 0,
        fanEarningsTotal: result?.fanEarningsTotal || 0,
        platformEarningsTotal: result?.platformEarningsTotal || 0,
        dealEarningsTotal: result?.dealEarningsTotal || 0,
      });
    } catch (error) {
      console.log("Error occurred", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check if walletList is empty or has data
    if (config.length > 0) {
      if (walletList.length === 0 && !currencySelected) {
        // Default to USD if no walletList is available
        setCurrencySelected("usd");
      }
      // Mark initial data loading as false regardless of walletList
      setInitialDataLoading(false);
    }
  }, [walletList, config, currencySelected]);

  useEffect(() => {
    if (!initialDataLoading) {
      // Only trigger data fetch after initial loading
      getRevenueData();
    }
    console.log("here total revenue", currencySelected);
  }, [currencySelected, value, initialDataLoading]);

  useEffect(() => {
    const graphKeys = Object.keys(earningData);
    if (graphKeys.length) {
      const graphArray = graphKeys.map(
        (key) => earningData[key][0]?.total || 0
      );
      setGraphData({
        value: [{ name: "Earning", data: graphArray }],
        label: graphKeys,
      });
    }
  }, [earningData]);

  return initialDataLoading ? (
    <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
  ) : (
    <div className={styles.total_revenue_box}>
      <div className={styles.total_revenue_row}>
        <div className={styles.title_wrapper}>
          <div className={styles.title}>{t("total_revenue")}</div>
          <Select
            variant="outlined"
            fullWidth
            value={currencySelected}
            className={styles.select_main}
            onChange={handleCurrencyChange}
          >
            {walletList.length > 0 ? (
              walletList.map((wallet: any) => (
                <MenuItem key={wallet.currency} value={wallet.currency}>
                  {getCurrencyFullLabel(wallet.currency)}
                </MenuItem>
              ))
            ) : (
              <MenuItem key="usd" value="usd">
                {getCurrencyFullLabel("usd")}
              </MenuItem>
            )}
          </Select>
        </div>
        <div className={styles.revenue}>
          {currencySelected
            ? handleTooltipValue(earningDataStats.earningsTotal)
            : null}
        </div>
      </div>
      <div className={styles.total_revenue_graph}>
        <Space direction="vertical" size={12}>
          <DateTabs handleChange={setValue} />
        </Space>
        {loading ? (
          <div className={styles.loader}>
            <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
          </div>
        ) : (
          <EarningDashboardChart
            formatterTooltip={handleTooltipValue}
            values={graphData.value}
            labels={graphData.label}
          />
        )}
      </div>
    </div>
  );
};

export default TotalRevenue;
