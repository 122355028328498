import React from 'react';
import BaseModal, {BaseModalProps} from '../LinkModal/components/BaseModal';
import styles from './AddLinkModal.module.scss';
import {useTranslation} from 'react-i18next';
import LinkCard, {ExploreLinkItem} from '../ExploreModal/LinkCard';
import {useSelector} from 'react-redux';
import {addLinkIcons} from '../../constants/socialMedia';
import {getConfig} from '../../features/stores/configSlicer';
import {haveSegmentPermission} from '../../features/util';
import {LinksSectionItem, selectLinksUser} from '../../features/stores/linksUserSlicer';
import {SectionItemContentType} from '../../constants/helper';

type Props = BaseModalProps & {
  onAddLink: (link: LinksSectionItem, editable: boolean) => void;
};

const AddLinkModal = ({onClose, onAddLink, ...props}: Props) => {
  const config = useSelector(getConfig).data || [];
  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const importantLinksSection = linksUserSections.find((section) => section.type === 'important_links');
  const monetizationEnabled =
    haveSegmentPermission(config, 'monetize') && !haveSegmentPermission(config, 'monetizeBlocklist');

  const {t} = useTranslation();
  const RegularLinkIcon = addLinkIcons['regularLink'];

  // Define the desired order of links
  const linkOrder = [
    'sellProductGuide',
    'shortCall',
    'socialMediaPromote',
    'tipJar',
    'videoMsg',
    'sellProduct',
  ];

  // Add regularLink to the array and map through the monetize2 items
  const monetizeLinks: ExploreLinkItem[] = monetizationEnabled ? [
    {
      type: 'other',
      title: t('Custom Link'),
      name: t('Custom Link'),
      subTitle: t('custom_links_sub'),
      defaultIcon: <RegularLinkIcon />,
      sectionId: importantLinksSection?.id,
    },
    ...haveSegmentPermission(config, 'monetize2')?.map((item: any) => {
      const LinkIcon = addLinkIcons[item.id];
      return {
        type: item.id,
        title: item.title,
        name: t(item.title),
        subTitle: item.subTitle,
        defaultIcon: <LinkIcon />,
      };
    }),
  ] : [{
    type: 'other',
    title: t('Custom Link'),
    name: t('Custom Link'),
    subTitle: t('custom_links_sub'),
    defaultIcon: <RegularLinkIcon />,
    sectionId: importantLinksSection?.id,
  }];

  const filteredMonetizeLinks = monetizeLinks.filter((item: any)=> item.type !== 'sellProductGuide');

  // Sort the links based on the defined order
  const sortedMonetizeLinks = filteredMonetizeLinks.sort((a, b) => {
    return linkOrder.indexOf(a.type) - linkOrder.indexOf(b.type);
  });

  const handleAddLink = (item: ExploreLinkItem, editable: boolean) => {
    const link: LinksSectionItem = {
      id: Date.now().toString(),
      type: item?.type ?? 'other',
      url: item?.url ?? '',
      customUploadedImage: '',
      title: item?.name ?? '',
      linksSectionId: item?.sectionId ?? (importantLinksSection?.id || null),
      orderNumber: 0,
      hidden: false,
      isVisible: true,
      layout: 'new',
      startDate: item?.startDate,
      endDate: item?.endDate,
      contentType: item?.type == 'shortCall' ? SectionItemContentType.GOOGLE_CALENDAR : undefined,
      price: ['shortCall', 'other'].includes(item?.type) ? undefined : [{type: "default", value: "default", amount: undefined}],
    };
    onAddLink(link, editable);
    onClose({}, 'close');
  };
  return (
    <BaseModal {...props} onClose={onClose}>
      <div className={styles.head_div}>
        <div className={styles.head_title}>{t('connect_your_content')}</div>
        <div className={styles.head_subtitle}>{t('share_content_directly_on_your_Alfan_Link')}</div>
      </div>
      <div className={styles.box_body}>
        {sortedMonetizeLinks.map((item, index) => (
          <LinkCard key={`${item.type}-${index}`} item={item} onAddLink={(item) => handleAddLink(item, true)} />
        ))}
      </div>
    </BaseModal>
  );
};

export default AddLinkModal;
