import React, {useState} from 'react';
import styles from './ChargeCardModal.module.scss';
import LockIcon from '@material-ui/icons/Lock';
import Loader from 'react-loader-spinner';
import SellProductGuideDefault from '../../assets/images/addLink/monetize/access_exclusive_content_guide.svg';

interface IProps {
  theme?: any;
  monetizeObj: any;
  linksUser: any;
  getHeading: any;
}

const Preview = ({theme, monetizeObj}: IProps) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const imageSrc = monetizeObj.attachment?.url || monetizeObj?.attachment.thumbUrl || monetizeObj.customUploadedImage?.url || SellProductGuideDefault;

  return (
    <div className={styles.img_wrapper}>
      {/* Common Loader Handling */}
      {!imgLoaded && (
        <div className={styles.loaderContainer}>
          <Loader type="TailSpin" color="#EB3B5A" height={30} width={30} />
        </div>
      )}

      {imageSrc === monetizeObj.attachment.thumbUrl && imgLoaded && (
        <div className={styles.lock}>
          <div className={styles.lockBackground}>
            <LockIcon fontSize="large" />
          </div>
        </div>
      )}

      <img
        className={styles.attachment}
        src={imageSrc}
        onLoad={() => setImgLoaded(true)}
        onError={() => console.error('Image failed to load')}
        alt="Product thumbnail"
        style={{display: imgLoaded ? 'block' : 'none'}}
      />
    </div>
  );
};

export default Preview;
