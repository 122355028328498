import React, {useState, useEffect} from "react";
import buttonStyles from '../../../pages/TrackingLinks/TrackingLinksPage.module.scss';
import styles from './AddProductLink.module.scss';
import {Button, Box, Typography} from "@material-ui/core";
import {useUploadRequest} from "../../../features/API/request";
import {useTranslation} from 'react-i18next';
import OutlinedInput from "@material-ui/core/OutlinedInput";
import nonHookRequest from "../../../features/API/nonHookRequest";
import {toast} from 'react-toastify';
import {useDebouncedCallback} from "use-debounce";
import InputAdornment from "@material-ui/core/InputAdornment";
import Loader from 'react-loader-spinner';
import {PrimaryButton} from "../../Buttons/PrimaryButton";
import trackingStyles from '../AddTrackingLinkModal.module.scss';

interface AddProductLinkProps {
  productLink: string | null;
  setProductLink: React.Dispatch<React.SetStateAction<string | null>>;
  setProductTitle: React.Dispatch<React.SetStateAction<string | null>>;
  setProductLogo: React.Dispatch<React.SetStateAction<string | null>>;
  setStep: React.Dispatch<React.SetStateAction<number>>
}

export const AddProductLink = ({
  productLink,
  setProductLink,
  setProductTitle,
  setProductLogo,
  setStep,
}: AddProductLinkProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const {t} = useTranslation();

  const fetchTrackingLinkInfo = async () => {
    setIsLoading(true);
    const response = await nonHookRequest({method: 'GET', url: '/albumLinks/meta', params: {
      url: productLink,
    }});
    if (response) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(response, 'text/html');
  
      const ogTitleTag = doc.querySelector('meta[property="og:title"]');
      const ogImageTag = doc.querySelector('meta[property="og:image"]');
  
      const title = ogTitleTag?.getAttribute('content') || doc.title || '';
      const imageUrl = ogImageTag?.getAttribute('content') || '';
      setProductTitle(title);
      setProductLogo(imageUrl);
    };
    setStep(3);
    setIsLoading(false);
  };

  const isValidURL = (url: string) => {
    const regex = /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;
    return regex.test(url);
  };

  return (
    isLoading ? 
      <div className={styles.loaderWrapper}>
        <Loader type="TailSpin" color="#EB3B5A" height={60} width={60} />
        <h3 className={styles.loaderText}>{t('finding_product_info')} </h3>
      </div> :
      <div className={styles.container}>
        <h5>{t('add_from_link')} </h5>
        <p>{t('select_deal_to_add')} </p>

        <OutlinedInput
          className={styles.customInput}
          value={productLink}
          onChange={(e) => setProductLink(e.target.value)}
          endAdornment={isLoading ? 
            <InputAdornment position="start">
              <Loader type="TailSpin" color="#EB3B5A" height={20} width={20} />
            </InputAdornment> : 
          null}
          placeholder={t('add_url_link')} 
        />
        <span className={styles.heightSpan}></span>
        <div className={trackingStyles.addProductBtn}>
          <PrimaryButton 
            label={t('add_product_link')} 
            onClick={() => {
              if (isValidURL(productLink!)) {
                fetchTrackingLinkInfo();
              } else {
                toast.error(t('invalid_product_link'));
              }
            }}
            disabled={!productLink}
          />
        </div>
      </div>
  );
};
