import React, {useState, useEffect} from 'react';
import BaseModal from '../LinkModal/components/BaseModal';
import {BaseModalProps} from '../LinkModal/components/BaseModal';
import nonHookRequest from '../../features/API/nonHookRequest';
import {SelectDeal} from './steps/SelectDeal';
import {AddProductLink} from './steps/AddProductLink';
import {AddProductDetails} from './steps/AddProductDetails';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {raiseToast} from '../Toast/Toast';
import {ProductEditingInfoProps} from '../../pages/TrackingLinks/TrackingLinksPage';

type Props = BaseModalProps & {
  productEditingInfo: ProductEditingInfoProps;
  setProductEditingInfo: React.Dispatch<React.SetStateAction<ProductEditingInfoProps>>;
  setOffsetAndFetchLinks: () => void;
  setOffset: React.Dispatch<React.SetStateAction<number>>
  collections: any[]
  getProducts: () => Promise<void>
};

export interface DeletingProps {
  productId: string,
  setOpenDeleteConfirmationModal: (value: React.SetStateAction<boolean>) => void
}

const AddTrackingLinkModal = ({
  productEditingInfo,
  setProductEditingInfo,
  setOffsetAndFetchLinks, 
  setOffset, 
  onClose,
  collections,
  getProducts,
  ...props
}: Props) => {
  const [step, setStep] = useState(1);
  const [productLink, setProductLink] = useState<string | null>(null);
  const [productTitle, setProductTitle] = useState<string | null>(null);
  const [productLogo, setProductLogo] = useState<string | null>(null);
  const [userDealId, setUserDealId] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(true);
  const [collectionIds, setCollectionIds] = useState<string[]>([]);

  useEffect(() => {
    setStep(productEditingInfo?.id ? 3 : 1);
    setProductLink(productEditingInfo?.productUrl || null);
    setProductTitle(productEditingInfo?.title || null);
    setProductLogo(productEditingInfo?.logoUrl || null);
  }, [productEditingInfo]);

  const PaperProps= {
    style: {
      width: '80%',
      maxWidth: '900px',
      height: step === 1 ? '700px' : step === 2 ? '300px' : '600px',
    },
  };

  const {t} = useTranslation();

  const resetForm = () => {
    setStep(1);
    setProductTitle(null);
    setProductLink(null);
    setProductLogo(null);
    setUserDealId(null);
    setProductEditingInfo({});
  };

  const handleAddingProduct = async () => {
    let response;
    if (productEditingInfo?.id) {
      response = await nonHookRequest({
        url: `/brandshub/api/product/${productEditingInfo?.id}`,
        method: 'PUT',
        body: {
          title: productTitle,
          logoUrl: productLogo,
          visible: isVisible,
          collectionIds,
        },
        isShortUrl: true,
        filterError: true,
      });
    } else {
      response = await nonHookRequest({
        url: `/brandshub/api/product`,
        method: 'POST',
        body: {
          productUrl: productLink, 
          title: productTitle, 
          logoUrl: productLogo,
          userDealId: userDealId,
          visible: isVisible,
          collectionIds,
        },
        isShortUrl: true,
        filterError: true,
      });
    }

    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: t('tracking_link_added')});
      setOffsetAndFetchLinks();
      getProducts();
      onClose({}, 'close');
      resetForm();
    };
  };

  const handleDeletingProduct = async ({
    productId, 
    setOpenDeleteConfirmationModal,
  }: DeletingProps) => {
    const response = await nonHookRequest({
      method: 'DELETE',
      url: `/brandshub/api/product/${productId}`,
      isShortUrl: true,
      filterError: true,
    });

    if (response?.hasError) {
      toast.error(response?.error?.message);
    } else {
      raiseToast({message: t('product_deleted')});
      setOffsetAndFetchLinks();
      getProducts();
      setOpenDeleteConfirmationModal(false);
      onClose({}, 'close');
    };
  };
    
  return (
    <BaseModal {...props} PaperProps={PaperProps} onClose={() => {
      onClose({}, 'close');
      resetForm();
    }}>
      {step === 1 && 
        <SelectDeal
          userDealId={userDealId}
          setUserDealId={setUserDealId}
          setStep={setStep}
        />
      }
      {step === 2 && 
        <AddProductLink 
          productLink={productLink}
          setProductLink={setProductLink}
          setProductTitle={setProductTitle}
          setProductLogo={setProductLogo}
          setStep={setStep}
        />
      }
      {step === 3 && 
        <AddProductDetails 
          handleAddingProduct={handleAddingProduct}
          handleDeletingProduct={handleDeletingProduct}
          productTitle={productTitle}
          setProductTitle={setProductTitle}
          productLink={productLink}
          setProductLink={setProductLink}
          productLogo={productLogo}
          setProductLogo={setProductLogo}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          collectionIds={collectionIds}
          setCollectionIds={setCollectionIds}
          collections={collections}
          isEditing={!!productEditingInfo?.id}
          productId={productEditingInfo?.id}
          onClose={() => onClose({}, 'close')}
        />
      }
    </BaseModal>
  );
};

export default AddTrackingLinkModal;
