import React, {
  useEffect,
  useState,
  useMemo,
  lazy,
} from 'react';
import {useDispatch} from 'react-redux';
import {setShouldSave} from '../../features/stores/changeDataSlicer';
import {fetchUsersDeals} from '../../features/Login/login';
import useWindowWidth from '../../hooks/useWindowWidth';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {removeStepCookie} from '../../Helper/generalHelper';
import {setAppliedDeals, setDeals} from '../../features/stores/dealsSlicer';
import {
  setPreviewMode,
} from '../../features/stores/previewModeSlicer';
import {isRtl} from './../../features/util';
import styles from './DealsPage.module.scss';
import DealsContainer from '../../components/Deals/DealsContainer';
import {Select} from "antd";
import nonHookRequest from '../../features/API/nonHookRequest';
import AvailableDeals from '../../components/Deals/AvailableDeals';
import AppliedDeals from '../../components/Deals/AppliedDeals';
import ActiveDeals from '../../components/Deals/ActiveDeals';
import {useDebouncedCallback} from 'use-debounce';
import searchStyles from '../../components/Deals/Deals.module.scss';

const MyProfileItem = lazy(
    () => import('../../components/MyProfileItems/MyProfileItem'),
);

export enum dealsStatus {
  AVAILABLE = "Available",
  APPLIED = "Applied",
  ACTIVE = "Approved",
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  width: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, width, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={width < 1024 ? 1 : 3} pr={width < 1024 ? 1 : 3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const DealsPage: React.FC = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const [activeDealsTabIndex, setActiveDealsTabIndex] = useState(() => {
    const savedTab = localStorage.getItem('activeDealsTabIndex');
    return savedTab ? parseInt(savedTab, 10) : 0;
  });
  const [availableDeals, setAvailableDeals] = useState<any>([]);
  const [appliedDeals, setAppliedDeals] = useState<any>([]);
  const [activeDeals, setActiveDeals] = useState<any>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const width = useWindowWidth();
  const {Option} = Select;

  useEffect(() => {
    handleGettingDeals(dealsStatus.AVAILABLE);
    handleGettingDeals(dealsStatus.APPLIED);
    handleGettingDeals(dealsStatus.ACTIVE);
  }, []);

  const handleGettingDeals = async (status: dealsStatus, keyword = "") => {
    setLoading(true);
    const response = await nonHookRequest({method: 'GET', url: `/deals?status=${status}&search=${keyword}`});

    switch (status) {
      case dealsStatus.AVAILABLE:
        setAvailableDeals(response);
        break;

      case dealsStatus.APPLIED:
        setAppliedDeals(response);
        break;

      case dealsStatus.ACTIVE:
        setActiveDeals(response);
        break;
    }
    setLoading(false);
  };

  const SectionList = [{
      id: '0',
      key: 'deals',
      name: t('available_deals'),
      isAccordion: false,
      content: <AvailableDeals availableDeals={availableDeals} handleGettingDeals={handleGettingDeals} loading={loading} />,
      isVisible: true,
    },
    {
      id: '1',
      key: 'appliedDeals',
      name: t('applied_deals'),
      isAccordion: false,
      content: <AppliedDeals appliedDeals={appliedDeals} handleGettingDeals={handleGettingDeals} loading={loading} />,
      isVisible: true,
    },
    {
      id: '2',
      key: 'activeDeals',
      name: t('active_deals'),
      isAccordion: false,
      content: <ActiveDeals activeDeals={activeDeals} handleGettingDeals={handleGettingDeals} loading={loading} />,
      isVisible: true,
    }];

  useEffect(() => {
    dispatch(setPreviewMode(false));
    dispatch(setShouldSave(false));
    removeStepCookie();
  }, [dispatch]);

  const handleChange = (event: string) => {
    setActiveDealsTabIndex(parseInt(event));
    localStorage.setItem('activeDealsTabIndex', event);
    setKeyword("");
  };

  const handleSearch = useDebouncedCallback((e) => {
    // TODO: revisit and change activeDealsTabIndex to activeDealsTab
    if (activeDealsTabIndex === 0) handleGettingDeals(dealsStatus.AVAILABLE, e.target.value);
    if (activeDealsTabIndex === 1) handleGettingDeals(dealsStatus.APPLIED, e.target.value);
    if (activeDealsTabIndex === 2) handleGettingDeals(dealsStatus.ACTIVE, e.target.value);
  }, 1000);

  return (
    <DealsContainer screen="deals">
      <div className={styles.new_link_wrapper}>
        <input
          className={styles.new_link_input}
          value={keyword}
          onChange={(e) => {
            setKeyword(e.target.value);
            handleSearch(e);
          }}
          placeholder={t('search')}
        />

        <div className={styles.customSelect}>
          <Select
            defaultValue={activeDealsTabIndex.toString()}
            onChange={handleChange}
            className={styles.customSelector}
          >
            {SectionList.map((section, index) => {
              return <Option key={index} value={section?.id}>{section.name}</Option>;
            })}
          </Select>
        </div>
      </div>

      {SectionList.map((section, index) => {
        return (
          <div key={section.key} className={styles.section_container}>
            {section.isVisible &&
              <TabPanel width={width} value={activeDealsTabIndex} index={index} key={`${section.key}-item`} dir={isRtl(i18n.language) ? 'rtl' : 'ltr'}>
                <MyProfileItem
                  name=""
                  id={section.id}
                  subtitle=""
                  content={section.content}
                  isAccordion={section.isAccordion}
                  isVisible={section.isVisible}
                  editableTitle={section.key === 'featuredVideos'}
                />
              </TabPanel>
            }
          </div>
        );
      })}
    </DealsContainer>
  );
};

export default DealsPage;
