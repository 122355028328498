import React, { useState } from 'react';
import styles from './ReferralBanner.module.scss';
import { ReactComponent as RewardIcon } from '../../assets/images/Version3/icons/Group 334.svg';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReferralModal from '../ReferralModal/ReferralModal';
import { useTranslation } from 'react-i18next';
import { isArabic } from '../../features/util';

const ReferralBanner = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={styles.banner} onClick={handleOpenModal}>
        <div className={styles.content}>
          <div className={styles.icon}>
            <RewardIcon />
          </div>
          <div className={styles.text}>{t("share_and_get_rewarded")}</div>
        </div>
        {isArabic(i18n.language) ? (
          <ArrowBackIosIcon className={styles.arrowIcon} />
        ) : (
          <ArrowForwardIosIcon className={styles.arrowIcon} />
        )}
      </div>

      {isModalOpen && <ReferralModal onClose={handleCloseModal} />}
    </>
  );
};

export default ReferralBanner;
