import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {store} from '../../app/store';
import CustomTheme from '../../components/CustomTheme/CustomThemeV2';
import MobilePreview from '../../components/MobilePreview/MobilePreview';
import MyProfileItem from '../../components/MyProfileItems/MyProfileItem';
import {themes} from '../../constants/newThemes';
import {setShouldSave} from '../../features/stores/changeDataSlicer';
import useWindowWidth from '../../hooks/useWindowWidth';
import styles from './Design.module.scss';
import {useTranslation} from 'react-i18next';

interface DesignProps {
  handleSave: () => void;
}

const Design: React.FC<DesignProps> = ({handleSave}) => {
  const {t} = useTranslation();
  const [selectedBackgroundIndex, setSelectedBackgroundIndex] = useState<number>(-1);
  const [selectedFontIndex, setSelectedFontIndex] = useState<number>(-1);
  const width = useWindowWidth();

  const handleBackgroundLeft = () => {
    if (selectedBackgroundIndex !== -1) {
      setSelectedBackgroundIndex(selectedBackgroundIndex - 1);
    }
    const el = document.getElementById('selectedBackground');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  const handleBackgroundRight = () => {
    if (selectedBackgroundIndex < themes.length - 1) {
      setSelectedBackgroundIndex(selectedBackgroundIndex + 1);
    }
    const el = document.getElementById('selectedBackground');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  const handleFontLeft = () => {
    if (selectedFontIndex !== -1) {
      setSelectedFontIndex(selectedFontIndex - 1);
    }
    const el = document.getElementById('selectedFont');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  const handleFontRight = () => {
    if (selectedFontIndex < themes.length - 1) {
      setSelectedFontIndex(selectedFontIndex + 1);
    }
    const el = document.getElementById('selectedFont');
    if (el) {
      el.scrollIntoView({inline: 'center', block: 'nearest', behavior: 'smooth'});
    }
  };

  useEffect(() => {
    store.dispatch(setShouldSave(false)); // clear state
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.profile_items_container}>
          <CustomTheme
            handleBackgroundLeft={handleBackgroundLeft}
            handleBackgroundRight={handleBackgroundRight}
            setBackgroundIndex={(e: number) => setSelectedBackgroundIndex(e)}
            backgroundIndex={selectedBackgroundIndex}
            handleFontLeft={handleFontLeft}
            handleFontRight={handleFontRight}
            setFontIndex={(e: number) => setSelectedFontIndex(e)}
            fontIndex={selectedFontIndex}
            handleSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

Design.propTypes = {
  handleSave: PropTypes.func.isRequired,
};

export default Design;
