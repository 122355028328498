/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './AvailabilityModal.module.scss';

interface CustomDropdownProps {
  onDaySelect: (day: string) => void;
  options: string[];
  placeholder: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ onDaySelect, options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleDayClick = (day: string) => {
    onDaySelect(day); // Pass selected day back to parent
    setIsOpen(false); // Close dropdown after selection
  };

  return (
    <div className={styles.dropdownContainer}>
      <div className={styles.dropdownHeader} onClick={toggleDropdown}>
        {placeholder}
        <span className={styles.dropdownArrow}>▼</span>
      </div>
      {isOpen && (
        <div className={styles.dropdownList}>
          {options.map((option) => (
            <div
              key={option}
              className={styles.dropdownItem}
              onClick={() => handleDayClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
