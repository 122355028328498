import React from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from 'react-beautiful-dnd';
import styles from './DraggableList.module.scss';

type DraggableListProps<T> = {
  items: T[];
  renderItem: (item: T, provided: DraggableProvided) => React.ReactElement;
  onDragEnd: (reorderedItems: T[]) => void;
  isTwoColumnLayout: boolean;
};

const DraggableList = <T, >({ items, renderItem, onDragEnd, isTwoColumnLayout }: DraggableListProps<T>) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    onDragEnd(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="draggable-list" direction={isTwoColumnLayout ? 'horizontal' : 'vertical'}>
        {(provided) => (
          <div
            className={`${styles.listContainer} ${isTwoColumnLayout ? styles.twoColumn : styles.oneColumn}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    {renderItem(item, provided)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
