import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreatorProfile.module.scss';
import { contentOptions, creatorTypeOptions, contentOptionsProd, creatorTypeOptionsProd, contentOptionsNewOnBoardingProd, contentOptionsNewOnBoarding, MAX_CONTENT_CATEGORY_SELECTION } from '../../constants/helper';
import { useSelector } from 'react-redux';
import { selectLinksUser, setLinksUser } from '../../features/stores/linksUserSlicer';
import { store } from '../../app/store';
import { Checkbox } from '@material-ui/core';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import { setDataChange } from '../../features/stores/changeDataSlicer';
import PhoneNumber from '../PhoneNumber/PhoneNumber';
import SelectGender from '../SelectGender/SelectGender';
import { isProdSite } from '../../Helper/generalHelper';
import { toast } from 'react-toastify';

const CreatorProfile: React.FC = ()=> {
  const { t, i18n } = useTranslation();
  const isProd = isProdSite();
  const linksUser = useSelector(selectLinksUser);
  const showBioAndName = window.location.pathname !== '/profile/setting/personal-information' && window.location.pathname !== '/profile/settings' && window.location.pathname !== '/profile/settings/*';
  const contentOptionsLocal = isProd ? contentOptionsNewOnBoardingProd : contentOptionsNewOnBoarding;
  const inactiveColor = '#AA9C9F';
  const activeColor = '#000';

  const handleSelect = (value: string) => {
    const selectedCategories = [...(linksUser.contentCategoryIds || [])];

    if (selectedCategories.includes(value)) {
      selectedCategories.splice(selectedCategories.indexOf(value), 1); // Deselect if already selected
    } else if (selectedCategories.length < MAX_CONTENT_CATEGORY_SELECTION) {
      selectedCategories.push(value); // Select new item if under max limit
    } else {
      toast(<span style={{ color: 'black' }}>{t('you_can_select_max_three')}</span>, { autoClose: 5000 });
      return;
    }

    store.dispatch(setLinksUser({ ...linksUser, contentCategoryIds: selectedCategories }));
  };

  return (
    <div className={showBioAndName ? '' : styles.profile_body}>
      <div>
        <div className={styles.heading}>{t('i_make_content_related_to')}</div>
        <div className={styles.content_realted}>
          {contentOptionsLocal.map((e: any) => (
            <div
              onClick={() => handleSelect(e.id)}
              key={e.id}
              className={`${styles.content_item} ${linksUser.contentCategoryIds?.includes(e.id) ? styles.active : ''}`}
            >
              <div className={styles.content_text}>
                <e.icon fill={linksUser.contentCategoryIds?.includes(e.id) ? '#E887F9' : '#AA9C9F'} />
                {t(e.name)}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showBioAndName && <>
        <div className={styles.margin_28_top}>
          <div className={styles.heading}>{t('display_name_label')}</div>
          <TextFieldInput
            placeholder={t('display_name_placeholder')}
            value={linksUser.fullname as string}
            customStyle={{ height: '56px', padding: '0px 12px' }}
            onChange={(e: any) => {
              store.dispatch(setLinksUser({ ...linksUser, fullname: e.target.value }));
              store.dispatch(setDataChange(true));
            }}
          />
        </div>
      </>}
      {/* {!showBioAndName && <>
        <div className={styles.margin_28_top}>
          <div>
            <PhoneNumber value={linksUser?.phone} country={'ae'} onChange={(v, c: {dialCode: string}, e) => {
              const phone = v[0] == '+' ? v.replace(/ /g, '') : '+' + v.replace(/ /g, '');
              const dialCode = '+' + c?.dialCode;
              let value = '';
              if (phone.substring(0, dialCode?.length) == dialCode) {
                value = phone;
              } else {
                value = `${dialCode}${v}`;
              }
              store.dispatch(setLinksUser({ ...linksUser, phone: value }));
              store.dispatch(setDataChange(true));
            }} />
          </div></div>
        <div className={styles.margin_28_top}>
          <div>
            <SelectGender value={linksUser?.gender || ''} handleChange={(e: string)=>{
              store.dispatch(setLinksUser({ ...linksUser, gender: e }));
              store.dispatch(setDataChange(true));
            }} />
          </div></div><br /></>} */}
    </div>
  );
};

export default CreatorProfile;
