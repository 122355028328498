/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../ChargeCardModal/ChargeCardModal.module.scss';
import { theme } from '../../constants/Theme2024';

interface CheckboxFieldProps {
  checked: boolean;
  onChange: () => void;
  label: React.ReactNode;
}

// Custom Checkbox with Pink Color
const PinkCheckbox = withStyles({
  root: {
    color: theme.colors.icon.pink, // Default color for the checkbox
    '&$checked': {
      color: theme.colors.icon.pink, // Color for the tick when checked
    },
  },
  checked: {}, // This is required to apply the custom checked color
})(Checkbox);

const CheckboxField: React.FC<CheckboxFieldProps> = ({ checked, onChange, label }) => (
  <div className={styles.terms}>
    <PinkCheckbox
      checked={checked}
      onChange={onChange}
      name="checkedAccept"
    />
    <div className={styles.title}>{label}</div>
  </div>
);

export default CheckboxField;
