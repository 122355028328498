/* eslint-disable react/prop-types */
import React from 'react';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import TimeSlotSelect from './TimeSlotSelect';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import styles from './AvailabilityModal.module.scss';
import { DAY_NAME_MAP } from '../../constants/helper';

interface Slot {
  startTime: string;
  endTime: string;
}

interface DaySectionProps {
  day: string;
  slots: Slot[];
  activeDay: string[];
  onAddSlot: () => void;
  onRemoveSlot: (index: number) => void;
  handleSelectChange: (event: React.ChangeEvent<{ value: unknown }>, index: number, field: 'startTime' | 'endTime') => void;
  validateSlots: (index: number) => string | null;
  clearDay: (day: string) => void;
}

const DaySection: React.FC<DaySectionProps> = ({
  day,
  slots,
  activeDay,
  onAddSlot,
  onRemoveSlot,
  handleSelectChange,
  validateSlots,
  clearDay,
}) => {
  return (
    <div className={styles.schedule_day_section}>
      <div className={styles.day_header}>
        <h3>{DAY_NAME_MAP[day] || day}</h3>
        <button className={styles.clear_day_button} onClick={() => clearDay(day)}>
          Clear Day
        </button>
      </div>
      <div className={styles.slots_container}>
        {slots.length > 0 ? (
          slots.map((slot, index) => (
            <div className={styles.slots_single} key={`slot-${day}-${index}`}>
              <div className={styles.slots_single_box}>
                <div className={styles.from_to_container}>
                  <TimeSlotSelect
                    value={slot.startTime}
                    label="From"
                    disabled={!activeDay.includes(day)}
                    onChange={(e) => handleSelectChange(e, index, 'startTime')}
                  />
                  <TimeSlotSelect
                    value={slot.endTime}
                    label="To"
                    disabled={!activeDay.includes(day)}
                    onChange={(e) => handleSelectChange(e, index, 'endTime')}
                  />
                </div>
                {index > 0 && (
                  <div className={`${styles.action} ${!activeDay.includes(day) ? styles.disable_click : ''}`} onClick={() => onRemoveSlot(index)}>
                    <HighlightOffIcon />
                  </div>
                )}
              </div>
              {/* Validation messages */}
              {validateSlots(index) && <div className={styles.slot_error}>{validateSlots(index)}</div>}
            </div>
          ))
        ) : (
          <div>No slots available</div>
        )}
        <div className={styles.add_button_container}>
          <PrimaryButton
            label="Add more"
            color="black"
            onClick={onAddSlot}
            style={{ padding: '8px 26px', fontSize: '13px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default DaySection;
