/* eslint-disable react/prop-types */
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styles from "./CountrySelect.module.scss";
import {useSelector} from "react-redux";
import {selectLinksUser, setLinksUser} from "../../features/stores/linksUserSlicer";
import {store} from "../../app/store";
import {setDataChange} from "../../features/stores/changeDataSlicer";
import {CountryRegionData} from 'react-country-region-selector';
import SelectWithSearch from "../SelectWithSearch/SelectWithSearch";


type Props = {
  isOnboarding?: boolean;
};

const CountrySelect: React.FC<Props> = ({ isOnboarding = false }) => {
  const {t, i18n} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const [selectedCountries, setSelectedCountries] = useState(linksUser?.location || '');
  return (
    <div className={styles.content}>
      <SelectWithSearch value={selectedCountries || ''}
          onChange={(val: any) => {
            if (val?.target?.value) {
              setSelectedCountries(val?.target?.value);
              store.dispatch(setLinksUser({...linksUser, location: val?.target?.value ?? '', city: '', 
              ...(isOnboarding ? { phone: null } : {}) }));
              store.dispatch(setDataChange(true));
            }
          }}
          label={t('country')} 
          options={CountryRegionData?.map((e) => ({
            display: t(`country_names.${e[0]}`, { defaultValue: e[0] }),
            value: e[0],
          }))}
          />
    </div>
  );
};

export default CountrySelect;
