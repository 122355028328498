export const theme = {
  colors: {
    text: {
      grey: '#756067',
      black: '#21000E',
      greyLight: '#AA9C9F',
      red: '#FF002B',
      redLight: '#E887F9',
    },
    icon: {
      dark: '#21000E',
      grey: '#F4F0ED',
      pink: '#E887F9',
      primary: '#FF002B',
    },
    button: {
      secondaryStroke: '#21000E',
      primaryStroke: '#FF002B',
      secondarySurface: '#FFFFFF',
      primarySurface: '#FF002B',
      secondaryText: '#21000E',
      secondaryIcon: '#21000E',
      primaryText: '#21000E',
      primaryIcon: '#21000E',
    },
    stroke: {
      light: '#EDE8E3',
      grey: '#CAC0C0',
      dark: '#21000E',
      secondary: '#E887F9',
      primary: '#FF002B',
    },
    surface: {
      dark: '#21000E',
      greyLight: '#F9F7F6',
      grey: '#F4F0ED',
      lightPrimary: '#FCEDEF',
      primary: '#FF002B',
      pink: '#E887F9',
      pinkLight: '#F2DBEF',
      graph: 'rgba(232, 135, 249, 0.15)',
    },
    state: {
      error: '#FF002B',
      success: '#39BA5D',
      successSurface: '#D9F3E1',
      warning: '#FFC41D',
    },
  },
};
