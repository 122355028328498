/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './DigitalProductItem.module.scss';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { ReactComponent as DragHandleIcon } from '../../assets/images/Version3/icons/dragIcon.svg';

type PriceType = {
  type: string;
  value: string;
  amount: number;
};

type DigitalProductItemProps = {
  visibility: boolean;
  title: string;
  subtitle?: string;
  contentType?: string;
  price: PriceType[];
  icon?: React.ReactNode;
  onClick: () => void;
};

const DigitalProductItem: React.FC<DigitalProductItemProps> = ({
  visibility,
  title,
  subtitle,
  contentType,
  price,
  icon,
  onClick,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  // Extract and format the first price if available
  const formattedPrice =
  price.length > 0
    ? `$${price[0].amount / 100}`
    : t('Price not available');

  const renderSubtitle = (text: string, maxLength = 60) => {
    if (!text) return null;

    const stripHtmlTags = (html: string) => {
      const div = document.createElement('div');
      div.innerHTML = html; // Parse the HTML string
      return div.textContent || div.innerText || ''; // Extract plain text
    };

    const plainText = stripHtmlTags(text);
    const shouldTruncate = plainText.length > maxLength;

    return (
      <div className={styles.subtitleWrapper}>
        <span>
          {isExpanded || !shouldTruncate
                ? plainText
                : `${plainText.substring(0, maxLength)}...`}
        </span>
        {shouldTruncate && (
          <button
            className={styles.toggleButton}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {t(isExpanded ? 'Show Less' : 'Show More')}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.cardContainer}>
        <div className={styles.imageContainer}>
          {icon || (
            <div className={styles.placeholderIcon}>{t('No Image')}</div>
          )}
        </div>
        <div className={styles.content}>
          <span className={styles.category}>
            {contentType ? t(contentType) : t('Guide')}
          </span>
          <h3 className={styles.title}>{title}</h3>
          {subtitle && (
            <div className={styles.subtitle}>{renderSubtitle(subtitle, 30)}</div>
          )}
          <div className={styles.price_edit_section}>
            <p className={styles.price}>
              {t('price')}:{' '}
              <span className={styles.priceValue}>{formattedPrice}</span>
            </p>
            <PrimaryButton
              label={t('Edit')}
              onClick={onClick}
              color="black"
              style={{ minWidth: '80px' }}
            />
          </div>
        </div>
      </div>
      <div className={styles.dragHandle}>
        <DragHandleIcon />
      </div>
    </div>
  );
};

export default DigitalProductItem;
