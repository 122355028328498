import React, {useState, useEffect, useMemo} from 'react';
import styles from './Availability.module.scss';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import Loader from 'react-loader-spinner';
import nonHookRequest from '../../../features/API/nonHookRequest';
import {toast} from 'react-toastify';
import Calendar from './../../../assets/images/versionV2/monetize/googleCalendar.svg';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {raiseToast} from '../../Toast/Toast';
import {getGoogleCalender} from '../../../features/stores/googleCalendarSlicer';
import {fetchGoogleCalendarConnection} from '../../../features/Login/login';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";


const GoogleCalendarAuth = () => {
  const {t, i18n} = useTranslation();
  const calendarConnected = useSelector(getGoogleCalender).connected || false;
  const [calendarUrlLoading, setCalendarUrlLoading] = useState(false);

  const getGoogleCalendarUrl = async () => {
    try {
      setCalendarUrlLoading(true);
      const calenderUrlResponse = await nonHookRequest({method: 'GET', url: '/google-oauth?service=calendar'});
      openCalendarAuth(calenderUrlResponse);
      setCalendarUrlLoading(false);
    } catch (error) {
      setCalendarUrlLoading(false);
    }
  };

  const openCalendarAuth = (calendarUrl: string) => {
    window.location.href = calendarUrl;
  };

  const handleAuthComplete = async () => {
      if (window?.location?.pathname?.includes('/mobile')) {
        // this check is implemented for mobile OAuth handling using deep-linking
        return;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const service = urlParams.get('service');
      const code = urlParams.get('calendar-code') || urlParams.get("code");
      const state = urlParams.get('calendar-state') || urlParams.get('state');
      const error = urlParams.get('calendar-error') || urlParams.get('error');
      const url = `${window.location.pathname}`;
      if (service == 'calendar' && code && state) {
        try {
          setCalendarUrlLoading(true);
          const calenderAuthResponse = await nonHookRequest({method: 'PUT', url: '/google-oauth', body: {state, code}, filterError: true});
          if (calenderAuthResponse?.hasError) {
            toast.error(<span style={{color: 'white'}}>{calenderAuthResponse?.error?.message || t('something_went_wrong')}</span>);
            window.history.pushState({}, document.title, url);
          } else {
            window.history.pushState({}, document.title, url);
            raiseToast({message: t('calendar_connected')});
            fetchGoogleCalendarConnection();
          }
          setCalendarUrlLoading(false);
        } catch (error) {
          setCalendarUrlLoading(false);
        }
      } else if (error != null) {
        toast.error(<span style={{color: 'white'}}>{error || t('something_went_wrong')}</span>);
        window.history.pushState({}, document.title, url);
      }
  };

  useEffect(() => {
    handleAuthComplete();
  }, []);

  return (
      <div className={styles.google_container}>
        <div className={styles.title}>{t('calendar')}</div>
        <div className={styles.sub_title}>{t('sync_your_personal_and_work_calendar')}</div>
        <div className={styles.google_row} onClick={!calendarUrlLoading && !calendarConnected ? getGoogleCalendarUrl : () => {}}>
  {calendarUrlLoading ? (
    <div className={styles.loader_box}>
      <Loader type="TailSpin" height={20} width={20} />
      <div>{t("processing_connection_request")}</div>
    </div>
  ) : (
    <>
      <div className={styles.google_container_left}>
        <div className={styles.google_img}>
          <img src={Calendar} alt="Google Calendar Icon" />
        </div>
        <div>{t("google_calendar")}</div>
      </div>
      <div className={styles.google_container_right}>
        {calendarConnected && (
          <span className={styles.connected_status}>{t("connected")}</span>
        )}
        {i18n.language === "ar" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </div>
    </>
  )}
</div>
      </div>
  );
};

export default GoogleCalendarAuth;
