import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LinksSectionItem, selectLinksUser} from '../../../features/stores/linksUserSlicer';
import BaseModal from '../components/BaseModal';
import DeleteButton from '../components/DeleteButton';
import EditableTextInput from '../components/EditableTextInput';
import ToggleButton from '../components/ToggleButton';
import LinkModal, {LinkModalProps} from '../LinkModal';
import currencies from '../../../constants/currencies';
import Select from '../components/Select';
import {MonetizeType, initialLink} from './types';
import PriceBreakdown from '../components/PriceBreakdown';
import UploadButton from '../components/UploadButton';
import PriceInput, {useMonetizePrice} from '../components/PriceInput';
import Alert from '@material-ui/lab/Alert';
import EditableImageInputGuide from '../components/EditableImageInputGuide';
import RichTextEditor from '../components/RichTextEditor';
import SellProductGuideDefault from '../../../assets/images/addLink/monetize/access_exclusive_content_guide.svg';
import { PrimaryButton } from '../../Buttons/PrimaryButton';
import { useSelector } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import { digitalTypes } from '../../../constants/socialMedia';


const DEFAULT_CURRENCY = 'usd';

const SellProductGuideModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();
  const {validate: validatePrice} = useMonetizePrice(link.currency, link.type as MonetizeType);
  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const maxCharacters = 500;
  const digitalSection = linksUserSections.find(
      (section) => section.type === 'digital',
  );

  useEffect(() => {
    setLink({
      ...defaultLink,
      currency: defaultLink.currency || DEFAULT_CURRENCY,
      linksSectionId: digitalSection?.id as string,
      contentType: defaultLink.contentType || digitalTypes[1],
      isMonitize: true,
      attachment: {...{
        id: '',
        url: SellProductGuideDefault,
        name: 'default-image',
      }, ...defaultLink?.attachment},
    });
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length <= 50) {
      setLink((prevState) => ({...prevState, title}));
    }
  };

  const handleLinkBreakdown = (index: number, data: any) => {
    const linkCopy = JSON.parse(JSON.stringify(link));
    linkCopy.price[index] = {...linkCopy.price[index], ...data};
    setLink(linkCopy);
  };

  const handleRichTextChange = (content: string) => {
    const plainText = content.replace(/<[^>]*>/g, '');
    if (plainText.length > maxCharacters) {
      // Trim content to the maximum allowed length
      const trimmedContent = plainText.slice(0, maxCharacters);
      setLink((prevLink) => ({
        ...prevLink,
        note: trimmedContent,
      }));
    } else {
      // Content is within limit, so we can safely update it
      setLink((prevLink) => ({
        ...prevLink,
        note: content,
      }));
    }
  };
  
  const validNote = link.note && link.note.replace(/<[^>]*>/g, '').length > maxCharacters;
  const isValid = useMemo(() => {
    return (
      link.title?.length &&
      link.price &&
      !validatePrice(link.price) &&
      link.currency &&
      link.attachment &&
      link.attachmentURL &&
      !validNote
    );
  }, [link]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t("add_title")}
        disabled={!editable}
      />

      <EditableImageInputGuide
        value={link.customUploadedImage || ""}
        type={link.type}
        id={link.id}
        onChange={(image) => {
          // Update link icon image
          setLink((prevLink) => ({
            ...prevLink,
            customUploadedImage: image,
          }));
        }}
        onUploadComplete={(file) => {
          // Update cover image
          setLink((prevLink) => ({
            ...prevLink,
            attachment: file,
          }));
        }}
        editable={editable}
      />

      {!editable && (
        <Alert style={{ fontWeight: 500, fontSize: "1rem" }} severity="warning">
          {t("monetization_is_disabled_for_your_account")}
        </Alert>
      )}

      <UploadButton
        contentType={link.contentType}
        url={link.attachmentURL}
        onUpload={({ url }) =>
          setLink((prevState) => ({ ...prevState, attachmentURL: url }))
        }
        label="Guide"
        onDelete={() =>
          setLink((prevState) => ({ ...prevState, attachmentURL: "" }))
        }
        editable={editable && mode === "add"}
      />
      <div></div>

      <Select
        value={link.contentType}
        onChange={(e) =>
          setLink((prevState) => ({
            ...prevState,
            contentType: e.target.value as string,
          }))
        }
        label={t("content_type")}
        disabled={!editable} // Only disable based on editable status
      >
        {digitalTypes.map((type) => (
          <MenuItem key={type} value={type}>
            {t(type)}
          </MenuItem>
        ))}
      </Select>

      <Select
        value={link.currency}
        onChange={(e) =>
          setLink((prevState) => ({
            ...prevState,
            currency: e.target.value as string,
          }))
        }
        disabled={!editable}
        label={t("currency")}
      >
        {Object.keys(currencies).map((key) => (
          <Select.Item key={key} value={key}>
            {key.toUpperCase()}
          </Select.Item>
        ))}
      </Select>

      <PriceInput
        link={link}
        onChange={(amount) => handleLinkBreakdown(0, { amount })}
        editable={editable}
      />

      <RichTextEditor
        value={link.note || ""}
        onChange={handleRichTextChange}
        disabled={!editable}
        label={t("give_your_buyer_a_small_teaser")}
        maxCharacters={maxCharacters}
      />

      <PriceBreakdown link={link} />

      <LinkModal.EditControlsContainer>

        {mode === "edit" && (
          <>
            <ToggleButton
              checked={link.isVisible}
              onChange={(checked) =>
                setLink((prevState) => ({ ...prevState, isVisible: checked }))
              }
              label={t("visibility")}
            />
            <DeleteButton onClick={() => onDelete(link.id)}>
              {t("delete")}
            </DeleteButton>
          </>

        )}
      </LinkModal.EditControlsContainer>

      <PrimaryButton
        label={t(mode === 'edit' ? 'save' : 'add')}
        onClick={() => onSave(link)}
        disabled={!isValid}
        color="red"
        style={{ width: "100%", margin: "auto", padding: "10px 0px" }}
      />
    </BaseModal>
  );
};

export default SellProductGuideModal;
