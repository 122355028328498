import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState, store} from '../../app/store';
import {commissionType, dealCategory} from '../../constants/helper';

export interface collection {
    id: string;
}

export interface CollectionsState {
  data: any[];
  collections: any[];
}

const initialState: CollectionsState = {
  data: [],
  collections: [],
};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setCollections: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    'user/setMe': (state, {payload}) => {
      if (payload.me?.userDeals) {
        const collections = payload.me?.collections;
        state.collections = collections || [];
      }
    },
  },
});

export const {setCollections} = collectionsSlice.actions;

export const getCollectionsStore = (state: RootState) => state.collections.collections;

export default collectionsSlice.reducer;
