/* eslint-disable require-jsdoc */
/* eslint new-cap: ["error", { "capIsNewExceptionPattern": "^window\\.." }] */
import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useAppSelector} from './app/hooks';
import LoggedSwitch from './features/Login/loggedSwitch';
import PublicSwitch from './features/Login/publicSwitch';
import {store} from './app/store';
import './style/global.module.scss';
import {selectMe} from './features/stores/userSlicer';
import {checkLogin, fetchMe, fetchConfig, fetchMusicLinks, guestView, fetchWalletStore, fetchUserSchedule, fetchGoogleCalendarConnection} from './features/Login/login';
import {Route, Switch} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useWindowWidth from '../src/hooks/useWindowWidth';
import PublicPage from './pages/Public/Public';
import {envConfig} from './features/EnvironmentVariables/enviromentVariables';
import {useTranslation} from 'react-i18next';
import {isRtl, getPreferredLocale, getUTMParameters} from './features/util';
import {logIn, getSession} from './features/Login/login';
import {getUniqueGlobalFollowerCount} from './Helper/generalHelper';
import Cookies from 'js-cookie';
import {Auth, Hub} from 'aws-amplify';
import './style/trengoCustomCss.scss';
import {creatorActor, eventTracking} from './services/event-tracking/event-tracking';

declare global {
  interface Window {
    fbq: any;
    gtag: any;
    ttq: any;
    Trengo: any;
    // FreshworksWidget: any;
    ReactNativeWebView: any;
  }
}

function App() {
  const {i18n} = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    document.body.dir = isRtl(language) ? 'rtl' : 'ltr';
  };

  const isNativeLogin = window?.location?.pathname?.includes('/public/native-login');
  const user = selectMe(useAppSelector(store.getState));

  const loadTrengoScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (document.querySelector('script[src="https://static.widget.trengo.eu/embed.js"]')) {
        resolve(); // Script already loaded
        return;
      }
      window.Trengo = window.Trengo || {};
      window.Trengo.key = process.env.REACT_APP_TRENGO_KEY;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://static.widget.trengo.eu/embed.js';
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error('Failed to load Trengo script.'));
      };
      document.head.appendChild(script);
    });
  };

  const initializeTrengo = async () => {
    try {
      await loadTrengoScript();
      if (window.Trengo) {
        window.Trengo.contact_data = {
          email: user.me.email,
          name: user.me.linksUser?.nickname,
        };
        if (window.Trengo.render) {
          window.Trengo.render();
        } else {
        }
      } else {
      }
    } catch (error) {
    }
  };

  const initializeUser = async () => {
    try {
      const isLoggedIn = await checkLogin();
      if (isLoggedIn && !user.me && user.loginStatus === 'LOGGED_OUT') {
        if (localStorage.getItem('token')) {
          fetchMe();
          fetchConfig();
          fetchMusicLinks();
          fetchWalletStore();
          fetchUserSchedule();
          fetchGoogleCalendarConnection();
        } else {
          guestView();
        }
      } else if (!isLoggedIn) {
        guestView();
      } else {
      }
    } catch (error) {
      guestView(); // Ensure guest view is triggered in case of an error
    }
  };

  const checkLoginStatus = async ()=>{
    const loginStatus = await checkLogin();
    if (loginStatus && user.loginStatus === 'LOGGED_IN' && user.me) {
      initializeTrengo();
      eventTracking().identify(user.me.id, {
        Email: user.me.email,
        Name: user.me.linksUser?.fullname,
        Location: user.me.linksUser?.location,
        Photo: user.me.linksUser?.avatar?.url,
        Followers: user.me.linksUser?.followersCount || 0,
        Nickname: user.me.linksUser?.nickname,
        Language: getPreferredLocale(),
      });
      eventTracking(creatorActor).identify(user.me.id, {
        Email: user.me.email,
        Name: user.me.linksUser?.fullname,
        Location: user.me.linksUser?.location,
        Photo: user.me.linksUser?.avatar?.url,
        Followers: user.me.linksUser?.followersCount,
        Nickname: user.me.linksUser?.nickname,
        Language: getPreferredLocale(),
        Phone: user.me?.phone,
        Gender: user.me?.gender,
      });
    }

    changeLanguage(getPreferredLocale());
  };


  useEffect(() => {
    initializeUser();
    checkLoginStatus();
    // handleFreshworksLabels();
  }, [user]);

  const mobile = useWindowWidth();

  const handleSocialLogin = async (response: any) => {
    if (response?.signInUserSession?.idToken?.jwtToken) {
      try {
        const me = await logIn({Bearer: response?.signInUserSession?.idToken?.jwtToken});
        if (me.hasNickname) {
          localStorage.setItem('token', response?.signInUserSession?.idToken?.jwtToken);
          setTimeout(() => {
            sessionStorage.clear();
            fetchConfig();
            fetchUserSchedule();
            fetchGoogleCalendarConnection();
          }, 300);
        } else {
          if (!response?.attributes) {
            window.location.assign('/public/claim');
          } else {
            if (response?.attributes?.email_verified) {
              window.location.assign('/public/claim');
            } else {
              localStorage.removeItem('verify_email');
              window.location.assign('/public/confirm');
            }
          }
        }
      } catch (error) {
        console.error('Login error: ', error);
      }
    }
  };

  const handleQueryParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const queryLangValue = searchParams?.get('UTM_source')?.toLowerCase();
    if (queryLangValue) {
      Cookies.set('utm_source', queryLangValue, {
        expires: new Date(new Date().getTime() + 10 * 60 * 1000),
      });
    }
  };


  useEffect(() => {
    eventTracking({service: 'clevertap'}).initialize();
    eventTracking({service: 'mixpanel'}).initialize();
    const unsubscribe = Hub.listen('auth', ({payload: {event, data}}) => {
      switch (event) {
        case 'signIn':
          handleSocialLogin(data);
          break;
        case 'signOut':
          break;
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    handleQueryParams();
    if (
      /iPad|iPhone|iPod/.test(navigator.userAgent) &&
      document?.querySelector('[name=viewport]')
    ) {
      document
          ?.querySelector('[name=viewport]')!
          .setAttribute(
              'content',
              'width=device-width, initial-scale=1, maximum-scale=1',
          );
    }
    getUTMParameters();
  }, []);

  return (
    <main>
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s) {if(f.fbq)return;window.fbq = n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${envConfig().fbPixelId}'); fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${envConfig().fbPixelId}&ev=PageView&noscript=1" />`}
        </noscript>
      </Helmet>
      <Switch>
        <Route exact path="/:nickname" component={PublicPage} />
        {user.loginStatus !== 'FETCHING' && user.loginStatus !== 'LOGGED_OUT' && (
          <div dir={isRtl(i18n.language) ? 'rtl' : 'ltr'}>
            {user.me && !isNativeLogin ? <LoggedSwitch /> : <PublicSwitch />}
            {mobile < 668 ? (
              <ToastContainer
                position="top-center"
                autoClose={1600}
                style={{padding: `20px`, marginBottom: `220px`}}
              />
            ) : (
              <ToastContainer position="bottom-right" autoClose={6600} />
            )}
          </div>
        )}
      </Switch>
    </main>
  );
}

export default App;
