import React, {useState} from 'react';
// import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';

export const CustomCarousel = () => {
  return (
    <div style={{width: '600px', margin: '0 auto'}}>
      {/* <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={4}
      >
        <Slider>
          <Slide index={0}><h3>Slide 1</h3></Slide>
          <Slide index={1}><h3>Slide 2</h3></Slide>
          <Slide index={2}><h3>Slide 3</h3></Slide>
          <Slide index={3}><h3>Slide 4</h3></Slide>
        </Slider>
        <ButtonBack>Back</ButtonBack>
        <ButtonNext>Next</ButtonNext>
      </CarouselProvider> */}
    </div>
  );
};
