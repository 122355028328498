import React, { useEffect, useState, useRef } from 'react';
import { store } from '../../app/store';
import styles from './FeaturedVideo.module.scss';
import { selectChangeData, setDataChange, setShouldSave } from '../../features/stores/changeDataSlicer';
import { useSelector } from 'react-redux';
import NaviButtons from '../NaviButtons/NaviButtons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { addSectionItem, deleteLinksSectionItem, LinksSection, LinksSectionItem, selectLinksUser, sortLinksSectionItems, updateSectionItem } from '../../features/stores/linksUserSlicer';
import { convertTime, extractVideoUrl, reorder } from '../../features/util';
import FeaturedVideoItem from './FeaturedVideoItem';
import axios from 'axios';
import WarningModal from '../WarningModal/WarningModal';
import { useTranslation } from 'react-i18next';
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';
import useWindowWidth from '../../hooks/useWindowWidth';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import { ReactComponent as UserPreview } from '../../assets/images/Users.svg';
import { setPreviewMode } from '../../features/stores/previewModeSlicer';
import { useHistory } from 'react-router-dom';

const FeaturedVideo = () => {
  const { t } = useTranslation();
  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const featuredVideosSection = linksUserSections.find(
      (s: any) => s.type === 'video',
  );
  const mobile = useWindowWidth();
  const featuredVideos = featuredVideosSection?.linksSectionItems;
  const [newVideoURL, setNewVideoURL] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const history = useHistory();
  const dataChange = useSelector(selectChangeData);
  const handleSaveGlobal = () => {
    if (dataChange) {
      store.dispatch(setShouldSave(true));
    }
  };
  const handlePreviewClick = async () => {
    await store.dispatch(setPreviewMode(true));
    history.push('/profile/preview');
  };

  const handleAddVideo = async () => {
    const validYoutubeUrls = ['youtube.com', 'https://www.youtube.com/watch?v=', 'https://youtu.be'];
    const linkStartsWith = (url:string)=>newVideoURL?.startsWith(url);

    if (!newVideoURL || !validYoutubeUrls.some(linkStartsWith)) {
      setShowModal(true);
      setModalText(t('invalid_yt_url_error'));
      setModalButtonText(t('featured_video_add_btn_text'));
      return;
    }
    let title = '';
    let duration = '';
    let channelIconUrl = '';
    const id = extractVideoUrl(newVideoURL);
    const youtubeUrl = 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet&part=contentDetails&id=' + id + '&key=' + process.env.REACT_APP_YOUTUBE_API_KEY;
    const videoDetails = await axios.get(youtubeUrl);


    if (videoDetails.data.items.length) {
      const item = videoDetails.data.items[0];
      duration = convertTime(item.contentDetails.duration);
      title = item.snippet.title;
      const youtubeChannelDetailsUrl = 'https://youtube.googleapis.com/youtube/v3/channels?part=snippet' + '&id=' + item.snippet.channelId +'&key=' + process.env.REACT_APP_YOUTUBE_API_KEY;
      // get channel profile icon
      const channelDetails = await axios.get(youtubeChannelDetailsUrl);
      if (channelDetails.data) {
        channelIconUrl = channelDetails.data.items[0]?.snippet.thumbnails.high.url || '';
      }


      const obj: LinksSectionItem = {
        id: Date.now().toString(),
        url: newVideoURL,
        title,
        duration,
        channelProfileIcon: channelIconUrl,
        videoThumbnail: item.snippet.thumbnails?.maxres?.url || item.snippet.thumbnails?.high?.url,
        customUploadedImage: null,
        type: 'youtube',
        linksSectionId: featuredVideosSection?.id as string,
        orderNumber: 0,
        isVisible: true,
      };

      const appendItem = () => new Promise<void>((resolve, reject) => {
        featuredVideos?.forEach((element: LinksSectionItem, index: number) => {
          store.dispatch(updateSectionItem({ id: element?.id, data: { ...element, orderNumber: index+1 } }));
        });
        resolve();
      });
      appendItem().then(() => {
        store.dispatch(addSectionItem(obj));
      }).then(() => {
        store.dispatch(sortLinksSectionItems());
      });
      store.dispatch(setDataChange(true));
      setNewVideoURL('');
    } else {
      setShowModal(true);
      setModalText(t('video_add_error'));
      setModalButtonText(t('featured_video_add_btn_text'));
      return;
    }
  };

  const scrollableDivRef = useRef() as any;

  const onDragEnd = (result: any)=>{
    if (!result.destination) {
      return;
    }
    const itemsTemp = reorder(
        featuredVideos,
        result.source.index,
        result.destination.index,
    ) as any;

    const final = itemsTemp.map((el: any, index: number) => {
      return { ...el, orderNumber: index };
    });
    final.forEach((element: LinksSectionItem, index: number) => {
      store.dispatch(updateSectionItem({ id: element?.id, data: { ...element, orderNumber: index } }));
    });
    store.dispatch(sortLinksSectionItems());
    store.dispatch(setDataChange(true));
  };

  const handleDelete = async (id: string) => {
    store.dispatch(setDataChange(true));
    store.dispatch(deleteLinksSectionItem({ sectionItemId: id }));
  };

  return (
    <div className={styles.container}>
      <WarningModal setIsOpen={setShowModal} open={showModal} contentHeadline={modalText} buttonText={modalButtonText}/>
      <div className={styles.new_link_wrapper}>
        <input
          className={styles.new_link_input}
          value={newVideoURL}
          onChange={(e) => {
            setNewVideoURL(e.target.value);
            store.dispatch(setDataChange(true));
          }}
          placeholder={t('yt_video_url')}
        />
        <PrimaryButton
          label={t('add_video')}
          onClick={handleAddVideo}
          color="red"
        />
      </div>

      {featuredVideos?.length ? <div className={styles.scrollable} ref={scrollableDivRef} key={featuredVideos?.length}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable direction='horizontal' droppableId="droppable">
            {(provided, snapshot) => (
              <div
                style={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {featuredVideos?.map((item: LinksSectionItem, index: number) => {
                  return (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={styles.scrollableItem}

                        >
                          <FeaturedVideoItem
                            link={item}
                            onDeleteClick={handleDelete}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div> : <div className={styles.no_links}>
        <img src={noVideo} />
        <div className={styles.no_links_text}>
          <div className={styles.no_links_title}>{t('no_video_to_display')}</div>
          <div className={styles.subtitle_no_link}>{t('click_the_button_above_to_add_one')}</div>
        </div>
      </div>}
      {(mobile <= 1026) && (
        <div className={styles.btn_containers}>
          <PrimaryButton
            avatar={<UserPreview className={styles.user_avatar} />}
            label={t('preview')}
            onClick={handlePreviewClick}
            color="black"
            style={{ width: '100%', padding: '10px 0' }}
          />
          <PrimaryButton
            label={dataChange ? t('save') : t('saved')}
            onClick={handleSaveGlobal}
            disabled={!dataChange}
            color="red"
            style={{ width: '100%', padding: '10px 0' }}
          />

        </div>
      )}
    </div>
  );
};

export default FeaturedVideo;


// const validate = () => {
//   let flagEmpty: boolean = false;
//   let isUrlOk: boolean = true;
//   if (newVideoURL === '') {
//     flagEmpty = true;
//   }
//   if (
//     !newVideoURL?.startsWith('https://www.youtube.com/watch?v=') &&
//       !newVideoURL?.startsWith('https://youtu.be')
//   ) {
//     isUrlOk = false;
//   }
//   // if (!check && (flagEmpty || !isUrlOk)) {
//   //   return;
//   // }
//   // if (flagEmpty || !isUrlOk) {
//   //   store.dispatch(setSectionStatus({
//   //     section: SECTION_NAMES.VIDEOS,
//   //     valid: false,
//   //     saveStatus: false,
//   //   }));
//   // } else {
//   //   store.dispatch(setSectionStatus({
//   //     section: SECTION_NAMES.VIDEOS,
//   //     valid: true,
//   //     saveStatus: false,
//   //   }));
//   // }
//   return {flagEmpty, isUrlOk};
// };

// const validateWithModal = () => {
//   const {flagEmpty, isUrlOk} = validate();
//   if (flagEmpty) {
//     setIsModalOpened(true);
//     setModalContextText('Please link directly to a YouTube video');
//     setModalButtonText('Add correct URL');
//     return false;
//   }
//   if (!isUrlOk) {
//     setIsModalOpened(true);
//     setModalContextText('Please link directly to a YouTube video');
//     setModalButtonText('Add correct URL');
//     return false;
//   }
//   return true;
// };
