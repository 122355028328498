import React from 'react';
import {useAppSelector} from '../../app/hooks';
import {store} from '../../app/store';
import desktop from '../../assets/images/Version3/images/MacBook Pro 13.svg';
import {previewStyles} from '../../constants/newThemes';
import {selectMe} from '../../features/stores/userSlicer';
import UserPublicProfile from '../../pages/Public/UserPublicProfileV2/UserPublicProfile';
import styles from '../DesktopPreview/DesktopPreview.module.scss';

const DesktopPreview = ({linksUser, musicLinks, deals, collections, themeProp}:any) =>{
  return (
    <div className={styles.desktop}>
      <img className={styles.desktop_img} src={desktop}/>
      <div className={styles.desktop_div}>
        <UserPublicProfile deals={deals} collections={collections} musicLinks={musicLinks} linksUser={linksUser} themeProp={themeProp} selectedStyle={previewStyles.DESKTOP_PREVIEW}/>
      </div>
    </div>
  );
};

export default DesktopPreview;
