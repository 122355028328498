import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { PlusCircleFilled } from '@ant-design/icons';
import styles from '../ImportantLinks/ImportantLinks.module.scss';
import { store } from '../../app/store';
import { setDataChange } from '../../features/stores/changeDataSlicer';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLinksSectionItem,
  LinksSectionItem,
  selectLinksUser,
  sortLinksSectionItems,
  updateSectionItem,
  updateSectionItems,
} from '../../features/stores/linksUserSlicer';
import { reorder, haveSegmentPermission } from '../../features/util';
import { useTranslation } from 'react-i18next';
import useWindowWidth from '../../hooks/useWindowWidth';
import ExploreModal from '../ExploreModal/ExploreModal';
import noLink from '../../assets/images/versionV2/Profile/noLinks.svg';
import { getConfig } from './../../features/stores/configSlicer';
import { themeColor } from '../../constants/newThemes';
import { Badge } from '@material-ui/core';
import LinkModalFactory from '../LinkModal/LinkModalFactory';
import AddLinkModal from '../AddLinkModal/AddLinkModal';
import { MonetizeType, MonetizeTypes } from '../LinkModal/modals/types';
import {
  setPreviewMode,
} from '../../features/stores/previewModeSlicer';
import { PrimaryButton } from '../Buttons/PrimaryButton';
import DraggableLinksSection from '../ImportantLinks/DraggableLinksSection';

export const CustomBadge = withStyles(() => ({
  badge: {
    transform: 'translate(11px, -8px)',
  },
}))(Badge);

export const ButtonCustom = withStyles({
  root: {
    background: (props: any) => props.background,
    color: (props: any) => props.colortext,
    border: (props: any) =>
      props.border ? `1px solid ${props.border}` : 'none',
    boxShadow: 'none',
    borderRadius: '25px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    padding: '8px 20px',
    textTransform: 'none',
  },
  contained: {
    '&:hover': {
      background: (props: any) => props.background,
    },
  },
})(Button);

const Exclusives = () => {
  const { t } = useTranslation();
  const mobile = useWindowWidth();
  const history = useHistory();
  const [openExploreModal, setOpenExploreModal] = useState(false);
  const [openMonetizeModal, setOpenMonetizeModal] = useState(false);
  const [selectedLink, setSelectedLink] = useState<LinksSectionItem>();
  const config = useSelector(getConfig).data || [];
  const linksUserSections = useSelector(selectLinksUser).linksSections;
  const exclusivesSection = linksUserSections.find(
      (section) => section.type === 'exclusive',
  );

  const exclusivesLinksItems = exclusivesSection?.linksSectionItems ?? [];
  const existImportantLinks =exclusivesLinksItems && exclusivesLinksItems.length > 0;

  const linkLimit = haveSegmentPermission(config, 'maxLinkLimit') || 10;

  const monetizationEnabled
    = haveSegmentPermission(config, 'monetize')
    && !haveSegmentPermission(config, 'monetizeBlocklist');

  const [openLinkModal, setOpenLinkModal] = useState<{
    open: boolean;
    editable: boolean;
    mode: 'add'|'edit';
    back?: 'exploreModal' | 'addLinkModal';
  }>({
    open: false,
    editable: true,
    mode: 'add',
  });

  const onDragEnd = (result: any, links: any[]) => {
    if (!result.destination) {
      return;
    }
    const itemsTemp = reorder(
        links,
        result.source.index,
        result.destination.index,
    ) as LinksSectionItem[];

    itemsTemp.forEach((element: LinksSectionItem, index: number) => {
      store.dispatch(
          updateSectionItem({
            id: element?.id,
            data: { ...element, orderNumber: index },
          }),
      );
    });
    store.dispatch(sortLinksSectionItems());
    store.dispatch(setDataChange(true));
  };

  const handleDelete = (id: string) => {
    store.dispatch(deleteLinksSectionItem({ sectionItemId: id }));
    store.dispatch(setDataChange(true));
  };

  const handleAddNewLink = (payload?: any) => {
    const obj: LinksSectionItem = payload
      ? payload
      : {
        id: Date.now().toString(),
        type: 'other',
        url: '',
        customUploadedImage: '',
        title: 'Title',
        linksSectionId: exclusivesSection?.id as string,
        orderNumber: 0,
        hidden: true,
        isVisible: true,
        layout: 'new',
      };
    const sectionItems = linksUserSections.find(
        (section) => section.id === obj.linksSectionId,
    )?.linksSectionItems;
    let data = JSON.parse(JSON.stringify(sectionItems)) || [];
    data.map((e: any, index: number) => {
      e.orderNumber = index + 1;
    });
    data = [obj, ...data];
    store.dispatch(updateSectionItems({ obj, data }));

    data.forEach((element: LinksSectionItem, index: number) => {
      store.dispatch(
          updateSectionItem({
            id: element?.id,
            data: { ...element, orderNumber: index },
          }),
      );
    });
    store.dispatch(sortLinksSectionItems());
    if (payload) {
      store.dispatch(setDataChange(true));
    }
  };

  const handleSave = (link: LinksSectionItem) => {
    if (openLinkModal.mode === 'add') {
      handleAddNewLink(link);
    } else {
      store.dispatch(updateSectionItem({ id: link.id, data: link }));
      store.dispatch(setDataChange(true));
    }
    setOpenLinkModal((state) => ({ ...state, open: false }));
  };

  const handleOpenLinkModal = (link: LinksSectionItem, editable: boolean = true, mode: 'add'| 'edit' = 'add', back?: 'exploreModal' | 'addLinkModal') => {
    if (!monetizationEnabled && MonetizeTypes.includes(link.type as MonetizeType)) {
      editable = false;
    }
    setSelectedLink({ ...link });
    setOpenLinkModal({ open: true, editable, mode, back });
  };

  const handleBackButton = () => {
    const backModal = openLinkModal.back;
    if (backModal === 'exploreModal') {
      setOpenExploreModal(true);
    } else if (backModal === 'addLinkModal') {
      setOpenMonetizeModal(true);
    }
    setOpenLinkModal((state)=>({ ...state, open: false, back: undefined }));
  };

  const handlePreviewClick = async () => {
    await store.dispatch(setPreviewMode(true));
    history.push('/profile/preview');
  };

  return (
    <div
      className={`${styles.container}`}
    >
      <AddLinkModal
        open={openMonetizeModal}
        onClose={() => {
          setOpenMonetizeModal(false);
        }}
        onAddLink={(...params) => handleOpenLinkModal(...params, 'add', 'addLinkModal')}
      />
      {openExploreModal && <ExploreModal onAddLink={(...params) => handleOpenLinkModal(...params, 'add', 'exploreModal')} importantLinksSection={exclusivesSection} handleClose={() => {
        setOpenExploreModal(false);
      }
      }/>
      }
      <LinkModalFactory
        editable={openLinkModal.editable}
        defaultLink={selectedLink!}
        open={openLinkModal.open}
        mode={openLinkModal.mode}
        withBackButton={!!openLinkModal.back}
        onBack={handleBackButton}
        onClose={() => {
          setOpenLinkModal((state)=>({ ...state, open: false }));
        }}
        onSave={handleSave}
        onDelete={(id) => {
          handleDelete(id);
          setOpenLinkModal((state) => ({ ...state, open: false }));
        }}
      />
      <div>

        {existImportantLinks ? (
          <DraggableLinksSection
            list={exclusivesLinksItems}
            onDragEnd={onDragEnd}
            onEditLink={(link) => handleOpenLinkModal(link, true, 'edit')}
          />
        ) : null}

        {(mobile > 768 || (mobile <= 768 && !!exclusivesLinksItems?.length!)) && (
          <div className={styles.btn_container}>
            {exclusivesLinksItems?.length! < linkLimit && (
              <div className={styles.addButton}>
                <PrimaryButton
                  label={t('add_link')}
                  onClick={() => setOpenMonetizeModal(true)}
                  color="red"
                  style={{ padding: mobile > 1024 ? '12px 120px' : '8px 16px' }}
                />
              </div>
            )}
            {mobile < 768 && (
              <ButtonCustom
                onClick={handlePreviewClick}
                variant="contained"
                size={mobile > 1024 ? 'large' : 'default'}
                colortext={themeColor.btnColor}
                background={themeColor.btnBackground}
              >
                {t('preview')}
              </ButtonCustom>
            )}
          </div>
        )}

        {!existImportantLinks && (
          <div className={styles.no_links}>
            <img src={noLink} />
            <div className={styles.no_links_text}>
              {mobile > 740 ? (
                <h5>{t('you_dont_have_any_links_to_display')}</h5>
              ) : (
                <h6>{t('you_dont_have_any_links_to_display')}</h6>
              )}
              <div className={styles.subtitle_no_link}>
                {t('click_the_button_above_to_add_one')}
              </div>
            </div>
            {mobile <= 768 && (
              <div className={styles.button_left}>
                {exclusivesLinksItems?.length! < linkLimit && (
                  <ButtonCustom
                    onClick={() => setOpenMonetizeModal(true)}
                    className={`${styles.button} ${styles.custom_btn}`}
                    variant="contained"
                    size={mobile > 1024 ? 'large' : 'default'}
                    colortext={themeColor.btnColor}
                    background={themeColor.btnBackground}
                    startIcon={<PlusCircleFilled />}
                  >
                    <div>{t('add_link')}</div>
                  </ButtonCustom>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Exclusives;
