import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { isArabic } from "../../features/util";
import { useTranslation } from "react-i18next";
import { theme } from "../../constants/Theme2024";
import useWindowWidth from "../../hooks/useWindowWidth";

const TextFieldInput = (
  props: TextFieldProps & {
    icon?: string | React.SVGProps<any>;
    customStyle?: any;
    staticHeader?: boolean;
  } & any
) => {
  const { t, i18n } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 768;
  return (
    <TextField
      id="filled-basic"
      fullWidth
      margin="normal"
      variant="filled"
      InputProps={{
        disableUnderline: true,
        startAdornment: props?.icon ? (
          <InputAdornment position="start" style={{ marginTop: "0px" }}>
            <img src={props?.icon as string} />
          </InputAdornment>
        ) : null,
        style: {
          ...{
            border: "1px solid #E7E8EA",
            background: "white",
            borderRadius: "30px",
            fontSize: "16px",
          },
          ...props?.InputPropsStyle,
        },
      }}
      inputProps={{ style: props?.customStyle }}
      style={{ marginTop: "0px", marginBottom: "0px" }}
      InputLabelProps={{
        //   shrink: false,
        style: {
          color: `${theme.colors.text.greyLight}`,
          fontSize: '1rem',
          right: isArabic(i18n.language)
          ? props?.icon
            ? isMobile
              ? "10px"
              : "40px"
            : isMobile
            ? "30px"
            : "30px"
          : "5px",
          left: isArabic(i18n.language)
            ? "unset"
            : props?.icon
            ? "30px"
            : props?.staticHeader
            ? isMobile
              ? "10px"
              : "10px"
            : isMobile
            ? "10px"
            : "10px",
          textAlign: "start",
        },
      }}
      {...props}
    />
  );
};

export default TextFieldInput;
