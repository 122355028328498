import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Link} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { isArabic } from '../../features/util';
import useWindowWidth from '../../hooks/useWindowWidth';
import styles from "./BackButton.module.scss";
interface IBackButtonProps{
    goToRoute: string; 
}

const BackButton = ({goToRoute}: IBackButtonProps) =>{
  const { t, i18n } = useTranslation();
  const width = useWindowWidth();
  return (
    <Link to={{pathname: `${goToRoute}`}} className={styles.back_btn_main}>
        {!(isArabic(i18n.language)) ? (
          <ArrowBackIosIcon className={styles.arrow_icon}/>
        ) : (
          <ArrowForwardIosIcon className={styles.arrow_icon} />
        )}
        {width > 768 && <span>{t('back')}</span>}
    </Link>
  );
};

export default BackButton;
