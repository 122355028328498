interface GoogleTagManagerScriptsParams {
  id: string;
}

const getBodyScript = ({id}: GoogleTagManagerScriptsParams) => {
  const bodyScript = document.createElement('noscript');

  const bodyScriptIframe = document.createElement('iframe');
  bodyScriptIframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`;
  bodyScriptIframe.height = '0';
  bodyScriptIframe.width = '0';
  bodyScriptIframe.style.display = 'none';
  bodyScriptIframe.style.visibility = 'hidden';
  bodyScript.appendChild(bodyScriptIframe);
  return bodyScript;
};

const getHeadScript = ({id}: GoogleTagManagerScriptsParams) => {
  const headScriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');`;

  const headScript = document.createElement('script');
  headScript.innerHTML = headScriptContent;

  return headScript;
};

const init = ({id}: GoogleTagManagerScriptsParams) => {
  if (!id) {
    console.error('Google Tag Manager ID is required');
  }

  const headScript = getHeadScript({id});
  const bodyScript = getBodyScript({id});

  document.head.insertBefore(headScript, document.head.childNodes[0]);
  document.body.insertBefore(bodyScript, document.body.childNodes[0]);
};

const GoogleTagManager = {
  init,
};
export default GoogleTagManager;
