import React, {PropsWithChildren, useEffect, useMemo, useState} from 'react';
import styles from './LinkModal.module.scss';
import {LinksSectionItem} from '../../features/stores/linksUserSlicer';
import {socialMediaLinks} from '../../constants/socialMedia';
import {useTranslation} from 'react-i18next';
import {isValidHttpUrl} from '../../features/util';
import EditableImageInput from './components/EditableImageInput';
import EditableTextInput from './components/EditableTextInput';
import TextInput from './components/TextInput';
import BaseModal, {BaseModalProps} from './components/BaseModal';
import ToggleButton from './components/ToggleButton';
import DeleteButton from './components/DeleteButton';
import SaveButton from './components/SaveButton';
import { PrimaryButton } from '../Buttons/PrimaryButton';

export type LinkModalProps = BaseModalProps & {
  defaultLink?: LinksSectionItem;
  editable?: boolean;
  mode?: 'add' | 'edit';
  onSave: (link: LinksSectionItem) => void;
  onDelete: (id: string) => void;
};

const initialLink: LinksSectionItem = {
  id: '',
  title: '',
  url: '',
  type: 'other',
  hidden: false,
  isVisible: true,
  customUploadedImage: '',
  linksSectionId: '',
  orderNumber: 0,
};

const LinkModal = ({
  defaultLink = initialLink,
  editable = true,
  mode = 'add',
  onSave,
  onClose,
  onDelete,
  ...baseModalProps
}: LinkModalProps) => {
  const [link, setLink] = useState<LinksSectionItem>(defaultLink);
  const {t} = useTranslation();

  useEffect(() => {
    setLink(defaultLink);
  }, [defaultLink]);

  const titleChange = (title: string) => {
    if (title.length > 50) return;
    setLink({...link, title});
  };

  const urlChange = (url: string) => {
    const skipSections = new Set(['seasonal']);
    const type =
      socialMediaLinks.find(
          ({key, section, linkTemplates}) =>
            url.toLowerCase().includes(key) && !skipSections.has(section!) || linkTemplates.some((template) => url.toLowerCase().includes(template)),
      )?.key || 'other';
    setLink({...link, url, type});
  };

  const isValid = useMemo(() => {
    return link.title?.length && link.url.length && isValidHttpUrl(link.url);
  }, [link]);

  return (
    <BaseModal {...baseModalProps} onClose={onClose}>
      <EditableTextInput
        value={link.title!}
        onChange={titleChange}
        placeholder={t('add_title')}
        disabled={!editable}
      />
      <EditableImageInput
        value={link.customUploadedImage || ''}
        type={link.type}
        id={link.id}
        onChange={(customUploadedImage) =>
          setLink({...link, customUploadedImage})
        }
        editable={editable}
      />
      <TextInput
        value={link.url}
        onChange={(e)=>urlChange(e.target.value)}
        disabled={!editable}
        label='Your Link'
        error={link.url && !isValidHttpUrl(link.url) ? 'Invalid URL' : ''}
      />

      <EditControlsContainer>
      {mode === "edit" && (
          <>
            <ToggleButton
              checked={link.isVisible}
              onChange={(checked) =>
                setLink((prevState) => ({ ...prevState, isVisible: checked }))
              }
              label={t("visibility")}
            />
            <DeleteButton onClick={() => onDelete(link.id)}>
              {t("delete")}
            </DeleteButton>
          </>

        )}
      </EditControlsContainer>

      <PrimaryButton
        label={t(mode === 'edit' ? 'save' : 'add')}
        onClick={() => onSave(link)}
        disabled={!isValid}
        style={{ width: "100%", margin: "auto", padding: "10px 0px" }}
      />
    </BaseModal>
  );
};

const EditControlsContainer = ({children}: PropsWithChildren<{}>) => {
  return <div className={styles.editControls}>{children}</div>;
};

LinkModal.EditControlsContainer = EditControlsContainer;

export default LinkModal;
