import React from 'react';
import {useSelector} from 'react-redux';
import UserPublicProfile from '../../pages/Public/UserPublicProfileV2/UserPublicProfile';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import {selectLinksUser} from '../../features/stores/linksUserSlicer';
import {useTranslation} from 'react-i18next';
import {getMusicAlbums} from '../../features/stores/musicAlbumsSlicer';
import {getDealsStore} from '../../features/stores/dealsSlicer';
import {getCollectionsStore} from '../../features/stores/collectionsSlicer';

const Preview = () => {
  const {t} = useTranslation();
  const linksUser = useSelector(selectLinksUser);
  const {musicLinks: musicLinks} = useSelector(getMusicAlbums);
  const deals =
    useSelector(getDealsStore)?.approvedDeals?.filter((e) => e.visible) || [];
  const collections = useSelector(getCollectionsStore) || [];
  const theme = useSelector(selectUserTheme);

  return (
    <div className='preview_content'>
      <UserPublicProfile
        deals={deals}
        collections={collections}
        musicLinks={musicLinks}
        linksUser={linksUser}
        themeProp={theme}
        selectedStyle="default"
      />
    </div>
  );
};

export default Preview;
