/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import styles from './Referrals.module.scss';
import { useTranslation } from 'react-i18next';
import CustomInput from '../CustomInput/CustomInput';
import { envConfig } from '../../features/EnvironmentVariables/enviromentVariables';
import copy from 'copy-to-clipboard';
import { ReactComponent as CopyIcon } from '../../assets/images/Version3/icons/content_copy.svg';
import { selectLinksUser } from '../../features/stores/linksUserSlicer';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface ReferralModalProps {
  onClose: () => void;
}

const ReferralModal: React.FC<ReferralModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const url = envConfig().copyLinkUrl;
  const linksUser = useSelector(selectLinksUser);
  const refUrl = `${url}/public/signup?ref=${linksUser?.nickname?.toLowerCase()}`;

  const [copied, setCopied] = useState(false);

  const handleCopyOrShare = async () => {
    // Step 1: Copy to clipboard
    copy(refUrl);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Hide the popup after 2 seconds

    // Step 2: Trigger share if supported
    if (navigator.share) {
      try {
        await navigator.share({
          title: t('share_referral_description'),
          url: refUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    }
  };

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <IconButton className={styles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <h2 className={styles.headline}>{t('refer_a_friend')}</h2>
        <p className={styles.subtitle}>{t('refer_friend_subtitle')}</p>
        <CustomInput
          placeholder="Referral code"
          value={linksUser?.nickname?.toLowerCase()}
          onChange={() => {}}
          wrapperClassName={styles.customInput}
        >
          <div className={styles.iconWrapper} onClick={handleCopyOrShare}>
            <CopyIcon />
            {copied && <div className={styles.copiedPopup}>Copied!</div>}
          </div>
        </CustomInput>
        <div className={styles.howItWorks}>
          <h3>{t('how_it_works')}</h3>
          <ol>
            <li>{t('earn_referral_reward_step1')}</li>
            <li>{t('earn_referral_reward_step2')}</li>
            <li>{t('earn_referral_reward_step3')}</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
