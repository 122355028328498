/* eslint-disable react/prop-types */
import React from "react";
import styles from "./LanguageToggle.module.scss";
import { getPreferredLocale, setPreferredLocale } from "../../features/util";
import { useTranslation } from "react-i18next";


const LanguageToggle = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageToggle = (locale: string) => {
    setPreferredLocale(locale);
    i18n.changeLanguage(locale);
    location.reload();
  };

  const isArabic = getPreferredLocale() === "ar";

  return (
    <div className={styles.languageWrapper}>
      <label className={styles.label}>{t("language")}</label>
      <div className={styles.languageToggle}>
        <button
          className={isArabic ? "" : styles.toggleSelected}
          onClick={() => handleLanguageToggle("en")}
        >
          EN
        </button>
        <button
          className={isArabic ? styles.toggleSelected : ""}
          onClick={() => handleLanguageToggle("ar")}
        >
          عربي
        </button>
      </div>
    </div>
  );
};

export default LanguageToggle;
