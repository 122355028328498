import React, { useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '../../assets/images/Version3/icons/peopleIcon.svg';
import styles from './UpgradeBanner.module.scss';
import { PrimaryButton } from '../Buttons/PrimaryButton';

const UpgradeBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseBanner = () => {
    setIsVisible(false);
    // Store the banner's hidden state in localStorage
    localStorage.setItem('upgradeBannerHidden', 'true');
  };

  if (!isVisible) return null;

  return (
    <div className={styles.banner}>
      <div className={styles.content}>
        <img src={PersonIcon} alt="Person Icon" className={styles.icon} />
        <span className={styles.text}>Manage your fans’ insights with Alfan</span>
      </div>
      <PrimaryButton
        label="Upgrade to Pro"
        color="black"
        onClick={() => console.log("Upgrade to Pro clicked")}
        style={{ display: 'flex', alignItems: 'center', gap: '6px', padding: '6px 24px' }}
      />
      <IconButton className={styles.closeIcon} onClick={handleCloseBanner}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default UpgradeBanner;


