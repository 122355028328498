import React, {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import styles from './Deals.module.scss';
import DealItem from './DealItem';
import {useTranslation} from 'react-i18next';
import {dealsConst} from '../../constants/helper';
import {dealsStatus} from '../../pages/DealsPage/DealsPage';
import {deal} from '../../features/stores/dealsSlicer';
import Loader from 'react-loader-spinner';
import EditCollectionsModal from './EditCollectionsModal';
import {refreshMe} from '../../features/Login/login';
import ViewDealDetailsModal from './ViewDealDetailsModal';
import {selectUserTheme} from '../../features/stores/userThemeSlicer';
import noVideo from '../../assets/images/versionV2/Profile/noVideo.png';

interface ActiveDealsProps {
  activeDeals: any[];
  handleGettingDeals: (status: dealsStatus, keyword?: string) => Promise<void>;
  loading: boolean;
}

const ActiveDeals = ({activeDeals, handleGettingDeals, loading}: ActiveDealsProps) => {
  const {t} = useTranslation();
  const [viewDealDetailsModal, setViewDealDetailsModal] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<deal>();
  const [activeId, setActiveId] = useState('');
  const scrollableDivRef = useRef() as any;

  const theme = useSelector(selectUserTheme);

  const translate = (key : string) => {
    return t(key, {lng: theme.displayLocale || 'en'});
  };

  const handleViewDealDetails = (deal: deal) => {
    setSelectedDeal(deal);
    setViewDealDetailsModal(true);
  };

  return (
    <div className={styles.container}>
      {loading ? 
        <div className={styles.loader}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div> : 
        <>
          {activeDeals.length > 0 ? (
            <div className={styles.scrollable} ref={scrollableDivRef}>
              {activeDeals.map((deal, index) => (
                <div key={deal.id} className={styles.scrollableItem}>
                  <DealItem
                    deal={deal}
                    handleDealApply={() => {}}
                    activeId={activeId}
                    status={dealsConst.APPROVED}
                    handleViewDealDetails={() => handleViewDealDetails(deal)}
                  />
                </div>
              ))}
            </div>
          ) : 
            <div className={styles.no_links}>
              <img src={noVideo} alt="No deals" />
              <div className={styles.no_links_text}>
                <div className={styles.no_links_title}>{t('no_deals_to_display')}</div>
              </div>
            </div>
          }
        </>
      }

      {viewDealDetailsModal && 
        <ViewDealDetailsModal 
          theme={theme} 
          open={viewDealDetailsModal}
          setIsOpen={setViewDealDetailsModal} 
          userDeal={selectedDeal!?.userDeal?.[0]} 
          deal={selectedDeal!} 
          translates={translate}
          isCreator
          onClose={() => {
            setViewDealDetailsModal(false);
          }}
          handleGettingDeals={handleGettingDeals}
        />
      }
    </div>
  );
};

export default ActiveDeals;
