import React, {useEffect, useState} from 'react';
import {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {toast} from 'react-toastify';
import useRequest from '../../features/API/request';
import styles from './../Signup/signupv2.module.scss';
import {logIn, loginCognito, createUserSession, removeUserSession, socialAuth} from '../../features/Login/login';
import logo from '../../assets/images/AlfanBrandNew.svg';
import passwordIconSvg from '../../assets/images/password-icon.svg';
import useInput from '../../hooks/useInput';
import classNames from 'classnames';
import {isRtl} from '../../features/util';
import {useTranslation} from 'react-i18next';
import googleCircle from '../../assets/images/googleCircleLogo.svg';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import {Link} from 'react-router-dom';
import {changeBackground, getUserOnboardingStepTitles, removeStepCookie} from '../../Helper/generalHelper';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import Loader from 'react-loader-spinner';
import CustomProgressBar from '../../components/CustomProgressBar/CustomProgressBar';

const cn = classNames.bind(styles);

const isNotEmpty = (value: String) => value.trim() !== '';
const isEmail = (email: String) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const LoginV2 = () => {
  const {t, i18n} = useTranslation();
  const isRTL: boolean = isRtl(i18n.language);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
    validate: validateEmail,
  } = useInput(isEmail);
  const {
    value: passwordValue,
    isValid: passwordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    reset: resetPassword,
    validate: validatePassword,
  } = useInput(isNotEmpty);

  let formIsValid = false;
  if (emailIsValid && passwordIsValid) {
    formIsValid = true;
  }

  if (emailHasError || passwordHasError) {
    formIsValid = false;
  }

  const request = useRequest({method: 'POST', url: '/auth/login'});
  const doLogin = async () => {
    validateEmail();
    validatePassword();
    removeStepCookie();
    if (!formIsValid) {
      return;
    }
    setLoading(true);
    const credentials = {
      email: emailValue.toLowerCase(),
      password: passwordValue,
      rememberMe: false,
      termsChecked: true,
      origin: 'LINKS',
    };
    const result = await loginCognito(credentials);
    if (result.error) {
      setLoading(false);
      const splitData = result.message.split(':@');
      const msg = splitData.length > 1 ? splitData[splitData.length - 1] : '';
      switch (result.error) {
        case 'UserNotConfirmedException':
          createUserSession({username: emailValue, creds: passwordValue});
          window.location.href='/public/confirm';
          break;
        case 'NotAuthorizedException':
          toast.error(<span style={{color: 'white'}}>{msg || result.message || t('something_went_wrong')}</span>);
          break;
        case 'UserNotFoundException':  
          toast.error(<span style={{color: 'white'}}>{msg || t('user_not_found')}</span>);
          break;
        default:
          console.log('no error matched');  
      }
    } else {
      const tokens: any = await Auth.currentSession();
      if (tokens && tokens.idToken) {
        resetEmail();
        resetPassword();
        const me = await logIn({Bearer: tokens.idToken.jwtToken});
        if (me.hasNickname) {
            localStorage.setItem('token', tokens.idToken.jwtToken);
            setTimeout(()=>{
              sessionStorage.clear();
            }, 300);
        } else {
          window.location.assign('/public/claim');
        }
        setLoading(false);
      }
    }
  };

  const emailClasses = emailHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const passwordClasses = passwordHasError ?
    cn(styles['form-group'], styles['invalid']) :
    styles['form-group'];
  const isPasswordVisibleFieldType = isPasswordVisible ? 'text' : 'password';

  useEffect(() => {
    removeUserSession();
    changeBackground('#F4F0ED');
  }, []);

  return (
    <>
      {loading ? (
        <div className={styles.globalLoader}>
          <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
        </div>
      ) : null}
      <div className={styles.bg}>
        <Link className={styles.logo} to="/">
            <span>
              <img src={logo} alt="logo" style={{width: '93px'}} />
            </span>
        </Link>

        <div className={styles.main_body_container}>
            <div className={styles.main_body_progress_box}>
                <CustomProgressBar hideProgress={true} currentStep={1} stepsName={getUserOnboardingStepTitles(t)} />
            </div>
            <div className={styles.main_body_form_box}>
                <div className={styles.language_container}>
                  <LanguagePicker onChange={() => {}} onBoardUi={true} />
                </div>
                <div className={styles.main_body_form_wrapper}>
                    <div>
                        <div>
                        <div className={styles.head_title}>{t('signin')}</div>
                        <div className={styles['form-img-new-mb']}>
                            <img src={'https://alfan-files-production.s3.eu-west-1.amazonaws.com/Alfan-Link-Public-Assets/signup_mob_animation.gif'} alt="" />
                        </div>
                        <form>
                            <div className={styles['socialLogoContainer']}>
                            <div
                                className={styles.google}
                                onClick={() => {
                                removeStepCookie();
                                socialAuth(CognitoHostedUIIdentityProvider.Google);
                                }}
                            >
                                <img
                                className={styles['circleLogo']}
                                src={googleCircle}
                                />
                                {t('continue_with_google')}
                            </div>
                            </div>

                            <div className={styles['socialLabelContainer']}>
                            <div>{t('or_signup_with')}</div>
                            <div className={styles['line']}></div>
                            </div>

                    <div className={styles.input_container}>
                      <div className={emailClasses}>
                        <TextFieldInput
                          label={t("email")}
                          customStyle={{ padding: "27px 22px 10px" }}
                          staticHeader={true}
                          type="text"
                          value={emailValue}
                          onChange={(e: any) => {
                            emailChangeHandler(e.target.value);
                          }}
                          onBlur={emailBlurHandler}
                        />
                        {emailHasError && (
                          <p className={styles["error-text"]}>
                            {t("invalid_email_error")}
                          </p>
                        )}
                      </div>

                      <div className={passwordClasses}>
                        <div className={styles.flex_relative}>
                          <TextFieldInput
                            label={t("pwd")}
                            customStyle={{ padding: "27px 22px 10px" }}
                            staticHeader={true}
                            type={isPasswordVisibleFieldType}
                            value={passwordValue}
                            onKeyUp={(e: any) => {
                              if (e.keyCode === 13) {
                                doLogin();
                              }
                            }}
                            onChange={(e: any) => {
                              passwordChangeHandler(e.target.value);
                              setPasswordError("");
                            }}
                            onBlur={passwordBlurHandler}
                          />
                          <img
                            style={
                              isRTL
                                ? { left: "20px", top: "20px" }
                                : { right: "20px", top: "20px" }
                            }
                            className={styles.eye}
                            src={passwordIconSvg}
                            onClick={() => {
                              setIsPasswordVisible(!isPasswordVisible);
                            }}
                            alt=""
                          />
                        </div>
                        {passwordHasError && (
                          <p className={styles["error-text"]}>
                            {t("password_create_error")}
                          </p>
                        )}
                      </div>
                    </div>
                            <div className={styles.forgotPassword}>
                                <a href='forgot'>{t('forgot_pwd')}</a>
                            </div>

                            <div className={styles.btn_wrapper}>
                                <button
                                    type="button"
                                    onClick={doLogin}
                                    className={styles['main-btn']}
                                >
                                    {t('signin')}
                                </button>
                            </div>
                        </form>

                        <div className={styles.login_msg}>
                            {t('no_account_yet')}{' '}
                            <Link to="signup">
                            {t('signup')}
                            </Link>
                        </div>
                        </div>
                    </div>
                    <p className={styles['claim-copy-right']}>{t('claim_cr')}</p>
                </div>
                <div className={styles['form-img-new']}>
                <img src={'https://alfan-files-production.s3.eu-west-1.amazonaws.com/Alfan-Link-Public-Assets/signup_web_animation.gif'} alt="" />
                </div>
            </div>
        </div>
      </div>
    </>
  );
};


export default LoginV2;
