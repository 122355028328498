import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './charts.modules.scss';

const CountChart = ({values, labels}: {values:{name: string, data: number[]}[], labels:string[]}) => {
  const options : ApexCharts.ApexOptions | undefined = {
    chart: {
        type: 'bar',
        height: 'auto',
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 0,
          columnWidth: '15%',
        },
      },
      xaxis: {
        type: 'category',
        categories: labels,
        labels: {
          style: {
              cssClass: 'apex-chart-custom-ltr',
          },
        },
        tickPlacement: 'between',
      },
      yaxis: {
        labels: {
          formatter: function(value) {
            return value?.toLocaleString();
          },
          style: {
            cssClass: 'apex-chart-custom-ltr',
          },
        },
      },
      legend: {
        position: 'bottom',
        show: false,
      },
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: false,
      },
    tooltip: {
      custom: function({series, seriesIndex, dataPointIndex, w}:any) {
        return '<div className="test">' +
        '<p className="serie">' + w.config.series[seriesIndex].name + ' </p>' +
        '<span className="category">' + w.config.series[seriesIndex].data[dataPointIndex].toLocaleString() + '</span>' +
          '</div>';
      },
    },
  };
  return (
    <div className="chart" id="lineChart" style={{height: '300px'}}>
      <ReactApexChart options={options} series={values} type="bar" width="100%" height="100%" />
    </div>
  );
};

export default CountChart;
