import React, {useEffect} from 'react';
import {socialMediaLinks} from '../../constants/socialMedia';
import styles from './SocialMediaItem.module.scss';
import {useState} from 'react';
import TextFieldInput from '../TextFieldInput/TextFieldInput';
import {useTranslation} from 'react-i18next';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import useWindowWidth from '../../hooks/useWindowWidth';

interface ISocialMediaItem {
  id: string;
  url: string;
  orderNumber: number;
  type: string;
  onUrlChange?: (arg1: any, arg2: any) => void;
  onDelete?: (arg: any) => void;
  index: number;
  style?: any;
  placeholder?: string;
  onBoardFlow?: boolean;
  handleValidation?: (arg: any, arg2: any, arg3: any) => void;
}

const SocialMediaItem = ({id, url, type, onUrlChange, onDelete, index, style, placeholder, onBoardFlow, handleValidation}: ISocialMediaItem) => {
  const [socialMediaItemIcon, setSocialMediaItemIcon] = useState(socialMediaLinks.find((e) => e.key === type)?.defaultIconTransparent);
  const [socialMediaItemURL, setSocialMediaItemURL] = useState(url);
  const {t} = useTranslation();
  const mobile = useWindowWidth();

  useEffect(() => {
    setSocialMediaItemURL(url);
  }, [url]);

  const handleUrlChange = (e: any) => {
    const value = e.target.value;
    const validValue = value.replace(/^@/, '');

    setSocialMediaItemURL(validValue);
    onUrlChange && onUrlChange(validValue, id);
  };

  const deleteItem = async () => {
    onDelete && onDelete(id);
  };

  const urlValidation = async () => {
    handleValidation && handleValidation(id, socialMediaItemURL, type);
  };

  return onBoardFlow ? <div className={styles.onboard_container} id={`element_${index}`} style={style}>
      <div className={styles.onboard_box}>
        <img src={socialMediaItemIcon} />
        <div className={styles.input_box}>
        <input value={socialMediaItemURL} placeholder={`@${type}`} className={styles.onboard_input}
          onBlur={() => urlValidation()}
          onChange={(e: any) => handleUrlChange(e)} />
        </div>
        
      </div> 
  </div> : (
    <div className={styles.container} id={`element_${index}`} style={style}>
      <div className={styles.left}>
        <img src={socialMediaItemIcon} />
      </div>
      <div className={styles.middle}>
        <div className={`${styles.input_container} ${socialMediaItemURL?.length ? styles.active : ''}`}>
          {!!socialMediaItemURL?.length && <CheckCircleIcon style={{color: '#E887F9'}} />}
          <input value={socialMediaItemURL} placeholder={`@${type}`} onBlur={urlValidation} onChange={(e: any) => handleUrlChange(e)} />
          {!!socialMediaItemURL && index !== 0 && <ClearIcon onClick={deleteItem} style={{cursor: 'pointer'}} />}
        </div>
      </div>
    </div>
  );
};

export default SocialMediaItem;
