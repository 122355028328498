import React from 'react';
import styles from '../TrackingLinksTable.module.scss';
import {PrimaryButton} from '../../Buttons/PrimaryButton';
import {ReactComponent as MobileImage} from '../../../assets/images/shop/mobile.svg';
import {useTranslation} from 'react-i18next';

interface EmptyStateProps {
  setOpenTrackingLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EmptyState = ({setOpenTrackingLinkModal}: EmptyStateProps) => {
  const {t} = useTranslation();
  
  return (
    <div className={styles.emptyStateContainer}>
      <div className={styles.textSection}>
        <h3 className={styles.header}>
          {t('dedicated_shop')}
          <br className={styles.customBreak} />{t('right_your')} <span>{t('alfan_store')}</span>
        </h3>
        <div className={styles.customList}>
          <div className={styles.listItem}>
            <span className={styles.number}>1</span>
            <p className={styles.point}>{t('promote_products')}</p>
          </div>
          <div className={styles.listItem}>
            <span className={styles.number}>2</span>
            <p className={styles.point}>{t('affilitate_link')}</p>
          </div>
          <div className={styles.listItem}>
            <span className={styles.number}>3</span>
            <p className={styles.point}>{t('help_audience')}</p>
          </div>
          <div className={styles.listItem}>
            <span className={styles.number}>4</span>
            <p className={styles.point}>{t('share_link')}</p>
          </div>
        </div>
        <PrimaryButton label={t('add_first_product')} color="black" onClick={() => setOpenTrackingLinkModal(true)} />
      </div>
      <div className={styles.imageSection}>
        <MobileImage width="100%" height="100%" />
      </div>
    </div>
  );
};
