/* eslint-disable react/prop-types */
import React from 'react';
import MuiSelect from '@material-ui/core/Select';
import { FormControl, InputLabel, MenuItem} from '@material-ui/core';
import { ReactComponent as Arrow } from '../../assets/images/keyboard_arrow_down.svg';
import { timeArray } from '../../constants/helper';
import { theme } from '../../constants/Theme2024';

interface TimeSlotSelectProps {
  value: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  label: string;
  disabled: boolean;
}

const TimeSlotSelect: React.FC<TimeSlotSelectProps> = ({ value, onChange, label, disabled }) => {
  return (
    <FormControl variant="outlined" fullWidth style={{ position: 'relative', borderRadius: '20px', border: 'none', padding: '5px' }}>
      <InputLabel shrink style={{ position: 'absolute', top: '15px', left: '2px', fontSize: '16px', color: `${theme.colors.text.grey}`, background: 'white', padding: '0 0 2px 0' }}>

        {label}
      </InputLabel>
      <MuiSelect
        disabled={disabled}
        value={value}
        onChange={onChange}
        disableUnderline
        style={{ width: '100%', borderRadius: '20px', border: 'none', color: '#3c3c3c', backgroundColor: 'white', boxShadow: 'none' }}
        IconComponent={(props) => <Arrow {...props} style={{ color: '#3c3c3c' }} />}
      >
        {timeArray.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

export default TimeSlotSelect;
