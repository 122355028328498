import linksActive from '../assets/images/Version3/icons/store-icon-new-active.svg';
import linksInactive from '../assets/images/Version3/icons/store-icon-new.svg';
import dashboardUnactive from '../assets/images/Version3/icons/requests-icon.svg';
import dashboardActive from '../assets/images/Version3/icons/request-icon-active.svg';
import homeActive from '../assets/images/versionV2/Header/al_home_active.svg';
import homeInactive from '../assets/images/versionV2/Header/al_home_inactive.svg';
import dealsInactive from '../assets/images/versionV2/Header/dealsInactive.svg';
import dealsActive from '../assets/images/versionV2/Header/dealsActive.svg';
import homeMobileActive from '../assets/images/versionV2/Header/homeMobileActive.svg';
import linksMobileActive from '../assets/images/Version3/icons/MobileStoreIcon.svg';
import dealsMobileActive from '../assets/images/versionV2/Header/dealsActiveMobile.svg';
import dashboardMobileActive from '../assets/images/Version3/icons/requestMobileActive.svg';

export const headerRoutes = [
  {
    key: 'home',
    route: '/profile/home',
    name: 'Home',
    label: '',
    icon: homeInactive,
    activeIcon: homeActive,
    mobileIcon: homeMobileActive,
    activatedRoute: ['/profile/home', '/profile/profile-icons'],
  },
  {
    key: 'myprofile',
    route: '/profile/myprofile',
    name: 'My store',
    label: '',
    icon: linksInactive,
    activeIcon: linksActive,
    mobileIcon: linksMobileActive,
    activatedRoute: [],
  },
  {
    key: 'deals',
    route: '/profile/deals',
    name: 'Deals',
    label: '',
    icon: dealsInactive,
    activeIcon: dealsActive,
    mobileIcon: dealsMobileActive,
    activatedRoute: ['/profile/deals', '/profile/products'],
  },
  {
    key: 'requestdashboard',
    route: '/profile/request-dashboard',
    name: 'Dashboard',
    label: '',
    icon: dashboardUnactive,
    activeIcon: dashboardActive,
    mobileIcon: dashboardMobileActive,
    activatedRoute: [],
  },
];


