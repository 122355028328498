import React, {useState, useEffect} from "react";
import nonHookRequest from "../../../features/API/nonHookRequest";
import styles from '../AddTrackingLinkModal.module.scss';
import {useTranslation} from 'react-i18next';
import Loader from 'react-loader-spinner';
import {ReactComponent as CheckIcon} from '../../../assets/images/shop/check.svg';
import {PrimaryButton} from "../../Buttons/PrimaryButton";
import {truncateUrl} from "../../../Helper/generalHelper";
import {isArabic} from '../../../features/util';

interface SelectDealProps {
  userDealId: string | null;
  setUserDealId: React.Dispatch<React.SetStateAction<string | null>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const SelectDeal = ({userDealId, setUserDealId, setStep}: SelectDealProps) => {
  const [deals, setDeals] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const {t, i18n} = useTranslation();

  useEffect(() => {
    getDeals();
  }, []);

  const getDeals = async () => {
    setLoading(true);
    const deals = await nonHookRequest({
      method: 'GET',
      url: `/api/v1/deals?status=Approved&marketingPlatform=Everflow`,
      isShortUrl: true,
    });
    setDeals(deals);
    setLoading(false);
  };

  return (
    <div className={styles.container} style={isArabic(i18n.language) ? {marginLeft: '-20px'} : {marginRight: '-20px'}}>
      <div>
        <h5>{t('select_deal')}</h5>
        <p>{t('select_deal_note')}</p>
        {loading ? 
          <div className={styles.loader}>
            <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
          </div> :
          <div className={styles.mt20}>
            <div className={`${styles.dealsContainer} ${styles.customScrollbar}`}>
              {deals?.length ? deals.map((deal, i) => {
                const productsCount = deal?.userDeal?.[0]?.product?.length;
                return (
                  <div 
                    key={i} 
                    className={styles.dealContainer}
                  >
                    <div className={styles.imageContainer}>
                      <img
                        src={deal?.logoUrl}
                        className={userDealId === deal?.userDeal?.[0].id ? 
                          `${styles.dealImage} ${styles.selected}` : 
                          `${styles.dealImage}`
                        }
                        alt=""
                        onClick={() => setUserDealId(deal?.userDeal?.[0].id)}
                      />
                      {userDealId === deal?.userDeal?.[0].id ? <CheckIcon className={styles.checkMark} /> : null}
                    </div>
                    <div className={styles.textWrapper}>
                      <p className={styles.dealName}>{truncateUrl(deal?.name, 15)}</p>
                      <p className={styles.productsCount}>{productsCount} {productsCount > 1 ? t('products') : t('product')}</p>
                    </div>
                  </div>
                );
              }) : <h6>{t('no_deals')}</h6>}
            </div>
          </div>
        }
      </div>
      <div className={styles.addProductBtn}>
        <PrimaryButton label={t('add_product_link')} onClick={() => setStep(2)} disabled={!userDealId} />
      </div>
    </div>
  );
};
